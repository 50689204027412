import { Component } from "vue-property-decorator";
import Swal,{SweetAlertResult} from "sweetalert2";
import Vue from "vue";

import { namespace } from 'vuex-class';
const LoginInfo = namespace('LoginInfo');
const CalendarInfo = namespace('CalendarInfo');
const GroupInfo = namespace('GroupInfo');
const ModalInfo = namespace('ModalInfo');
const EventInfo = namespace('EventInfo');
const MenuInfo = namespace('MenuInfo');
const HoduDocInfo = namespace('HoduDocInfo');

import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { ginus_exception_log } from '@/model/osm';
import { hodu_doc_object } from '@/model/hodudoc';
import { hodu_color } from '@/common/color';

import moment from 'moment';
import 'moment-lunar';
import Pica from "pica";
import EXIF from "exif-js";
import Autolinker from 'autolinker';

import { ErrorHandler, Route } from 'vue-router/types/router';
import { local_storage_info } from "@/lib/HoduLocalStorage";

import { CommonInputModalInfo, CommonSelectFriendAndGroupTeamModalInfo } from "@/store/modules/ModalInfo";
import { ScheduleSearchConfig } from '@/store/modules/CalendarInfo';
import { GroupTeamInfo } from '@/store/modules/GroupInfo';

declare var APP_VERSION: string;
declare var BUILD_VERSION: string;

// 로딩 관련 타이머
export let loading_timer_end_1  : number | undefined = undefined;
export let loading_timer_end_2  : number | undefined = undefined;
export let loading_timer_end_3  : number | undefined = undefined;
export let loading_timer_bouncy : number | undefined = undefined;
export let indicator_timeout    : number | undefined = undefined;
// export let clear_timout_test    : number | undefined = undefined;

/**
 * OS 정보 받아오기
 */
export async function getOSInfo() : Promise<string> {
    try {
        const ua = navigator.userAgent;
        let os : string = "";
        if (ua.match(/Win(dows )?NT 6\.0/)) {
            os = "Windows Vista";
        } else if (ua.match(/Win(dows )?(NT 5\.1|XP)/)) {
            os = "Windows XP";
        } else {
            if ((ua.indexOf("Windows NT 5.1") != -1) || (ua.indexOf("Windows XP") != -1)) { os = "Windows XP"; } 
            else if ((ua.indexOf("Windows NT 7.0") != -1) || (ua.indexOf("Windows NT 6.1") != -1)) { os = "Windows 7"; } 
            else if ((ua.indexOf("Windows NT 8.0") != -1) || (ua.indexOf("Windows NT 6.2") != -1)) { os = "Windows 8"; } 
            else if ((ua.indexOf("Windows NT 8.1") != -1) || (ua.indexOf("Windows NT 6.3") != -1)) { os = "Windows 8.1"; } 
            else if ((ua.indexOf("Windows NT 10.0") != -1) || (ua.indexOf("Windows NT 6.4") != -1)) { os = "Windows 10"; } 
            else if ((ua.indexOf("iPad") != -1) || (ua.indexOf("iPhone") != -1) || (ua.indexOf("iPod") != -1)) { os = "Apple iOS"; } 
            else if (ua.indexOf("Android") != -1) { os = "Android OS"; } 
            else if (ua.match(/Win(dows )?NT( 4\.0)?/)) { os = "Windows NT"; } 
            else if (ua.match(/Mac|PPC/)) { os = "Mac OS"; } 
            else if (ua.match(/Linux/)) { os = "Linux"; } 
            else if (ua.match(/(Free|Net|Open)BSD/)) { os = RegExp.$1 + "BSD"; } 
            else if (ua.match(/SunOS/)) { os = "Solaris"; }
        }

        if (os.indexOf("Windows") != -1) {
            if (navigator.userAgent.indexOf('WOW64') > -1 || navigator.userAgent.indexOf('Win64') > -1) { os += ' 64bit'; } 
            else { os += ' 32bit'; }
        }

        return os;

    } catch(e) {
        return 'unknown';
    }
}

/**
 * 브라우저 정보 받아오기
 */
export async function getBrowserInfo() : Promise<string> {

    const agent : string = navigator.userAgent.toLowerCase();
    let version : number | undefined;

    try {
        // @ts-ignore Opera 8.0+
        const isOpera = (!!window['opr'] && !!opr['addons']) || !!window['opera'] || navigator.userAgent.indexOf(' OPR/') >= 0;

        // @ts-ignore Firefox 1.0+
        const isFirefox = typeof InstallTrigger !== 'undefined';

        // @ts-ignore Safari 3.0+ "[object HTMLElementConstructor]" 
        const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari['pushNotification']));

        // Internet Explorer 6-11
        const isIE = /*@cc_on!@*/false || !!document['documentMode'];

        // Edge 20+
        const isEdge = !isIE && !!window['StyleMedia'];

        // @ts-ignore Chrome 1 - 71
        const isChrome = !!window['chrome'] && (!!window['chrome']['webstore'] || !!window['chrome']['runtime']);

        // Edge (based on chromium) detection
        const isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

        if( isOpera ) {
            const match : RegExpMatchArray | null = navigator.userAgent.match(/OPR\/([0-9]+)\./);
            version = match ? parseInt(match[1]) : undefined;

            return `Opera ${version}`.trim(); 
        }
        else if( isFirefox ) { 
            const match : RegExpMatchArray | null = navigator.userAgent.match(/Firefox\/([0-9]+)\./);
            version = match ? parseInt(match[1]) : undefined;
            return `Firefox ${version}`.trim(); 
        }
        else if( isSafari ) {
            const match : RegExpMatchArray | null = navigator.userAgent.match(/Version\/([0-9]+)\./);
            version = match ? parseInt(match[1]) : undefined; 
            return `Safari ${version}`.trim(); 
        }
        else if( isIE ) {   
            
            let word : string = ''; 

            // IE old version ( IE 10 or Lower ) 
            if ( navigator.appName == "Microsoft Internet Explorer" ) word = "msie "; 

            // IE 11 
            else if ( agent.search( "trident" ) > -1 ) word = "trident/.*rv:"; 

            const reg : RegExp = new RegExp( word + "([0-9]{1,})(\\.{0,}[0-9]{0,1})" ); 
            if ( reg.exec( agent ) != null ) { version = parseFloat( RegExp.$1 + RegExp.$2 ); } 

            return `Internet Explorer ${version ? version : ''}`.trim(); 
        }
        else if( isEdge ) { 
            const match : RegExpMatchArray | null = navigator.userAgent.match(/Edge\/([0-9]+)\./);
            version = match ? parseInt(match[1]) : undefined; 
            return `Microsoft Edge ${version}`; 
        }
        else if( isChrome && !isEdgeChromium ) { 
            
            let raw : RegExpMatchArray | null = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
            version = raw ? parseInt(raw[2], 10) : undefined;

            return `Google Chrome ${version ? version : ''}`.trim(); 
        }
        else if( isEdgeChromium ) {
            const match : RegExpMatchArray | null = navigator.userAgent.match(/Edg\/([0-9]+)\./);
            version = match ? parseInt(match[1]) : undefined;
            
            return `Microsoft Edge Chromium ${version}`; 
        }

        return 'unknown';

    } catch(e) {
        return 'unknown';
    }
}

export function isIE() : boolean {
    let is_ie : boolean = false; 
    const agent = navigator.userAgent;
    const appName = navigator.appName;

    if ( (appName == 'Netscape' && agent.search('Trident') != -1) || (agent.toLowerCase().indexOf("msie") != -1) ) {
        is_ie = true;
    }

    return is_ie;
}

/**
 * API 호출 타입
 */
export enum API_METHOD {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
}

/**
 * CRUD 모드 enum
 */
export enum CRUD_TYPE {
    CREATE = "CREATE",
    READ   = "READ",
    UPDATE = "UPDATE",
    DELETE = "DELETE",
    NONE   = "",
}

/**
 * 소유 타입 enum (event_type, scope) 
 */
export enum OWNER_TYPE {
    PERSONAL = "PERSONAL", // 개인
    GROUP    = "GROUP",    // 그룹
    TEAM     = "TEAM",     // 팀
}

/**
 * 이벤트 타입 enum
 */
export enum EVENT_SUB_TYPE {
    SCHEDULE    = "BASIC",       // 일정
    CARD        = "CARD",        // 카드
    WORK        = "WORK",        // 업무
    APPOINTMENT = "APPOINTMENT", // 병원 예약
    REPORT      = "REPORT",      // 업무일지
    MEETINGLOG  = "MEETINGLOG",  // 회의록
}

/**
 * 일정 공유 옵션
 * 공유, 복사, 이동
 */
export enum SHARE_OPTION {
    "SHARE" ="SHARE" ,
    "COPY" ="COPY" ,
    "MOVE" ="MOVE" ,
}

/**
 * 달력 타입 enum
 */
export enum CALENDAR_TYPE {
   DAY_GRID_MONTH = "dayGridMonth", // 월 달력
   TIME_GRID_WEEK = "timeGridWeek", // 주 달력
   TIME_GRID_DAY  = "timeGridDay",  // 일 달력
}

/**
 * 그룹 타입 enum
 */
export enum GROUP_TYPE {
    GROUP = "GROUP", // 일반 그룹
    BIZC  = "BIZC",  // 호두 C
    BIZD  = "BIZD",  // 호두 D
    BIZH  = "BIZH",  // 호두 H
}

/**
 * 정렬 타입 (오름차순, 내림차순)
 */
export enum SORT_TYPE {
    ASC = "ASC",
    DESC = "DESC",
}

/**
 * 파일 용량
 */
export enum FILE_SIZE {
    B  = "B",
    KB = "KB",
    MB = "MB",
    GB = "GB",
    TB = "TB",
}

@Component({
//   async beforeRouteEnter(to: any, from: any, next: (arg0: (vm: any) => void) => void): Promise<void> {
//     console.log("beforeRouteEnter");
//     next(vm => {
// //      if(typeof vm.preFetch === 'function')
//         vm.preFetch(to);
//     });
//   },
//   beforeRouteUpdate(to: any, from: any, next:any):void {
//     // if(typeof this.preFetch !== 'function')
//     //   next()
//     // else
//       this.preFetch(to).catch(next()).then(next());
//   }
    
})
export default class VueHoduCommon extends Vue {
    COMPONENT_NAME : string = "";

    GroupAuth = {ADMIN: "ADMIN", GROUP_MANAGER: "GROUP_MANAGER",GROUP_SUB_MANAGER: "GROUP_SUB_MANAGER",GROUP_USER: "GROUP_USER",TEAM_MANAGER: "TEAM_MANAGER",TEAM_USER: "TEAM_USER"}  
    
    //! 자주 사용하는 store 데이터 VueHoduCommon에 등록
    /**
     * @LoginInfo.State
     */
    @LoginInfo.State isLogin           !: boolean;
    @LoginInfo.State user_id           !: number;
    @LoginInfo.State user_type         !: string;
    @LoginInfo.State user_name         !: string;
    @LoginInfo.State user_email        !: string;
    @LoginInfo.State user_phone_number !: string;
    @LoginInfo.State country_code      !: string;
    @LoginInfo.State user_preference   !: any;
    @LoginInfo.State user_group_role   !: any;
    @LoginInfo.State user_team_role    !: any;
    @LoginInfo.State template_map      !: any;
    @LoginInfo.State auth_info         !: any;
    @LoginInfo.State friends           !: any[];
    @LoginInfo.State image_time        !: number;
    @LoginInfo.State loading_bouncy    !: boolean;
    @LoginInfo.State loading_end_1     !: boolean; 
    @LoginInfo.State loading_end_2     !: boolean; 
    @LoginInfo.State loading_end_3     !: boolean; 
    
    /**
     * @LoginInfo.Action
     */
    @LoginInfo.Action doLogin              ?: any;
    @LoginInfo.Action doSetUserId          ?: any;
    @LoginInfo.Action doSetUserPhoneNumber ?: any;
    @LoginInfo.Action doSetCountryCode     ?: any;
    @LoginInfo.Action doSetUserPreference  ?: any;
    @LoginInfo.Action doSetGroupRole       ?: any;
    @LoginInfo.Action doSetTeamRole        ?: any;
    @LoginInfo.Action doSetAuthInfo        ?: any;
    @LoginInfo.Action doSetFriends         ?: any;
    @LoginInfo.Action doSetImageTime       ?: any;
    @LoginInfo.Action doSetLoadingBouncy   ?: any;
    @LoginInfo.Action doSetLoadingEnd1     ?: any;
    @LoginInfo.Action doSetLoadingEnd2     ?: any;
    @LoginInfo.Action doSetLoadingEnd3     ?: any;
    
    /**
     * @CalendarInfo.State
     */
    @CalendarInfo.State schedule_search_config  !: ScheduleSearchConfig;
    @CalendarInfo.State calendar_id             !: string;
    @CalendarInfo.State scope                   !: string;
    @CalendarInfo.State scope_group_id          !: number;
    @CalendarInfo.State scope_team_id           !: number;
    @CalendarInfo.State scope_group_team_option !: any;
    @CalendarInfo.State schedule_time_type      !: string;

    /**
     * @CalendarInfo.Action
     */
    @CalendarInfo.Action doSetScheduleSearchConfig ?: any;
    @CalendarInfo.Action doSetCalendarId           ?: any;
    @CalendarInfo.Action doSetScope                ?: any;
    @CalendarInfo.Action doSetScopeGroupId         ?: any;
    @CalendarInfo.Action doSetScopeTeamId          ?: any;
    @CalendarInfo.Action doSetScopeGroupTeamOption ?: any;
    @CalendarInfo.Action doSetScheduleTimeType     ?: (params : string) => void;

    /**
     * @GroupInfo.State
     */
    @GroupInfo.State group_info_list     !: GroupTeamInfo[];
    @GroupInfo.State all_group_data_temp !: GroupTeamInfo[];
    @GroupInfo.State all_group_data      !: GroupTeamInfo[];
    @GroupInfo.State normal_group_data   !: GroupTeamInfo[];
    @GroupInfo.State hodu_c_group_data   !: GroupTeamInfo[];
    @GroupInfo.State hodu_d_group_data   !: GroupTeamInfo[];
    @GroupInfo.State group_team_option   !: any;

    /**
     * @GroupInfo.Action
     */
    @GroupInfo.Action doReplaceGroupInfoList     ?: any;
    @GroupInfo.Action doSetAllGroupDataTemp      ?: any;
    @GroupInfo.Action doSetNormalGroupData       ?: any;
    @GroupInfo.Action doSetHoduCGroupData        ?: any;
    @GroupInfo.Action doSetHoduDGroupData        ?: any;
    @GroupInfo.Action doGroupId                  ?: any;
    @GroupInfo.Action doTeamId                   ?: any;
    @GroupInfo.Action doGroupTeamOption          ?: any;
    @GroupInfo.Action doGroupStatisticsStartDate ?: any;
    
    /**
     * @EventInfo.Action
     */
    @EventInfo.Action doSetEvent              ?: any; // 이벤트 정보 등록
    @EventInfo.Action doSetEventCrudType      ?: any; // 이벤트 CRUD TYPE 등록 (CREATE, READ, UPDATE)
    @EventInfo.Action doSetEventShareInfo     ?: any; // 공유 정보 초기화     

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State message_modal_info ?: any;     

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetMessageModalInfo  ?: any;
    @ModalInfo.Action doSetCommonInputModalInfo  ?: (params : CommonInputModalInfo) => void;
    @ModalInfo.Action doSetCommonSelectFriendAndGroupTeamModalInfo ?: (params : CommonSelectFriendAndGroupTeamModalInfo) => void;

    /**
     * @MenuInfo.State
     */
    @MenuInfo.State left_control_box_flag  !: boolean;
    @MenuInfo.State right_control_box_flag !: boolean;
    @MenuInfo.State group_menu_close       !: boolean;
    @MenuInfo.State message_menu_close     !: boolean;
    @MenuInfo.State work_menu_close        !: boolean;
    @MenuInfo.State community_menu_close   !: boolean;
    @MenuInfo.State resident_menu_close    !: boolean;

    /**
     * @MenuInfo.Action
     */
    @MenuInfo.Action doSetLeftControlBoxFlag  ?: any;
    @MenuInfo.Action doSetRightControlBoxFlag ?: any;
    @MenuInfo.Action doSetGroupMenuClose      ?: any;
    @MenuInfo.Action doSetMessageMenuClose    ?: any;
    @MenuInfo.Action doSetWorkMenuClose       ?: any;
    @MenuInfo.Action doSetCommunityMenuClose  ?: any;
    @MenuInfo.Action doSetResidentMenuClose   ?: any;

    /**
     * @HoduDocInfo.State
     */
    @HoduDocInfo.State hospital_info         !: hodu_doc_object.hospital_info | null;
    @HoduDocInfo.State hospital_setting_info !: hodu_doc_object.hospital_setting_info[] | null;
    @HoduDocInfo.State department_info       !: hodu_doc_object.department_info[] | null;
    @HoduDocInfo.State doctor_info           !: hodu_doc_object.doctor_info[] | null;
    @HoduDocInfo.State patient_info          !: hodu_doc_object.patient_info[] | null;

    /**
     * @HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetHospitalInfo        ?: (params : hodu_doc_object.hospital_info | null) => void;
    @HoduDocInfo.Action doSetHospitalSettingInfo ?: (params : hodu_doc_object.hospital_setting_info[] | null) => void;
    @HoduDocInfo.Action doSetDepartmentInfo      ?: (params : hodu_doc_object.department_info[] | null) => void;
    @HoduDocInfo.Action doSetDoctorInfo          ?: (params : hodu_doc_object.doctor_info[] | null) => void;
    @HoduDocInfo.Action doSetPatientInfo         ?: (params : hodu_doc_object.patient_info[] | null) => void;
    
    /**
     * 색상
     */
    dc_color : string[] = [
        hodu_color.hodu_dc_0, 
        hodu_color.hodu_dc_1, 
        hodu_color.hodu_dc_2, 
        hodu_color.hodu_dc_3, 
        hodu_color.hodu_dc_4, 
        hodu_color.hodu_dc_5, 
        hodu_color.hodu_dc_6, 
        hodu_color.hodu_dc_7, 
        hodu_color.hodu_dc_8, 
        hodu_color.hodu_dc_9, 
    ];

    lc_color : string[] = [
        hodu_color.hodu_lc_0, 
        hodu_color.hodu_lc_1, 
        hodu_color.hodu_lc_2, 
        hodu_color.hodu_lc_3, 
        hodu_color.hodu_lc_4, 
        hodu_color.hodu_lc_5, 
        hodu_color.hodu_lc_6, 
        hodu_color.hodu_lc_7, 
        hodu_color.hodu_lc_8, 
        hodu_color.hodu_lc_9, 
    ];

    hodu_chat_color : string[] = [
        hodu_color.chat_color_0,
        hodu_color.chat_color_1,
        hodu_color.chat_color_2,
        hodu_color.chat_color_3,
        hodu_color.chat_color_4,
        hodu_color.chat_color_5,
        hodu_color.chat_color_6,
        hodu_color.chat_color_7,
        hodu_color.chat_color_8,
        hodu_color.chat_color_9
    ]

    /**
     * 파일 업로드 확장자 제한
     */
    file_extension_forbidden : string[] = [
        '.ADE', '.ADP', '.BAT', '.CHM', '.CMD', 
        '.COM', '.CPL', '.EXE', '.HTA', '.INS', 
        '.ISP', '.JAR', '.JSE', '.LIB', '.LNK', 
        '.MDE', '.MSC', '.MSP', '.MST', '.PIF', 
        '.SCR', '.SCT', '.SHB', '.SYS', '.VB' , 
        '.VBE', '.VBS', '.VXD', '.WSC', '.WSF', 
        '.WSH', '.JS'
    ];

    focus_view : JQuery<HTMLElement> | null = null;

    DEFAULT_FILE_MAX_SIZE : number = 103809024; // 100MB 
    DEFAULT_FILE_MAX_SIZE_TEXT : string = "100MB";

    NORMAL_GROUP_FILE_MAX_SIZE : number = 2097152; // 2MB 
    NORMAL_GROUP_FILE_MAX_SIZE_TEXT : string = "2MB";

    DYNAMIC_LINK_PREFIX : string = "https://hodu.page.link/?link=https://web.hodu.app?";
    DYNAMIC_LINK_SUFFIX : string = "&apn=kr.co.ginus.with&isi=1125407575&ibi=kr.co.ginus.nanuga&efr=1";

    async preFetch(to:any):Promise<any> {
        console.log("preFetch");
    }

    created():void {
        this.COMPONENT_NAME = this.$options.name ? this.$options.name : "";
        console.log(`created ${this.COMPONENT_NAME}`);
    }
    beforeMount(): void {
        console.log(`beforeMount ${this.COMPONENT_NAME} `);
    }
    mounted(): void {
        console.log(`mounted ${this.COMPONENT_NAME}`);
        // this.showSuccess("마운트","마운트됨");
    }
    showError(msg:string,title:string = "알림"): Promise<SweetAlertResult> {
        return Swal.fire({
            title: title,
            html: msg,
//            type: 'error',
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "확인"
          });
    }
    showConfirm(msg:string,title:string,confirmBtnText:string="확인",cancelBtnText:string="취소"): Promise<SweetAlertResult> {
        return Swal.fire({
            title: title,
            html: msg,
//            type: 'info',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: confirmBtnText,
            cancelButtonText: cancelBtnText
        });
    }
    showSuccess(msg:string,title:string,confirmBtnText:string="확인"): Promise<SweetAlertResult> {
        return Swal.fire({
            title: title,
            html: msg,
//            type: 'success',
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: confirmBtnText
          });
    }

    /**
     * 라우터를 이용한 페이지 이동
     * @param path - 경로
     * @param onComplete  - 성공 함수
     * @param onAbort - 에러 함수 
     */
    async hodu_router_push(path : string, onComplete ?: (route: Route) => void, onAbort ?: ErrorHandler) : Promise<void> {
        await this.$router.push(path, onComplete, onAbort ? onAbort : () => {});
    }

    /**
     * 이전 페이지로 이동
     */
    movePrevPage() : void {
        this.$router.go(-1);
    }

    /**
     * 날짜차이 계산
     */
    getDateDiff(_date1 : Date | string, _date2 : Date | string) : number {
        let diffDate_1 : Date = _date1 instanceof Date ? _date1 : new Date(_date1);
        let diffDate_2 : Date = _date2 instanceof Date ? _date2 : new Date(_date2);
    
        diffDate_1 = new Date(diffDate_1.getFullYear(), diffDate_1.getMonth(), diffDate_1.getDate());
        diffDate_2 = new Date(diffDate_2.getFullYear(), diffDate_2.getMonth(), diffDate_2.getDate());
    
        let diff : number = Math.abs(diffDate_2.getTime() - diffDate_1.getTime());
        diff = Math.ceil(diff / (1000 * 3600 * 24));
    
        return diff;
    }

    /**
     * 그룹 권한 가져오기
     */
    get_group_role(group_id : number) : string {
        let group_role : string = "";

        for ( let i = 0; i < this.user_group_role.length; i++ ) {
            let lo_data = this.user_group_role[i];

            if ( lo_data.group_id == group_id ) {
                group_role = lo_data.group_role;
                break;
            }
        }

        return group_role;
    }

    /**
     * 팀 권한 가져오기
     */
    get_team_role(team_id : number) : string {
        let team_role : string = "";

        for ( let i = 0; i < this.user_team_role.length; i++ ) {
            let lo_data = this.user_team_role[i];

            if ( lo_data.team_id == team_id ) {
                team_role = lo_data.team_role;
                break;
            }
        }

        return team_role;
    }

    /**
     * 그룹 Permmision 가져오기
     */
    is_group_permmision(group_id, cate, type) : boolean {
        let permmision : boolean = false;

        for ( let i = 0; i < this.user_group_role.length; i++ ) {
            let lo_data = this.user_group_role[i];

            if ( lo_data.group_id == group_id ) {
                try {
                    permmision = lo_data['group_permmision'][cate][type];
                    if( permmision == null ) permmision = false;
                } catch(e) {
                    permmision = false;
                }
                break;
            }
        }

        return permmision;
    }

    /**
     * 팀 Permmision 가져오기
     */    
    is_team_permmision(team_id, cate, type) : boolean {
        let permmision : boolean = false;

        for ( let i = 0; i < this.user_team_role.length; i++ ) {
            let lo_data = this.user_team_role[i];

            if ( lo_data.team_id == team_id ) {
                try {
                    permmision = lo_data['team_permmision'][cate][type];
                    if( permmision == null ) permmision = false;
                } catch(e) {
                    permmision = false;
                }
                break;
            }
        }

        return permmision;
    }
    
    /**
     * 그룹 기능 체크
     * @param  {number} group_id
     * @param  {string} feature
     * @returns boolean
     */
    isEnableGroupFeature(group_id : number, feature : string) : boolean {
        
        try {
            const group_length : number = this.group_info_list.length;
            for( let i = 0; i < group_length; i++ ) {
                if( this.group_info_list[i].group_id == group_id ) {
                    if( this.group_info_list[i].group_features[feature] == null ) {
                        return false;
                    }
                    
                    return this.group_info_list[i].group_features[feature].enable;
                }
            }
        } catch(e) { }

        return false;
    }

    /**
     * 그룹 권한 가져오기
     */
    get_group_feature(group_id : number) : any {
        try {
            const group_length : number = this.group_info_list.length;
            for( let i = 0; i < group_length; i++ ) {
                if( this.group_info_list[i].group_id != group_id ) continue;
                return this.group_info_list[i].group_features;
            }
        } catch(e) {
            
        }

        return null;
    }

   /**
     * 그룹 컬러 변환
     */
    group_color_to_class_name(color) : string {
        
        color = this.hodu_hex_color_process(color);

        if ( color == "#FF6363" || color == "#FFFF6363" ) return "Dc0";
        if ( color == "#FFA70E" || color == "#FFFFA70E" ) return "Dc1";
        if ( color == "#FFC72F" || color == "#FFFFC72F" ) return "Dc2";
        if ( color == "#FF198B" || color == "#FFFF198B" ) return "Dc3";
        if ( color == "#00B2C7" || color == "#FF00B2C7" ) return "Dc4";
        if ( color == "#13D08B" || color == "#FF13D08B" ) return "Dc5";
        if ( color == "#4DBAFF" || color == "#FF4DBAFF" ) return "Dc6";
        if ( color == "#477FFF" || color == "#FF477FFF" ) return "Dc7";
        if ( color == "#6854FF" || color == "#FF6854FF" ) return "Dc8";
        if ( color == "#35405A" || color == "#FF35405A" ) return "Dc9";
      
        return "Dc9";
    }

    /**
     * 서버에서 그룹 권한 가져오기
     */
    async get_group_role_service() : Promise<any> {

        const vue = this;

        await this.hodu_api_call(`api/v1/groups/getGroupRole/0`, API_METHOD.GET, null, false)
        .then(async(response) => {

            await Promise.all([this.doSetGroupRole(response.data.data.group_role_list), this.doSetTeamRole(response.data.data.team_role_list), this.getMyGroupForWeb()])
                .then(async() => {
                    // null이거나 사이즈가 0이면 그대로 종료
                    if( vue.all_group_data_temp == null || vue.all_group_data_temp.length < 1 ){
                        vue.doReplaceGroupInfoList([]);
                        return;
                    }
                    
                    vue.all_group_data.splice(0, vue.all_group_data.length);
                    vue.normal_group_data.splice(0, vue.normal_group_data.length);
                    vue.hodu_c_group_data.splice(0, vue.hodu_c_group_data.length);
                    vue.hodu_d_group_data.splice(0, vue.hodu_d_group_data.length);

                    //! =============================================================
                    //! group 데이터 가공
                    //! =============================================================
                    const group_size : number = vue.all_group_data_temp.length;
                    for( let i = 0; i < group_size; i++ ) {
                        
                        const group_obj : any = JSON.parse(JSON.stringify(vue.all_group_data_temp[i])); 

                            // 색상 설정 및 팀 초기화
                            group_obj.color = this.hodu_hex_color_process(group_obj.color);
                            group_obj.teams = [];

                            // 호두 D 예약
                            if( group_obj.biz_type == GROUP_TYPE.BIZD && group_obj.row_type == 'APPOINTMENT' ) {

                                // 병원 그룹 최상위 일때
                                if( group_obj.team_id == 0 && ( group_obj.team_name == null || group_obj.team_name == "" ) ) { continue; }

                                let hodu_d_group : any = null;
                                let hodu_d_index : number = -1;

                                const hodu_d_count : number = vue.hodu_d_group_data.length;
                                for( let j = 0; j < hodu_d_count; j++ ) {
                                    if ( vue.hodu_d_group_data[j].group_id != group_obj.group_id ) { continue; }
                                    hodu_d_group = JSON.parse(JSON.stringify(vue.hodu_d_group_data[j]));
                                    hodu_d_index = j;
                                    break;
                                }

                                if( hodu_d_group == null || hodu_d_index < 0 ) {
                                    hodu_d_group = {
                                        level : 1,                  
                                        row_type : 'APPOINTMENT',               
                                        group_id : group_obj.group_id,             
                                        team_id : 99999,               
                                        biz_id : group_obj.biz_id,                 
                                        biz_type : GROUP_TYPE.BIZD,              
                                        group_name : group_obj.group_name,             
                                        team_name : '',              
                                        color : '#477FFF',                   
                                        team_count : 0,             
                                        group_features : {}, 
                                        limit_event_attachment : 0,
                                        limit_event_image_count : 0,
                                        user_count : 0,             
                                        checked : vue.schedule_search_config.hodu_d_filter.filter(key => new RegExp(group_obj.biz_id).test(key) == true).length < 1, // 하나라도 체크 해제 되어 있으면 체크 해제
                                        shareChecked : false,          
                                        listOn : false,                 
                                        shareListOn : false,           
                                        teams : []     
                                    };
                                    
                                    vue.hodu_d_group_data.push(JSON.parse(JSON.stringify(hodu_d_group)));
                                    hodu_d_index = vue.hodu_d_group_data.length - 1;
                                }
                                            
                                // 필터 체크
                                const doctor_key : string = `${group_obj.biz_id}___${group_obj.department_code}___${group_obj.doctor_code}`;
                                group_obj.checked = vue.schedule_search_config.hodu_d_filter.indexOf(doctor_key) == -1;

                                // 그룹의 teams에 추가
                                hodu_d_group.teams.push(group_obj);
                                hodu_d_group.team_count = hodu_d_group.teams.length;
                                vue.hodu_d_group_data.splice(hodu_d_index, 1, JSON.parse(JSON.stringify(hodu_d_group)));
                            }

                            // 일반 그룹, 호두 C, 호두 D (부관리자, 관리자) (호두 D는 추후 제거 예정)
                            else {
                                if( group_obj.row_type == 'APPOINTMENT' || group_obj.row_type == 'TEAM' ) { continue; }
                                
                                let next_index : number = -1;

                                for( let j = i + 1; j < group_size; j++ ) {
                                    const group_team_obj : any = JSON.parse(JSON.stringify(vue.all_group_data_temp[j]));
                                    
                                    if( group_team_obj.group_id == group_obj.group_id && group_team_obj.row_type == 'APPOINTMENT' ) { 
                                        continue; 
                                    }

                                    // 같은 그룹내의 팀이 아니라면 break
                                    if( group_team_obj.level < 2 || group_team_obj.group_id != group_obj.group_id ) {
                                        next_index = j; 
                                        break; 
                                    }
                                    
                                    // 색상 설정 및 필터 설정
                                    group_team_obj.color = this.hodu_hex_color_process(group_team_obj.color);
                                    group_team_obj.checked = vue.schedule_search_config.team_filter.indexOf(group_team_obj.team_id) == -1;

                                    // 대상 그룹의 teams에 push
                                    group_obj.teams.push(group_team_obj);
                                }
                                
                                // 리스트 펼쳐짐 false, 필터 설정
                                group_obj.listOn  = false;
                                group_obj.checked = vue.schedule_search_config.group_filter.indexOf(group_obj.group_id) == -1;
                                this.all_group_data.push(JSON.parse(JSON.stringify(group_obj)));
                                
                                // biz_id 유무에 따라 분류해서 normal_group, hodu_c_group에 push
                                if( group_obj.biz_id != null && group_obj.biz_id.length > 0 ){
                                    vue.hodu_c_group_data.push(JSON.parse(JSON.stringify(group_obj)));
                                } else {
                                    vue.normal_group_data.push(JSON.parse(JSON.stringify(group_obj)));
                                }
                                
                                // 다음 인덱스가 존재한다면 i++이 되기 때문에 next_index - 1을 i로 설정
                                if( next_index > -1 ) { i = next_index - 1; }
                            }

                    }
                    
                    vue.doSetAllGroupDataTemp(JSON.parse(JSON.stringify(vue.all_group_data_temp)));
                    vue.doSetNormalGroupData(JSON.parse(JSON.stringify(vue.normal_group_data)));
                    vue.doSetHoduCGroupData(JSON.parse(JSON.stringify(vue.hodu_c_group_data)));
                    vue.doSetHoduDGroupData(JSON.parse(JSON.stringify(vue.hodu_d_group_data)));
                    vue.doReplaceGroupInfoList(JSON.parse(JSON.stringify(vue.all_group_data)));

                    if( window["setFilterCheckColor"] != null ) { await window["setFilterCheckColor"](); }
                })
        })
        .catch(async(e) => {
            this.hodu_error_process(e, false, false, true);
        });
    }

    /**
     * 그룹 전체 조회
     */
    async getMyGroupForWeb() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/user/me/getMyGroup?group_id=0&is_web=true`, API_METHOD.GET, undefined, false)
            .then(async(response) => {
                console.log(JSON.stringify(response.data.data.list));
                vue.doSetAllGroupDataTemp(response.data.data.list);
            })
            .catch(async(e)=>{
                this.hodu_error_process(e, false, false, false);
            });
    }

    /**
     * API 호출
     * @param  {string}       url           - url
     * @param  {any}          method        - get, post, put, delete 등등
     * @param  {any}          body?         - body 데이터
     * @param  {boolean=true} showIndicator - 인디케이터 발생 여부
     */
    async hodu_api_call(url : string, method : API_METHOD, body ?: any, showIndicator: boolean = true) : Promise<any> {
        if( showIndicator == true ) { this.hodu_show_indicator(); }
        
        return new Promise((resolve, reject) => {
            let config : AxiosRequestConfig = {
                url: url,
                method: method,
                data: body
            }

            this.$http.request(config)
                .then(async(response) => { resolve(response); })
                .catch(async(e) => { reject(e); })
                .finally(async() => { if( showIndicator == true ) { this.hodu_hide_indicator(); } })
        })
    }

    /**
     * 파일 다운로드
     * @param url         - 다운로드 받을 URL 
     * @param file_name   - 파일 이름 
     * @param is_compress - 압축 여부
     */
    async hodu_download(url : string, file_name : string, is_compress : boolean = false) : Promise<AxiosResponse> {
        return new Promise((resolve, reject) => {
            this.$http({
                url : url,
                method : 'GET',
                responseType: 'blob',
                headers : {
                    "Cache-Control" : "no_cache"
                }
            }).then(async(response) => {
                
                // 압축이라면 그대로 내보낸다
                if( is_compress ) {
                    resolve(response);
                    return;
                }

                console.log(response);

                const blob = new Blob([response.data]);

                if ( window.navigator && window.navigator['msSaveOrOpenBlob'] ) {
                    window.navigator['msSaveOrOpenBlob'](blob, file_name); // for IE
                    return;
                }
                
                await this.hodu_download_blob(blob, file_name);
                resolve(response);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    /**
     * blob 다운로드
     * @param blob      - 다운로드 받을 blob
     * @param file_name - 파일 이름
     */
    async hodu_download_blob(blob : Blob, file_name : string) : Promise<void> {
        try {

            if ( window.navigator && window.navigator['msSaveOrOpenBlob'] ) {
                window.navigator['msSaveOrOpenBlob'](blob, file_name); // for IE
                return;
            }
            
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file_name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch(e) {
            throw e;
        }
    }

    /**
     * API 호출이 성공적인지 판단 (200 ~ 299인 경우만 성공)
     * @param status 
     */
    isHttpStatusSuccess(status : number) {
        return status != null && 200 <= status && status < 300;
    }

    /**
     * 파일 업로드
     */
    async hodu_temp_upload(form_data, showIndicator: boolean = true) : Promise<any> {
        if (showIndicator) $('#api_indicator').css('display', 'block');

        return new Promise((resolve, reject) => {
            this.$http.post('api/v1/upload', form_data, { headers : { 'Content-Type' : 'multipart/form-data' } })
            .then(async(response) => {
                if (showIndicator) $('#api_indicator').css('display', 'none');
                resolve(response);
            })
            .catch(async(e) => {
                if (showIndicator) $('#api_indicator').css('display', 'none');
                reject(e);
            });
        })        
    }

    /**
     * 파일 업로드
     */
    async hodu_chat_temp_upload(form_data, showIndicator: boolean = true) : Promise<any> {
        if (showIndicator) $('#api_indicator').css('display', 'block');

        return new Promise((resolve, reject) => {
            this.$http.post('api/v1/chat/channel_info/temp/upload', form_data, { headers : { 'Content-Type' : 'multipart/form-data' } })
            .then(async(response) => {
                if (showIndicator) $('#api_indicator').css('display', 'none');
                resolve(response);
            })
            .catch(async(e) => {
                if (showIndicator) $('#api_indicator').css('display', 'none');
                reject(e);
            });
        })        
    }

    /**
     * message_modal 띄우는 함수
     * @param message_type    - 메세지 타입 (success, cancel, alert 세가지 타입이 있음)
     * @param message         - 메세지
     * @param button_text     - 버튼 텍스트 배열 [갯수에 따라서 버튼 개수가 정해짐, 최대 3개]
     * @param button_function - 각각의 버튼 함수 배열 (아무것도 하지 않길 원하면 아무것도 안하는 함수로 보낼 것)
     */
    async hodu_show_dialog(message_type : string, message : string, button_text : string[], button_function ?: Function[]) : Promise<void> { 

        // 기초 정보 입력
        this.doSetMessageModalInfo({
            "show_message_modal" : true,
            "message_type" : message_type,
            "message" : message,
            "button_text" : button_text
        });
        
        // 왼쪽에서 부터 첫번째 버튼 클릭 함수
        if( button_function != null && button_function.length >= 1 ) {
            window['messageModalFirstButtonClick'] = button_function[0];
        }

        // 왼쪽에서 부터 두번째 버튼 클릭 함수
        if( button_function != null && button_function.length >= 1 ) {
            window['messageModalSecondButtonClick'] = button_function[1];
        }

        // 왼쪽에서 부터 세번째 버튼 클릭 함수
        if( button_function != null && button_function.length >= 1 ) {
            window['messageModalThirdButtonClick'] = button_function[2];
        }
        
        // 완전히 비어 있다면 아무것도 안하는 함수들로 등록
        if( button_function == null ) {
            window['messageModalFirstButtonClick']  = () => {};
            window['messageModalSecondButtonClick'] = () => {};
            window['messageModalThirdButtonClick']  = () => {};
        }

    }

    /**
     * 호두 로딩
     */
    async hodu_loading() : Promise<void> {
        this.doSetLoadingEnd3(false);  
        this.doSetLoadingEnd2(false);  
        this.doSetLoadingEnd1(false);  
        this.doSetLoadingBouncy(true);

        loading_timer_bouncy = window.setTimeout(() => {
            this.doSetLoadingBouncy(false);
        },1000);
    
        loading_timer_end_1 = window.setTimeout(() => {
            this.doSetLoadingEnd1(true);
        }, 1100);
    
        loading_timer_end_2 = window.setTimeout(() => {
            this.doSetLoadingEnd2(true);
        }, 1500);

        loading_timer_end_3 = window.setTimeout(() => {
            this.doSetLoadingEnd3(true);
        }, 2000);
        
        // 테스트용 = alert 중복해서 여러번 나오는지 테스트
        // clear_timout_test = window.setTimeout(() => {
        //     alert("test");
        // }, 5000);
        
        // 테스트용 로그 - 번호 체크용
        // console.log(`========================================================================================`);
        // console.log(`${JSON.stringify(loading_timer_bouncy)}`);
        // console.log(`${JSON.stringify(loading_timer_end_1 )}`);
        // console.log(`${JSON.stringify(loading_timer_end_2 )}`);
        // console.log(`${JSON.stringify(loading_timer_end_3 )}`);        
        // console.log(`${JSON.stringify(clear_timout_test   )}`);
        // console.log(`========================================================================================`);
    }

    /**
     * 로딩 타이머 끝내기
     */
    async hodu_loading_timer_exit() : Promise<void> {

        // 테스트용 로그 - 번호 체크용
        // console.log(`========================================================================================`);
        // console.log(`${JSON.stringify(loading_timer_bouncy)}`);
        // console.log(`${JSON.stringify(loading_timer_end_1 )}`);
        // console.log(`${JSON.stringify(loading_timer_end_2 )}`);
        // console.log(`${JSON.stringify(loading_timer_end_3 )}`);        
        // console.log(`${JSON.stringify(clear_timout_test   )}`);
        // console.log(`========================================================================================`);

        if( loading_timer_bouncy ) { clearTimeout( loading_timer_bouncy); }
        if( loading_timer_end_1  ) { clearTimeout( loading_timer_end_1 ); }
        if( loading_timer_end_2  ) { clearTimeout( loading_timer_end_2 ); }
        if( loading_timer_end_3  ) { clearTimeout( loading_timer_end_3 ); }
        
        // 테스트용 alert timeout
        // if( clear_timout_test    ) { clearTimeout( clear_timout_test   ); }
    }

    /**
     * 웹 try - catch 또는 API 통신 에러 처리
     * @param  {any} e
     * @param  {boolean=true} show_modal
     * @param  {boolean=false} is_back_page
     * @param  {boolean=false} do_not_show_anything
     */
    async hodu_error_process(e : any, show_modal : boolean = true, is_back_page : boolean = false, do_not_show_anything : boolean = false, modal_callback ?: Function) : Promise<void> {

        console.log(e);

        if( e.response ) {
            
            if( e.response.data instanceof Blob ) { return; }
            
            // 현재 규정되지 않은 오류 처리
            if( e.response.data.indexOf(`<!DOCTYPE html>`) == -1 && e.response.data.indexOf(`api/v1`) == -1 ) {
                
                // 아무것도 보여주지 않기를 원한다면 그대로 빠져나감
                if( do_not_show_anything == true ) { return; }

                // modal을 보여주는게 아닌 경우 (ex : modal에서 사용하는 경우)
                if( show_modal == false ) {
                    alert(e.response.data);
                    if( is_back_page == true ) { this.movePrevPage(); }
                }
                
                // modal을 보여주는 경우
                else {
                    await this.hodu_show_dialog('cancel', e.response.data, ['확인'], [() => { 
                        if( is_back_page == true ) { this.movePrevPage(); }
                        if( modal_callback ) { modal_callback(); }
                    }]);
                }

            // api/v1이 data에 들어있다면 proxy 관련 문제 등으로 서버 통신 오류인 경우이므로 서버 통신 오류 다이얼로그 처리
            } else if( e.response.data.indexOf(`<!DOCTYPE html>`) == -1 && e.response.data.indexOf(`api/v1`) > -1 ) {
                
                // 아무것도 보여주지 않기를 원한다면 그대로 빠져나감
                if( do_not_show_anything == true ) { return; }
                
                // modal을 보여주는게 아닌 경우 (ex : modal에서 사용하는 경우)
                if( show_modal == false ) {
                    alert('서버 통신 오류, 잠시 후 다시 시도해주세요');
                    if( is_back_page == true ) { this.movePrevPage(); }
                }
                
                // modal을 보여주는 경우
                else {
                    await this.hodu_show_dialog('cancel', "서버 통신 오류, 잠시 후 다시 시도해주세요", ['확인'], [() => { 
                        if( is_back_page == true ) { this.movePrevPage(); }
                        if( modal_callback ) { modal_callback(); }
                    }]);
                }
            
            // <!DOCTYPE html>가 data에 들어있다면 통신 API URL 자체가 NOT FOUND인 경우이므로 Exception 생성
            } else {
                this.hodu_exception_add(e);
                if( is_back_page == true ) { this.movePrevPage(); }
            }

        } else {
            this.hodu_exception_add(e);
            if( is_back_page == true ) { this.movePrevPage(); }
        }
    }

    /**
     * 호두 Exception 등록
     * @param e - exception
     */
    async hodu_exception_add(e) : Promise<void> {
        
        const exception : ginus_exception_log = {
            exception_id : 0,
            user_id : this.user_id,
            exception_message : e.message,
            exception_trace : e.stack,
            os : 'WEB',
            os_version : await getBrowserInfo(),
            device_model : await getOSInfo(),
            app_version : this.getVersion(),
            package_name : `(${this.COMPONENT_NAME}) ${this.$router.currentRoute.path}`,
            crt_ymd : moment(moment().toDate()).format('YYYY-MM-DD HH:mm:ss')
        };

        this.hodu_api_call('api/v1/exception', API_METHOD.POST, exception, false)
            .then((response) => {})
            .catch((e) => {});
    }

    async getAdminCheck() : Promise<boolean> {
        const arr_admin_user_id = [3, 4, 7, 5674, 8021];

        let admin_flag = false;

        for (let i = 0; i < arr_admin_user_id.length; i++) {
            if ( arr_admin_user_id[i] == this.user_id ) {
                admin_flag = true;
                break;
            }
        }

        return admin_flag;
    }

    /**
     * hex값 스트링을 받고 argb 형식(9자리)이면 RGB형식으로 반환한다
     * @param color - 컬러 HEX값
     */
    hodu_hex_color_process(color ?: string | null, default_color ?: string ) : string {

        if( color == null || color.length < 7 ) {
            return default_color ? default_color : "#477fff";
        }

        if( color.length == 7 ) {
            return color;
        }
        
        return color.replace(/#(..)(......)/, '#$2');
    }

    /**
     * 컬러 HEX값을 받고 dc0~9 lc0~9로 반환
     * @param color - 컬러 HEX값
     */
    hodu_color_dc_lc(color : string | null, default_class : string = 'dc7') : string {

        color = this.hodu_hex_color_process(color, "#477fff").toUpperCase();

        switch(color) {
            case hodu_color.hodu_dc_0: return "dc0";
            case hodu_color.hodu_dc_1: return "dc1";
            case hodu_color.hodu_dc_2: return "dc2";
            case hodu_color.hodu_dc_3: return "dc3";
            case hodu_color.hodu_dc_4: return "dc4";
            case hodu_color.hodu_dc_5: return "dc5";
            case hodu_color.hodu_dc_6: return "dc6";
            case hodu_color.hodu_dc_7: return "dc7";
            case hodu_color.hodu_dc_8: return "dc8";
            case hodu_color.hodu_dc_9: return "dc9";
            case hodu_color.hodu_lc_0: return "lc0";
            case hodu_color.hodu_lc_1: return "lc1";
            case hodu_color.hodu_lc_2: return "lc2";
            case hodu_color.hodu_lc_3: return "lc3";
            case hodu_color.hodu_lc_4: return "lc4";
            case hodu_color.hodu_lc_5: return "lc5";
            case hodu_color.hodu_lc_6: return "lc6";
            case hodu_color.hodu_lc_7: return "lc7";
            case hodu_color.hodu_lc_8: return "lc8";
            case hodu_color.hodu_lc_9: return "lc9";
        }

        return default_class;
    }

    /**
     * 컬러 HEX값을 받고 imgDc0~9 lc0~9로 반환 (그룹용) 
     * imgLc는 없지만 파스텔톤이 나중에 생기는걸 대비해서 만들어놓음
     * @param color - 컬러 HEX값
     */
    hodu_color_dc_lc_for_group(color : string | null | undefined, default_class : string = 'imgDc7') : string {

        color = this.hodu_hex_color_process(color, "#477fff").toUpperCase();

        switch(color) {
            case hodu_color.hodu_dc_0: return "imgDc0";
            case hodu_color.hodu_dc_1: return "imgDc1";
            case hodu_color.hodu_dc_2: return "imgDc2";
            case hodu_color.hodu_dc_3: return "imgDc3";
            case hodu_color.hodu_dc_4: return "imgDc4";
            case hodu_color.hodu_dc_5: return "imgDc5";
            case hodu_color.hodu_dc_6: return "imgDc6";
            case hodu_color.hodu_dc_7: return "imgDc7";
            case hodu_color.hodu_dc_8: return "imgDc8";
            case hodu_color.hodu_dc_9: return "imgDc9";
            case hodu_color.hodu_lc_0: return "imgLc0";
            case hodu_color.hodu_lc_1: return "imgLc1";
            case hodu_color.hodu_lc_2: return "imgLc2";
            case hodu_color.hodu_lc_3: return "imgLc3";
            case hodu_color.hodu_lc_4: return "imgLc4";
            case hodu_color.hodu_lc_5: return "imgLc5";
            case hodu_color.hodu_lc_6: return "imgLc6";
            case hodu_color.hodu_lc_7: return "imgLc7";
            case hodu_color.hodu_lc_8: return "imgLc8";
            case hodu_color.hodu_lc_9: return "imgLc9";
        }

        return default_class;
    }

    /**
     * 컬러 HEX값을 받고 dc0~9 lc0~9에 해당하는 label 텍스트로 반환
     * @param color - 컬러 HEX값
     */
    hodu_color_dc_lc_label_text(color : string | null) : string {

        color = this.hodu_hex_color_process(color, "").toUpperCase();

        switch(color) {
            case hodu_color.hodu_dc_0 : return "빨강";
            case hodu_color.hodu_dc_1 : return "주황";
            case hodu_color.hodu_dc_2 : return "노랑";
            case hodu_color.hodu_dc_3 : return "자주";
            case hodu_color.hodu_dc_4 : return "청록";
            case hodu_color.hodu_dc_5 : return "초록";
            case hodu_color.hodu_dc_6 : return "하늘";
            case hodu_color.hodu_dc_7 : return "파랑";
            case hodu_color.hodu_dc_8 : return "보라";
            case hodu_color.hodu_dc_9 : return "검정";
            case hodu_color.hodu_lc_0 : return "연한 갈색";
            case hodu_color.hodu_lc_1 : return "연한 자주";
            case hodu_color.hodu_lc_2 : return "연한 빨강";
            case hodu_color.hodu_lc_3 : return "연한 보라";
            case hodu_color.hodu_lc_4 : return "연한 주황";
            case hodu_color.hodu_lc_5 : return "연한 노랑";
            case hodu_color.hodu_lc_6 : return "연한 초록";
            case hodu_color.hodu_lc_7 : return "연한 파랑";
            case hodu_color.hodu_lc_8 : return "연한 하늘";
            case hodu_color.hodu_lc_9 : return "연한 검정";
        }

        return "";
    }

    /**
     * 이미지 리사이즈
     * @param from_src - 이미지 URL
     * @param max_width - 최대 너비 (기본 1920)
     * @param max_height - 최대 높이 (기본 1920)
     * @param q - JPEG 퀄리티 (기본 90%)
     */
    async hodu_image_resize(from_src : any, max_width : number = 1920, max_height : number = 1920, q : number = 0.90) : Promise<Blob> {
        return (await this.hodu_image_resize_return_with_image_size(from_src, max_width, max_height, q)).return_blob;
    }

    async hodu_image_resize_return_with_image_size(from_src : any, max_width : number = 1920, max_height : number = 1920, q : number = 0.90) : Promise<any> {
        let return_blob : Blob | null = null;
        let allMetaData : any;

        const from_canvas : HTMLCanvasElement = document.createElement('canvas');
        const from_ctx : CanvasRenderingContext2D | null = from_canvas.getContext("2d");
        
        await this.hodu_image_load(from_src)
            .then(async(image) => {
                console.log(`image ${image.width}, ${image.height}`);
                
                await EXIF.getData(image, () => {
                    allMetaData = EXIF.getAllTags(image);
                    console.log(allMetaData);
                });

                console.log(`exif orientation : ${allMetaData.Orientation}`);

                if (4 < allMetaData.Orientation && allMetaData.Orientation < 9) {
                    from_canvas.width = image.height;
                    from_canvas.height = image.width;
                } else {
                    from_canvas.width  = image.width;
                    from_canvas.height = image.height;
                }

                if( from_ctx != null ) {
                    // transform context before drawing image
                    // switch (allMetaData.Orientation) {
                    //     case 2: from_ctx.transform(-1, 0, 0, 1, image.width, 0); break;
                    //     case 3: from_ctx.transform(-1, 0, 0, -1, image.width, image.height); break;
                    //     case 4: from_ctx.transform(1, 0, 0, -1, 0, image.height); break;
                    //     case 5: from_ctx.transform(0, 1, 1, 0, 0, 0); break;
                    //     case 6: from_ctx.transform(0, 1, -1, 0, image.height, 0); break;
                    //     case 7: from_ctx.transform(0, -1, -1, 0, image.height, image.width); break;
                    //     case 8: from_ctx.transform(0, -1, 1, 0, 0, image.width); break;
                    //     default: break;
                    // }

                    from_ctx.drawImage(image, 0, 0);
                    // await this.hodu_canvas_to_blob_promise(from_canvas).then(async(blob) => {
                    //     from_ctx.drawImage(blob, 0, 0);
                    // });
                }

            }).catch(async(e) => {
                throw e;
            });

        // console.log(`from ${from_canvas.width}, ${from_canvas.height}`);
        const ratio : number = from_canvas.width / from_canvas.height;

        // max_width, max_height 기본 값 1920px
        const to_canvas : HTMLCanvasElement = document.createElement('canvas');
        to_canvas.width = from_canvas.width;
        to_canvas.height = from_canvas.height;

        /**
         * 둘중 하나의 값이라도 max 값을 넘는 경우 큰쪽을 max 값으로 맞추고 작은쪽을 비율에 맞춘다
         */
        if( from_canvas.width > max_width || from_canvas.height > max_height ) {
            if( ratio > 1 ) {
                to_canvas.width = max_width;
                to_canvas.height = max_width / ratio;

                // 너비에 맞는 비율로 줄였을때 max_height보다 크다면 height를 max_height로 바꾸고 width를 비율에 맞게 조정한다
                if( to_canvas.height > max_height ) {
                    to_canvas.width  = max_height * ratio;
                    to_canvas.height = max_height;
                }

            } else {
                to_canvas.width  = max_height * ratio;
                to_canvas.height = max_height;

                // 높이에 맞는 비율로 줄였을때 max_width보다 크다면 height를 max_height로 바꾸고 width를 비율에 맞게 조정한다
                if( to_canvas.width > max_width ) {
                    to_canvas.width = max_width;
                    to_canvas.height = max_width / ratio;
                }
            }
        }

        console.log(`to image : ${to_canvas.width}, ${to_canvas.height}`);
        
        const pica = Pica();
        await pica.resize(from_canvas, to_canvas)
            .then(async result => pica.toBlob(result, 'image/jpeg', q))
            .then(async(blob : Blob) => return_blob = blob );
        
        if( return_blob == null ) {
            throw new Error("blob is null");
        }

        // await this.hodu_image_load2(from_src, allMetaData.Orientation)
        //     .then(async(image) => {
        //         console.log(`image ${image.width}, ${image.height}`);
        //         from_canvas.width  = image.width;
        //         from_canvas.height = image.height;

        //         if( from_ctx != null ) {
        //             from_ctx.drawImage(image, 0, 0);
        //         }

        //     }).catch(async(e) => {
        //         throw e;
        //     });

        // // console.log(`to ${to_canvas.width}, ${to_canvas.height}`);
        // await this.hodu_canvas_to_blob_promise(from_canvas).then(async(blob) => {
        //     return_blob = blob;
        // });

        if( return_blob == null ) {
            throw new Error("blob is null");
        }
        
        return { "return_blob" : return_blob, image_info : { "width" : to_canvas.width, "height" : to_canvas.height } };
    }

    /**
     * 이미지 로드 Promise
     * @param src - 이미지 경로
     */
    hodu_image_load(src : string) : Promise<any> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = (e) => { resolve(image); }
            image.onerror = () => reject;
            image.src = src;
        })
    }

    /**
     * 이미지 로드 Promise 2
     * @param src - 이미지 경로
     */
    hodu_image_load2(src : string, orientation : number) : Promise<any> {
        return new Promise((resolve, reject) => {

            // @ts-ignore
            loadImage(src, (image) => { resolve(image); }, { "orientation" : orientation } );

        })
    }

    hodu_canvas_to_blob_promise(canvas : HTMLCanvasElement) : Promise<any> {
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => { resolve(blob); })
        });
    }
    
    /**
     * Blob을 File로 변환
     * @param  {Blob} blob - 변경할 Blob
     * @param  {string} name - 지정할 파일 이름
     */
    async hodu_blob_to_file(blob : Blob, name : string) : Promise<File> {
        return new File([blob], name, { type: blob.type, lastModified : new Date().getTime()});
    }

    /**
     * 비즈 메인화면 이동할때 사용하는 변수 초기화
     */
    async reset_biz_initialization() : Promise<void> {
        this.doSetCalendarId(`personal-${this.user_id}`);
        this.doSetScope(OWNER_TYPE.PERSONAL);
        this.doSetScopeGroupId(0);
        this.doSetScopeTeamId(0);
        this.doSetScopeGroupTeamOption({});
    }

    /**
     * 그룹 변수 초기화
     */
    async reset_group_initialization() : Promise<void> {
        this.doGroupStatisticsStartDate("");
        this.doGroupId(0);
        this.doTeamId(0);
        this.doGroupTeamOption({});
    }

    /**
     * 인디케이터 띄우기
     * @param  {boolean=false} exist_time_out     - 타임아웃을 걸건지 여부
     * @param  {number=15000} timeout_time_millis - 타임아웃 시간 (ms)
     */
    async hodu_show_indicator(exist_time_out : boolean = false, timeout_time_millis : number = 15000) : Promise<void> {
        this.focus_view = $(':focus');
        if( this.focus_view   ) { this.focus_view.blur(); }
        $('.loadingDiv').show();
        
        // 타임아웃 설정
        if( exist_time_out == true ) {
            indicator_timeout = window.setTimeout(() => { $('.loadingDiv').hide(); }, timeout_time_millis);
        }
        
    }

    /**
     * 인디케이터 없애기
     */
    async hodu_hide_indicator() : Promise<void> {
        $('.loadingDiv').hide();
        if( this.focus_view   ) { this.focus_view.focus(); }
        if( indicator_timeout ) { clearTimeout(indicator_timeout) }
    }

    /**
     * 양력을 음력으로 전환
     * @param {Date|string} date - 양력 날짜
     * @returns Promise<string>  - 음력 날짜
     */
    async hodu_solar_to_lunar(date : Date | string) : Promise<string> {
        let lunar_date : string = "";

        date = date instanceof Date ? date : new Date(moment(date).format());

        await this.hodu_api_call(`api/v1/date/lunar?solar_date=${moment(date).format('YYYYMMDD')}`, API_METHOD.GET, null, false)
            .then(async(response) => {
                console.log(response);
                lunar_date = response.data;
            })
            .catch(async(e) => {
                lunar_date = moment(date).lunar().format('YYYY-MM-DD');
            })

        return lunar_date;
    }

    /**
     * 양력기간을 음력기간으로 전환
     * @param {Date|string} start_date - 양력 시작 날짜
     * @param {Date|string} end_date   - 양력 종료 날짜
     * @returns Promise<any[]> - 날짜 정보 데이터 배열
     */
    async hodu_solars_to_lunars(start_date : Date | string, end_date : Date | string) : Promise<any[]> {
        let lunar_dates : any[] = [];

        start_date = start_date instanceof Date ? start_date : new Date(moment(start_date).format());
        end_date   = end_date   instanceof Date ? end_date   : new Date(moment(end_date).format());

        await this.hodu_api_call(`api/v1/date/lunars?solar_start=${moment(start_date).format('YYYYMMDD')}&solar_end=${moment(end_date).format('YYYYMMDD')}`, API_METHOD.GET, null, false)
            .then(async(response) => {
                console.log(response);
                try {
                    lunar_dates = response.data.data.lunar_dates;
                } catch(e) {
                    lunar_dates = [];
                }
            })
            .catch(async(e) => {
                lunar_dates = [];
            })

        return lunar_dates;
    }

    /**
     * 음력을 양력으로 변환
     * @param  {string} date    - 음력 날짜
     * @param  {number} leap_yn - 윤달 여부
     * @returns Promise<Date>   - 양력 날짜
     */
    async hodu_lunar_to_solar(date : string, leap_yn : boolean) : Promise<Date> {
        let solar_date : Date = new Date();

        date = date.replace(/-/ig, '');

        await this.hodu_api_call(`api/v1/date/solar?lunar_date=${date}&leap_yn=${leap_yn}`, API_METHOD.GET, null, false)
            .then(async(response) => {
                console.log(response);
                try {
                    solar_date = new Date(moment(response.data).format());
                } catch(e) {
                    solar_date = new Date(moment(date).solar().format());
                }
            })
            .catch(async(e) => {
                solar_date = new Date(moment(date).solar().format());
            })

        return solar_date;
    }
    
    /**
     * 음력을 양력기간(2050년까지의 음력을 전부 변환해서 가져온 데이터)으로 변환
     * @param  {string}  date    - 음력 날짜
     * @param  {boolean} leap_yn - 윤달 여부
     * @returns Promise<any[]>   - 날짜 정보 데이터 배열
     */
    async hodu_lunar_to_solars(date : string, leap_yn : boolean = false) : Promise<any[]> {
        let solar_dates : any = [];

        date = date.replace(/-/ig, '');

        await this.hodu_api_call(`api/v1/date/solars?lunar_date=${date}&leap_yn=${leap_yn}`, API_METHOD.GET, null, false)
            .then(async(response) => {
                console.log(response);
                try {
                    solar_dates = response.data.data.solar_dates;
                } catch(e) {
                    solar_dates = [];
                }
            })
            .catch(async(e) => {
                solar_dates = [];
            })

        return solar_dates;
    }

    /**
     * 공휴일 데이터 가져오기
     * @param  {Date|string} solar_start - 양력 시작일
     * @param  {Date|string} solar_end   - 양력 종료일
     * @returns Promise<any[]> - 날짜 정보 데이터 배열
     */
    async hodu_get_holidays(solar_start : Date | string, solar_end : Date | string) : Promise<any[]> {
        let holi_days : any[] = [];

        solar_start = solar_start instanceof Date ? solar_start : new Date(moment(solar_start).format());
        solar_end   = solar_end   instanceof Date ? solar_end   : new Date(moment(solar_end).format());
        
        let query = `solar_start=${moment(solar_start).format('YYYYMMDD')}`;
        query    += `&solar_end=${moment(solar_end).format('YYYYMMDD')}`;

        await this.hodu_api_call(`api/v1/date/holidays?${query}`, API_METHOD.GET, null, false)
            .then((response) => {
                console.log(response);
                try {
                    holi_days = response.data.data.holi_days;
                } catch(e) {
                    holi_days = [];
                }
            })
            .catch((e) => {
                holi_days = [];
            });
        
        return holi_days;
    }

    /**
     * 해당 양력일의 음력이 윤달인지 여부 체크
     * @param solart_date - 양력 날짜
     */
    async hodu_is_intercalation(solart_date : Date | string) : Promise<any> {

        let is_intercalation : boolean = false;
        let next_date_obj    : any[]   = [];

        solart_date = solart_date instanceof Date ? solart_date : new Date(moment(solart_date).format());

        await this.hodu_api_call(`api/v1/date/intercalation?solar_date=${moment(solart_date).format('YYYYMMDD')}`, API_METHOD.GET, null, false)
            .then(async(response) => {
                console.log(response);
                is_intercalation = response.data.data.is_intercalation;
                next_date_obj    = response.data.data.next_date_obj;
            })
            .catch(async(e) => {
                is_intercalation = false;
                next_date_obj    = [];
            })

        return { is_intercalation, next_date_obj };
    }
    
    /**
     * 웹 앱 버전 리턴
     */
    getVersion() : string {
        return APP_VERSION;
    }

    /**
     * 웹 앱 빌드 버전 리턴
     */
    getBuildVersion() : string {
        return BUILD_VERSION;
    }

    /**
     * day_of_week 숫자
     * @param value "?요일"
     */
    getDayName(value: number, additional_string : string = "") : string {
        switch (value) {
            case 0 :
                return "일" + additional_string;
            case 1 :
                return "월" + additional_string;
            case 2 :
                return "화" + additional_string;
            case 3 :
                return "수" + additional_string;
            case 4 :
                return "목" + additional_string;
            case 5 :
                return "금" + additional_string;
            case 6 :
                return "토" + additional_string;
            default :
                return ""
        }
    }

    /**
     * 날짜를 받고 요일로 반환
     * @param date - 날짜
     * @param additional_string - 뒤에 추가로 붙을 문자열
     */
    getDayOfWeekByDate(date : string | Date | undefined, additional_string : string = "") : string {
        try {
            if( date == null ) return '';
            
            date = date instanceof Date ? date : new Date(date);

            switch( date.getDay() ){
                case 0:
                    return "일" + additional_string;

                case 1:
                    return "월" + additional_string;

                case 2:
                    return "화" + additional_string;

                case 3:
                    return "수" + additional_string;

                case 4:
                    return "목" + additional_string;

                case 5:
                    return "금" + additional_string;

                case 6:
                    return "토" + additional_string;

                default:
                    return "";
                    
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return "";
    }
    
    /**
     * AM, PM이 포함 된 날짜 문자열을 오전, 오후로 바꿔서 보내준다
     * @param  {string} date_string - 날짜 string
     * @returns string
     */
    amPmStringToLocaleAmPmString(date_string : string) : string {
        if( date_string == null ) { return ""; }

        let return_date_string : string = date_string.trim().toUpperCase();

        try {
            let type = navigator.appName;
            let country_code = 'ko-KR';
            if (type == 'Netscape') {
                country_code = navigator.language;
            } else {
                country_code = navigator['userLanguage'];
            }
            
            if( country_code != 'ko-KR' ) {
                return return_date_string;
            }
        } catch(e){
            this.hodu_error_process(e, false, false, true);
        }
        
        // locale이 한국이라면
        // AM → 오전
        if( return_date_string.indexOf("AM") > -1 ) {
            return_date_string = return_date_string.replace(/AM/g, "").trim();

            // 마지막 공백의 index를 구한다
            const last_space_index : number = return_date_string.lastIndexOf(" ");
            const regexp_stirng : RegExp = new RegExp(`(.{${last_space_index + 1}})`);
            return_date_string = return_date_string.replace(regexp_stirng, "$1 오전 ");
        }

        // PM → 오후
        if( return_date_string.indexOf("PM") > -1 ) {
            return_date_string = return_date_string.replace(/PM/g, "").trim();

            // 마지막 공백의 index를 구한다
            const last_space_index : number = return_date_string.lastIndexOf(" ");
            const regexp_stirng : RegExp = new RegExp(`(.{${last_space_index + 1}})`);
            return_date_string = return_date_string.replace(regexp_stirng, "$1 오후 ");
        }
        
        return_date_string = return_date_string.replace("  ", " ");
        return return_date_string.trim();
    }
    
    /**
     * 호두 D 정보를 biz_id로 전부 가져오고 세팅한다
     * @param  {string} biz_id
     * @returns Promise<void>
     */
    get_hodu_d_info(biz_id : string) : Promise<void> {

        return new Promise(async(resolve, reject) => {
            Promise.all([
                // 2020-11-26 LSJ - 병렬처리를 위해 await 제거했음
                this.get_hospital_info(biz_id),
                this.get_hospital_setting_info(biz_id),
                this.get_department_info(biz_id),
                this.get_doctor_info(biz_id),
                this.get_patient_info(biz_id)
            ]).then(() => {
                // console.log("resolve");
                resolve();
            })
            .catch((e) => {
                // this.hodu_error_process(e, false, false, true);
                reject(e);
            });
        });
    }
    
    /**
     * 병원 정보 세팅
     * @param  {string} biz_id
     * @returns Promise<void>
     */
    async get_hospital_info(biz_id : string) : Promise<void> {
        // console.log("Before get_hospital_info");
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${biz_id}`, API_METHOD.GET)
            .then((response) => { 
                if( this.doSetHospitalInfo ) { this.doSetHospitalInfo(response.data.data.hospital_info); }
                else { throw new Error("doSetHospitalInfo is not defined"); }
                // console.log("After get_hospital_info");
            })
            .catch((e) => {
                throw e;
            });
    }
    
    /**
     * 병원 세팅 정보 세팅
     * @param  {string} biz_id
     * @returns Promise<void>
     */
    async get_hospital_setting_info(biz_id : string) : Promise<void> {
        // console.log("Before get_hospital_setting_info");
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${biz_id}/timetable`, API_METHOD.GET)
            .then((response) => {
                if( this.doSetHospitalSettingInfo ) { this.doSetHospitalSettingInfo(response.data.data); }
                else { throw new Error("doSetHospitalSettingInfo is not defined"); }
                // console.log("After get_hospital_setting_info");
            })
            .catch((e) => {
                throw e;
            });
    }

    /**
     * 진료과 정보 세팅
     * @param  {string} biz_id
     * @returns Promise<void>
     */
    async get_department_info(biz_id : string) : Promise<void> {
        // console.log("Before get_department_info");
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${biz_id}/department?search_word=`, API_METHOD.GET)
            .then((response) => {
                if( this.doSetDepartmentInfo ) { this.doSetDepartmentInfo(response.data.data); }
                else { throw new Error("doSetDepartmentInfo is not defined"); }
                // console.log("After get_department_info");
            })
            .catch((e) => {
                throw e;
            });

    }

    /**
     * 의사 정보 세팅
     * @param  {string} biz_id
     * @returns Promise<void>
     */
    async get_doctor_info(biz_id : string) : Promise<void> {
        // console.log("Before get_doctor_info");
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${biz_id}/doctor?department_code=&search_word=`, API_METHOD.GET)
            .then((response) => {
                if( this.doSetDoctorInfo ) { this.doSetDoctorInfo(response.data.data); }
                else { throw new Error("doSetDoctorInfo is not defined"); }
                // console.log("After get_doctor_info");
            })
            .catch((e) => {
                throw e;
            });

    }

    /**
     * 환자 정보 세팅
     * @param  {string} biz_id
     * @returns Promise<void>
     */
    async get_patient_info(biz_id : string) : Promise<void> {
        // console.log("Before get_patient_info");
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${biz_id}/patient`, API_METHOD.GET)
            .then((response) => {
                if( this.doSetPatientInfo ) { this.doSetPatientInfo(response.data.data); }
                else { throw new Error("doSetPatientInfo is not defined"); }
                // console.log("After get_patient_info");
            })
            .catch((e) => {
                throw e;
            });
    }
    
    /**
     * CRUD 모드 CREATE 여부
     * @param  {CRUD_TYPE} crud_type
     * @returns boolean
     */
    isCreate(crud_type : CRUD_TYPE | string) : boolean {
        return crud_type == CRUD_TYPE.CREATE;
    }

    /**
     * CRUD 모드 READ 여부
     * @param  {CRUD_TYPE} crud_type
     * @returns boolean
     */
    isRead(crud_type : CRUD_TYPE | string) : boolean {
        return crud_type == CRUD_TYPE.READ;
    }

    /**
     * CRUD 모드 UPDATE 여부
     * @param  {CRUD_TYPE} crud_type
     * @returns boolean
     */
    isUpdate(crud_type : CRUD_TYPE | string) : boolean {
        return crud_type == CRUD_TYPE.UPDATE;
    }

    /**
     * CRUD 모드 DELETE 여부
     * @param  {CRUD_TYPE} crud_type
     * @returns boolean
     */
    isDelete(crud_type : CRUD_TYPE | string) : boolean {
        return crud_type == CRUD_TYPE.DELETE;
    }
    
    /**
     * 해당 그룹의 타입으로 BIZC인지 구분
     * @param  {GROUP_TYPE} biz_type_or_group_type
     * @returns boolean
     */
    isHoduC(biz_type_or_group_type : GROUP_TYPE) : boolean {
        if( biz_type_or_group_type == null )  { return false; }
        if( biz_type_or_group_type == GROUP_TYPE.BIZC ) { return true; }

        return false;
    }

    /**
     * 해당 그룹의 타입으로 BIZD인지 구분
     * @param  {GROUP_TYPE} biz_type_or_group_type
     * @returns boolean
     */
    isHoduD(biz_type_or_group_type : GROUP_TYPE) : boolean {
        if( biz_type_or_group_type == null )  { return false; }
        if( biz_type_or_group_type == GROUP_TYPE.BIZD ) { return true; }

        return false;
    }

    /**
     * 해당 그룹의 타입으로 BIZH인지 구분
     * @param  {GROUP_TYPE} biz_type_or_group_type
     * @returns boolean
     */
    isHoduH(biz_type_or_group_type : GROUP_TYPE) : boolean {
        if( biz_type_or_group_type == null )  { return false; }
        if( biz_type_or_group_type == GROUP_TYPE.BIZH ) { return true; }

        return false;
    }

    /**
     * 호두 프리미엄 그룹 도메인 서비스로 로그인 했는지 구분
     */
    isHoduDomainService() : boolean {
        return this.scope_group_team_option != null && this.scope_group_team_option.is_domain_service == true;
    }

    /**
     * 개인 이벤트 여부 
     * @param  {OWNER_TYPE} owner_type
     * @returns boolean
     */
    isPersonalScope(owner_type : OWNER_TYPE | string | undefined) : boolean {
        return owner_type == null || owner_type.length < 1 || owner_type == OWNER_TYPE.PERSONAL;
    }

    /**
     * 그룹 이벤트 여부 
     * @param  {OWNER_TYPE} owner_type
     * @returns boolean
     */
    isGroupScope(owner_type : OWNER_TYPE | string | undefined) : boolean {
        return owner_type == OWNER_TYPE.GROUP;
    }

    /**
     * 팀 이벤트 여부 
     * @param  {OWNER_TYPE} owner_type
     * @returns boolean
     */
    isTeamScope(owner_type : OWNER_TYPE | string | undefined) : boolean {
        return owner_type == OWNER_TYPE.TEAM;
    }

    /**
     * 일정 여부
     * @param  {EVENT_SUB_TYPE} event_sub_type
     * @returns boolean
     */
    isSchedule(event_sub_type : EVENT_SUB_TYPE | string | undefined) : boolean {
        return event_sub_type == EVENT_SUB_TYPE.SCHEDULE;
    }

    /**
     * 카드 여부
     * @param  {EVENT_SUB_TYPE} event_sub_type
     * @returns boolean
     */
    isCard(event_sub_type : EVENT_SUB_TYPE | string | undefined) : boolean {
        return event_sub_type == EVENT_SUB_TYPE.CARD;
    }

    /**
     * 일반 일정 여부
     * @param  {EVENT_SUB_TYPE} event_sub_type
     */
    isEvent(event_sub_type : EVENT_SUB_TYPE | string | undefined) : boolean {
        return this.isSchedule(event_sub_type) || this.isCard(event_sub_type);
    }

    /**
     * 업무 여부
     * @param  {EVENT_SUB_TYPE} event_sub_type
     * @returns boolean
     */
    isWork(event_sub_type : EVENT_SUB_TYPE | string | undefined) : boolean {
        return event_sub_type == EVENT_SUB_TYPE.WORK;
    }

    /**
     * 병원 예약 여부
     * @param  {EVENT_SUB_TYPE} event_sub_type
     * @returns boolean
     */
    isAppointment(event_sub_type : EVENT_SUB_TYPE | string | undefined) : boolean {
        return event_sub_type == EVENT_SUB_TYPE.APPOINTMENT;
    }

    /**
     * 업무일지 여부
     * @param  {EVENT_SUB_TYPE} event_sub_type
     * @returns boolean
     */
    isReport(event_sub_type : EVENT_SUB_TYPE | string | undefined) : boolean {
        return event_sub_type == EVENT_SUB_TYPE.REPORT;
    }
    
    /**
     * 회의록 여부
     * @param  {EVENT_SUB_TYPE} event_sub_type
     * @returns boolean
     */
    isMeetingLog(event_sub_type : EVENT_SUB_TYPE | string | undefined) : boolean {
        return event_sub_type == EVENT_SUB_TYPE.MEETINGLOG;
    }

    /**
     * moment 객체 반환
     * @param {Date|string|moment.Moment} date 
     */
    hodu_moment(date : Date | string | moment.Moment) : moment.Moment {
        return date ? moment(date) : moment();
    }
    
    /**
     * Date를 특정 포맷의 string으로 리턴 하는 함수
     * @param  {Date|string|moment.Moment} date
     * @param  {string} format
     * @returns string
     */
    hodu_date_to_format_string(date : Date | string | moment.Moment | undefined, format : string) : string {
        try {
            return moment(date).format(format);
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return '';
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    hodu_yyyymmdd_to_date(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 호두홈에서 현재 들어온 아파트의 관리자인지 확인
     */
    is_hodu_home_manager(group_id ?: number) : boolean {
        if( group_id == null && this.scope == OWNER_TYPE.PERSONAL ) {
            return false;
        }

        for ( let group_data of this.all_group_data ) {
            if( group_data.group_id != (group_id ? group_id : this.scope_group_id) || group_data.role.length < 1 ) { continue; }
            
            return (group_data.role[0] == 'GROUP_MANAGER' || group_data.role[0] == 'ADMIN');
        }
        return false;
    }

    /**
     * 호두홈에서 현재 들어온 아파트의 부관리자인지 확인
     */
    is_hodu_home_sub_manager(group_id ?: number) : boolean {
        if( group_id == null && this.scope == OWNER_TYPE.PERSONAL ) {
            return false;
        }

        for ( let group_data of this.all_group_data ) {
            if( group_data.group_id != (group_id ? group_id : this.scope_group_id) || group_data.role.length < 1 ) { continue; }
            
            return group_data.role[0] == 'GROUP_SUB_MANAGER';
        }
        return false;
    }

    /**
     * 문자열을 URL등의 하이퍼링크 대상을 <a></a> 로 바꿔서 반환
     * @param target_string - 대상 문자열
     */
    hodu_make_href(target_string : string) : string {
        if( target_string == null || target_string.trim().length < 1 ) return "";

        const autolinker = new Autolinker({ newWindow : true, stripPrefix : false, className : "hodu-link" });

        const result = autolinker.link(target_string.trim());

        if( result == null || result.trim().length < 1 ) return "";

        return result.trim();
    }

    /**
     * scope (개인, 그룹, 팀)의 id에 따른 프로필 이미지 URL 반환
     * @param  {string} scope
     * @param  {number} scope_id
     */
    hodu_profile_image_url(scope : string, scope_id : number) : string {
        return `app_images/profile/${scope.toLowerCase()}/${Math.floor(scope_id / 10000)}/${scope_id}.jpg`;
    }

    /**
     * 유저 환경설정 동기화
     */
    async sync_user_preference(show_indicator : boolean = true) : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/user/me/preference`, API_METHOD.GET, null, show_indicator);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.preference ) {
                throw new Error("유저 환경설정 동기화 중 오류 발생");
            }
            
            this.doSetUserPreference(response.data.data.preference);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 유저 환경설정 동기화 후 특정 카테고리의 환경설정 가져오기 
     * @param category 
     */
    async get_user_preference(category : string, show_indicator : boolean = true, do_sync : boolean = true) : Promise<any> {
        if( show_indicator ) await this.hodu_show_indicator();
        if( do_sync ) await this.sync_user_preference(show_indicator);
        if( show_indicator ) await this.hodu_hide_indicator();

        const target = this.user_preference.filter(preference => preference.cate == category);

        if( target.length < 1 )  {
            return null;
        }

        return target[0]; 
    }

    /**
     * 유저 환경설정 세팅
     */
    async set_user_preference(category : string, body : Object, show_indicator : boolean = true) : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/user/me/preference/${category}`, API_METHOD.PUT, body, show_indicator);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.preference ) {
                throw new Error("유저 환경설정 세팅 중 오류 발생");
            }
            
            this.doSetUserPreference(response.data.data.preference);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 호두키즈 여부 반환
     */
     isHoduKids(group_id : number) : boolean {

        for ( let group of this.group_info_list ) {
            if ( group.group_id == group_id ) {
                return group.group_info.project_type == 'kids';
            }
        }

        return false;
    }

    /**
     * 외부 프로젝트 연동인 경우 존재하는 그룹의 partner_key 반환
     */
    getGroupPartnerKey(group_id : number) : string {

        for ( let group of this.group_info_list ) {
            if ( group.group_id == group_id ) {
                return group.group_info.partner_key ? group.group_info.partner_key : '';
            }
        }

        return '';
    }

    /**
     * 호두홈 주차관리 아마노 여부
     */
     isHoduAmano(group_id : number) : boolean {

        for ( let group of this.group_info_list ) {
            if ( group.group_id == group_id ) {
                return group.group_info.car_manager == 'AMANO';
            }
        }

        return false;
    }

    /**
     * 필터 정보 가져오기 및 세팅
     */
     async getFilterInfo(callback ?: Function) : Promise<void> {
        try {
            const filter_info = await this.get_user_preference('filter', true);

            if( filter_info == null ) {
                local_storage_info.filter_info = {
                    basic : true,
                    work : true,
                    report : true,
                    meetinglog : true,
                    vacation : true,
                    businesstrip : true,
                }
                await this.doSetScheduleSearchConfig({
                    past_schedule : true,
                    personal_schedule : true,
                    my_schedule : true,
                    shared_schedule : true,
                    group_schedule : true,
                    hodu_c_schedule : true,
                    hodu_d_schedule : true,
                    group_appointment : true,
                    system_calendar : true,
                    group_filter : [],
                    team_filter : [],
                    group_and_team_filter : [],
                    hodu_d_filter : [],
                    group_appointment_filter : [],       
                    update_tag : 0,
                });
                return;
            }

            const preference = filter_info.preference;
            const web_filter_info = preference['WEB'];
        
            const event_sub_type_filter_info = web_filter_info.filter_info;
            const schedule_search_config = web_filter_info.schedule_search_config;

            if( event_sub_type_filter_info != null ) {
                local_storage_info.filter_info = JSON.parse(JSON.stringify(event_sub_type_filter_info));
            }
            else {
                local_storage_info.filter_info = {
                    basic : true,
                    work : true,
                    report : true,
                    meetinglog : true,
                    vacation : true,
                    businesstrip : true,
                }
            }

            if( schedule_search_config != null ) {
                if( schedule_search_config.system_calendar == null ) {
                    schedule_search_config.system_calendar = true;
                }
                await this.doSetScheduleSearchConfig(schedule_search_config);
            }
            else {
                await this.doSetScheduleSearchConfig({
                    past_schedule : true,
                    personal_schedule : true,
                    my_schedule : true,
                    shared_schedule : true,
                    group_schedule : true,
                    hodu_c_schedule : true,
                    hodu_d_schedule : true,
                    group_appointment : true,
                    system_calendar : true,
                    group_filter : [],
                    team_filter : [],
                    group_and_team_filter : [],
                    hodu_d_filter : [],
                    group_appointment_filter : [],       
                    update_tag : 0,
                });
            }
            

        } catch(e) {
            await this.hodu_error_process(e, false, false, true);
            local_storage_info.filter_info = {
                basic : true,
                work : true,
                report : true,
                meetinglog : true,
                vacation : true,
                businesstrip : true,
            }
            await this.doSetScheduleSearchConfig({
                past_schedule : true,
                personal_schedule : true,
                my_schedule : true,
                shared_schedule : true,
                group_schedule : true,
                hodu_c_schedule : true,
                hodu_d_schedule : true,
                group_appointment : true,
                system_calendar : true,
                group_filter : [],
                team_filter : [],
                group_and_team_filter : [],
                hodu_d_filter : [],
                group_appointment_filter : [],       
                update_tag : 0,
            });
        } finally {
            this.$forceUpdate();
            await callback?.();
        }
    }

    /**
     * 인증
     */
    async firebase_auth(authResult, auth_check_later, callback) : Promise<void> {
        try {
        
            const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
            const request_phone_number = authResult?.user?.phoneNumber ?? '';

            console.log(phoneUtil);

            let response;

            if( auth_check_later == true ) {
                
                response = await this.hodu_api_call('api/v1/auth', API_METHOD.POST, {
                    "auth_check" : false,
                    "user_id" : this.user_id,
                    "auth_check_later" : true,
                });

            }
            else {
                if( request_phone_number == null || request_phone_number.length < 1 ) {
                    throw new Error("인증 절차 진행 중 오류 발생");
                }
    
                const phoneNumber = phoneUtil.parse(request_phone_number, '');
    
                const country_code = phoneNumber.getCountryCode();
                const national_number = phoneNumber.getNationalNumber();
                let national_number_string = String(national_number);
    
                if( country_code == 82 ) {
                    national_number_string = "0" + national_number_string;
                }

                response = await this.hodu_api_call('api/v1/auth', API_METHOD.POST, {
                    "auth_check" : true,
                    "user_id" : this.user_id,
                    "country_code" : String(country_code), 
                    "user_phone_number" : national_number_string,
                });
            }

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.user ) {
                throw new Error("인증 절차 진행 중 오류 발생");
            }

            callback?.(response.data.data.user);

        } catch(e) {
            alert(e);
            this.hodu_show_dialog('cancel', '인증 절차 진행 중 오류 발생', ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }
     
    /**
     * 문자열 검증 후 반환
     * @param  {string} str
     * @param  {string='-'} err_str
     */
    hodu_string_validation(str : string, err_str : string = '-') : string {
        try {
            if( err_str == null ) err_str = '-';
            if( str == null || str.trim().length < 1 ) return err_str;
            return str.trim();
        } catch(e) {
            return err_str;
        }
    }

    /**
     * 캘린더 환경설정 싱크
     * @param show_indicator 
     */
    async calendar_preference_sync(show_indicator : boolean = true, do_sync : boolean = true) : Promise<void> {
        try {
            const calendar_preference = await this.get_user_preference("calendar", show_indicator, do_sync);

            if( calendar_preference == null || calendar_preference.preference == null || calendar_preference.preference.schedule_time_type == null ) {
                this.doSetScheduleTimeType?.("NONE");
            }
            else {
                this.doSetScheduleTimeType?.(calendar_preference.preference.schedule_time_type);
            }
            
            window['calendar_reload']?.();

        } catch(e) {    
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 친구 조회
     */
     async getFriends(show_indicator : boolean = false) : Promise<void> {

        try {

            const response = await this.hodu_api_call(`api/v1/friend`, API_METHOD.GET, null, show_indicator);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.friends ) {
                throw new Error("친구 조회 중 오류 발생");
            }

            this.doSetFriends(response.data.data.friends);

        } catch(e) {
            if( show_indicator ) this.hodu_show_dialog("cancel", "친구 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 친구 찾기
     * @param user_id 
     * @returns 
     */
    getFriend(user_id : number) : any {
        let friend = null;

        const target = this.friends.filter(friend => friend.friend_user_id == user_id);

        if( target.length < 1 ) {
            return null;
        }

        friend = target[0];

        return friend;
    }

    /**
     * 친구 이름 반환
     */
    getFriendName(friend) : string {

        if( friend.friend_custom_nickname != null && friend.friend_custom_nickname.trim().length > 0 ) {
            return friend.friend_custom_nickname.trim();
        }

        if( friend.friend_contacts_name != null && friend.friend_contacts_name.trim().length > 0 ) {
            return friend.friend_contacts_name.trim();
        }

        if( friend.friend_name != null && friend.friend_name.trim().length > 0 ) {
            return friend.friend_name.trim();
        }

        return "";
    }

    /**
     * 유저 프로필 이미지 URL 만들고 반환
     * @param user_id 
     */
    make_user_profile_image_url(user_id : number) : string {
        return `app_images/profile/user/${Math.floor(user_id / 10000)}/${user_id}.jpg`;
    }

    /**
     * 그룹 프로필 이미지 URL 만들고 반환
     * @param group_id 
     */
    make_group_profile_image_url(group_id : number) : string {
        return `app_images/profile/group/${Math.floor(group_id / 10000)}/${group_id}.jpg`;
    }

    /**
     * 팀 프로필 이미지 URL 만들고 반환
     * @param team_id 
     */
    make_team_profile_image_url(team_id : number) : string {
        return `app_images/profile/team/${Math.floor(team_id / 10000)}/${team_id}.jpg`;
    }

    /**
     * 문자열 a에 문자열 b가 포함 되는가
     * @param a 
     * @param b 
     * @returns 
     */
    hodu_string_includes(a : string, b : string) : boolean {
        return (a ?? "").trim().toUpperCase().normalize("NFC").includes((b ?? "").trim().toUpperCase().normalize("NFC"));
    }

    /**
     * 단축 URL 변환
     * @param  {string} url
     */
    async hodu_shorten_url(url : string) : Promise<string> {

        let shorten_url = "";

        try {

            // const response = await this.hodu_api_call(`api/v1/hodu/naver/shorten_url`, API_METHOD.POST, { "url" : url });
            const response = await this.hodu_api_call(`api/v1/hodu/shorten_url`, API_METHOD.POST, { "url" : url });

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.result || !response.data.result.url || response.data.result.url.length < 1 ) {
                throw new Error("단축 URL 변환 중 오류 발생");
            }

            shorten_url = response.data.result.url;

        } catch(e) {
            throw e;
        }

        return shorten_url;
    }

    
    /**
     * 가입된 그룹이 프리미엄 그룹인지 체크
     * @param  {number} group_id
     */
    is_premium_group(scope : string, scope_id : number) : boolean {

        let is_premium : boolean = false;

        if( (scope != "GROUP" && scope != "TEAM") || scope_id < 1 ) {
            return false;
        }

        for( const group_or_team of this.all_group_data_temp ) {
            if( (scope == 'GROUP' && group_or_team.group_id != scope_id) ||
                (scope == 'TEAM' && group_or_team.team_id != scope_id)  ) continue;
            is_premium = group_or_team.biz_id != null && group_or_team.biz_id.length > 0 && (group_or_team.biz_type ?? "") != 'GROUP';
            break;
        }

        return is_premium;
    }

    /**
     * 숫자 가격 포맷 반환
     * @param number 
     * @returns 
     */
    number_price_format(number : number | string) {

        const num = Number(number);

        if( isNaN(num) ) {
            return '-';
        }

        return num.toLocaleString('ko-KR');
    }

    get_device_uid() {
        return sessionStorage.getItem("device_uid");
    }

    get_session_token() {
        return sessionStorage.getItem("session_token");
    }

}