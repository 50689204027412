
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { HoduPremiumPopupInfo } from '@/store/modules/ModalInfo';
import { hodu_local_storage } from '@/lib/HoduLocalStorage';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {

    },
})
export default class HoduPremiumPopup extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHoduPremiumPopupInfo ?: (params : HoduPremiumPopupInfo) => void;

    mounted() : void {

    }

    goHoduPremiumPayment() {
        let url : string = '//hodu.app/#/payment';
        let win : any = window.open(url, '_blank');
        win.focus();
    }

    /**
     * 모달 닫기
     */
    close() : void {
        hodu_local_storage.setItem("hodu_premium_popup_time", String(new Date().getTime()));
        this.doSetHoduPremiumPopupInfo?.({ show_modal : false });
    }

}
