
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const ApprovalInfo = namespace('ApprovalInfo');

import { approval_enum, approval_interface, approval_modal_interface } from '@/model/approval';

import JSZip from 'jszip';
import pdf from 'vue-pdf';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        pdf, ResizeObserver
    },
})
export default class ApprovalDetail extends Mixins(VueHoduCommon) {

    approval_enum : any = approval_enum;

    /**
     * 히스토리 + 현재 결재 상태 합치기
     */
    get computedApproverHistory() : any[] {
        let history : any[] = [];

        if( this.approval != null ) {
            
            if( this.approval['histapprover'] != null ) {
                history = history.concat(this.approval['histapprover']);
            }

            history = history.concat(this.approval.approver);
        }

        return history;
    }

    /**
     * 지출결의서 합계 금액
     */
    get computedTotalPrice() : number { 
        let price : number = 0;

        if( this.approval == null || this.approval.contents == null || this.approval.contents.transactions == null ) return 0;

        for( const transaction of this.approval.contents.transactions ) {
            if( transaction.price != null && String(transaction.price).length > 0 ) {
                const transaction_price = Number(transaction.price);
                if( isNaN(transaction_price) ) {
                    continue;
                }
                price += transaction_price;
            }
        }

        return price;
    }

    /**
     * 지출결의서 영수증 파일 개수
     */
    get computedTransactionFileLength() : number {
        return this.approval != null && this.approval.contents != null && this.approval.contents.transaction_files != null ? this.approval.contents.transaction_files.length : 0;
    }

    /**
     * 기안서 파일 개수
     */
     get computedFreeFileLength() : number {
        return this.approval != null && this.approval.contents != null && this.approval.contents.files != null ? this.approval.contents.files.length : 0;
    }
    
    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetApprovalMessageModalInfo ?: (params : approval_modal_interface.ApprovalMessageModalInfo) => void;
    @ModalInfo.Action doSetApprovalProcessModalInfo ?: (params : approval_modal_interface.ApprovalProcessModalInfo) => void;
    @ModalInfo.Action doSetApprovalReplyModalInfo ?: (params : approval_modal_interface.ApprovalReplyModalInfo) => void;

    /**
     * @ApprovalInfo.Action
     */
    @ApprovalInfo.Action doSetApproval ?: (params : approval_interface.t_approval) => void;

    approval_uid : string = "";
    approval : approval_interface.t_approval | null = null;

    // current_page : number[] = [];
    // page_count : number[] = [];

    pdf_infos : any[] = [];
    none_pdf_files : any[] = [];

    group_id : number = 0;

    templates : approval_interface.ApprovalTemplateBundle[] = [];

    beforeMount() {
        this.group_id = Number(this.$route.params.scope_id);
        this.approval_uid = this.$route.params.approval_id;
    }

    async mounted() : Promise<void> {
        $(".hover_menu").click(function(){
			$(this).parent().siblings('.look').toggleClass("open");
        });

        // this.showApprovalMessageModal();
        await this.getSelectedApprovalInfo();

        //영수증파일 팝업
        // $('.receiptAddBtn').click(function(){
        //     $('.filePopup').fadeIn(200);
        //     return false;
        // })
        // $('.filePopup button.filePopup_close').click(function(){
        //     $('.filePopup').fadeOut()
        // })
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();
        const reply_height = $('.CommentBox').outerHeight();
        const approver_title_height = $('.approver label.approval_info').outerHeight();
        const approver_button_height = $('.approver .approver_button_area').outerHeight();
        

        // @ts-ignore
        $('#approval_detail_left_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - ((reply_height && this.approval != null && this.approval.approval_state != 'TEMP') ? reply_height : 0),
        });

        // @ts-ignore
        $('#approver_status_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0)
                                           - (approver_title_height ? approver_title_height : 0)
                                           - (approver_button_height ? approver_button_height : 0),
        });
    }

    /**
     * 기안서 조회
     */
    async getSelectedApprovalInfo() : Promise<void> {

        this.pdf_infos.splice(0, this.pdf_infos.length);
        this.none_pdf_files.splice(0, this.none_pdf_files.length);
        
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/approval/${this.approval_uid}`, API_METHOD.GET);
            
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("기안서 조회 중 오류 발생");
            }

            const approval_info = response.data.data.approval_info;

            if( approval_info.contents != null && approval_info.contents.files != null ) {
                
                for( const file of approval_info.contents.files ) {
                    try {

                        if ( file.name.indexOf(".pdf") > -1 && (file.name.length - ".pdf".length) == file.name.indexOf(".pdf") ) {

                            // @ts-ignore
                            const loadingTask = pdf.createLoadingTask(`app_images/${file.url}`);
                            await loadingTask.promise.then(pdf_info => { 
                                this.pdf_infos.push({
                                    file : file,
                                    page : 1,
                                    pages : pdf_info._pdfInfo.numPages,
                                });
                            });

                        }
                        else {
                            this.none_pdf_files.push(file);
                        }
                        
                    } catch(e) {
                        this.hodu_error_process(e, false, false, true);
                    }
                }
            }

            this.approval = JSON.parse(JSON.stringify(approval_info));
            
            if( this.approval != null && !((this.approval.user_id == this.user_id) || (this.approval.contents.deputy_user_id == this.user_id)) && (this.approval.approval_state == approval_enum.APPROVAL_STATE.CANCEL || this.approval.approval_state == approval_enum.APPROVAL_STATE.TEMP) ) {
                this.movePrevPage();
                return;
            }

            // 양식 기안서면 양식이 현재 존재하는지 체크 (복사 가능여부 체크를 위해서임)
            if( this.approval != null && this.approval.approval_type == approval_enum.APPROVAL_TYPE.BASIC ) {
                try {
                    const template_response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class?include_templates=${true}`, API_METHOD.GET, null, false);
                    
                    console.log(template_response);

                    if( !template_response || !this.isHttpStatusSuccess(template_response.status) || !template_response.data || !template_response.data.data ) {
                        throw new Error("전자결재 양식 정보 조회 중 오류 발생");
                    }

                    const approval_templates = template_response.data.data.approval_templates;

                    this.templates.splice(0, this.templates.length);
                    this.templates = this.templates.concat(approval_templates.filter(template_class => template_class.template_state == '0' || template_class.template_state == '10'));

                } catch(e) {
                    throw e;
                }
            }

            await this.$nextTick();
            
            this.setScroll();

        } catch(e) {
            this.hodu_show_dialog('cancel', '기안서 조회 중 오류 발생', ['확인'], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }

        this.$nextTick(() => this.handleResize());
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : number | string | undefined) : string {

        if( day_count == null ) return '';
        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;
        
        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 휴가일자 텍스트 반환
     */
    getVacationText() : string {
        
        if( this.approval == null ) return "";

        let str = "";

        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.VACATION ) {
            
            if( this.approval.contents.vacation_start != null && (this.approval.contents.vacation_start as string).length > 0 &&
                this.approval.contents.vacation_end != null && (this.approval.contents.vacation_end as string).length > 0 ) {
                const start = String(this.approval.contents.vacation_start);
                const end   = String(this.approval.contents.vacation_end);

                if( start == null || end == null ) return "";

                const start_date = new Date([start.substring(0, 4), start.substring(4, 6), start.substring(6, 8)].join('-'));
                const end_date   = new Date([end.substring(0, 4), end.substring(4, 6), end.substring(6, 8)].join('-'));

                if( start == end ) {
                    
                    str = `${this.hodu_date_to_format_string(start_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(start_date)}`;
                    
                    if( this.approval.contents.vacation_type_daycount != null && this.approval.contents.vacation_start_time != null && this.approval.contents.vacation_end_time != null && 
                        // this.approval.contents.vacation_type_daycount > 0 && this.approval.contents.vacation_type_daycount < 1 ) {
                        this.approval.contents.vacation_type_daycount == 0.5 ) {
                            
                        let start_hour = Number(this.approval.contents.vacation_start_time.substring(0,2));
                        let start_min = Number(this.approval.contents.vacation_start_time.substring(2,4));
                        let start_am_pm = 'AM';

                        if( start_hour >= 12 ) start_am_pm = "PM";
                        if( start_hour >  12 ) start_hour -= 12;
                        if( start_hour == 0  ) start_hour = 12;

                        let end_hour = Number(this.approval.contents.vacation_end_time.substring(0,2));
                        let end_min = Number(this.approval.contents.vacation_end_time.substring(2,4));
                        let end_am_pm = 'AM';

                        if( end_hour >= 12 ) end_am_pm = "PM";
                        if( end_hour >  12 ) end_hour -= 12;
                        if( end_hour == 0  ) end_hour = 12;

                        str += ` ${this.amPmStringToLocaleAmPmString(`${`0${start_hour}`.slice(-2)}:${`0${start_min}`.slice(-2)} ${start_am_pm}`)} ~ ${this.amPmStringToLocaleAmPmString(`${`0${end_hour}`.slice(-2)}:${`0${end_min}`.slice(-2)} ${end_am_pm}`)}`;
                    }

                }
                else {
                    str = `${this.hodu_date_to_format_string(start_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(start_date)}`;
                    str += " ~ ";
                    str += `${this.hodu_date_to_format_string(end_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(end_date)}`;
                }
            }   

            // 5.4.2 부터는 vacation_selected로 만들어야함
            else if( this.approval.contents.vacation_selected != null ) {
                for( const selected_date of this.approval.contents.vacation_selected ) {
                    if( str.length > 0 ) str += ", ";
                    const date = new Date([selected_date.substring(0,4), selected_date.substring(4,6), selected_date.substring(6,8)].join('-'));
                    str += `${this.hodu_date_to_format_string(date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(date)}`;
                }

                if( this.approval.contents.vacation_selected.length == 1 && this.approval.contents.vacation_type_daycount != null &&
                    this.approval.contents.vacation_start_time != null && this.approval.contents.vacation_end_time != null ) {
                    // if( this.approval.contents.vacation_type_daycount > 0 && this.approval.contents.vacation_type_daycount < 1 ) {
                    if( this.approval.contents.vacation_type_daycount == 0.5 ) {
                        let start_hour = Number(this.approval.contents.vacation_start_time.substring(0,2));
                        let start_min = Number(this.approval.contents.vacation_start_time.substring(2,4));
                        let start_am_pm = 'AM';

                        if( start_hour >= 12 ) start_am_pm = "PM";
                        if( start_hour >  12 ) start_hour -= 12;
                        if( start_hour == 0  ) start_hour = 12;

                        let end_hour = Number(this.approval.contents.vacation_end_time.substring(0,2));
                        let end_min = Number(this.approval.contents.vacation_end_time.substring(2,4));
                        let end_am_pm = 'AM';

                        if( end_hour >= 12 ) end_am_pm = "PM";
                        if( end_hour >  12 ) end_hour -= 12;
                        if( end_hour == 0  ) end_hour = 12;

                        str += ` ${this.amPmStringToLocaleAmPmString(`${`0${start_hour}`.slice(-2)}:${`0${start_min}`.slice(-2)} ${start_am_pm}`)} ~ ${this.amPmStringToLocaleAmPmString(`${`0${end_hour}`.slice(-2)}:${`0${end_min}`.slice(-2)} ${end_am_pm}`)}`;
                    }
                }
            }

        }

        return str;
    }

    /**
     * 출장일자 텍스트 반환
     */
    getBusinessTripText() : string {

        if( this.approval == null ) return "";

        let str = "";

        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BUSINESSTRIP ) {
            
            // 5.4.2 부터는 vacation_selected로 만들어야함
            if( this.approval.contents.vacation_selected != null ) {
                for( const selected_date of this.approval.contents.vacation_selected ) {
                    if( str.length > 0 ) str += ", ";
                    const date = new Date([selected_date.substring(0,4), selected_date.substring(4,6), selected_date.substring(6,8)].join('-'));
                    str += `${this.hodu_date_to_format_string(date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(date)}`;
                }

                if( this.approval.contents.vacation_selected.length == 1 && this.approval.contents.vacation_type_daycount != null &&
                    this.approval.contents.vacation_start_time != null && this.approval.contents.vacation_end_time != null ) {
                    // if( this.approval.contents.vacation_type_daycount > 0 && this.approval.contents.vacation_type_daycount < 1 ) {
                    if( this.approval.contents.vacation_type_daycount == 0.5 ) {
                        let start_hour = Number(this.approval.contents.vacation_start_time.substring(0,2));
                        let start_min = Number(this.approval.contents.vacation_start_time.substring(2,4));
                        let start_am_pm = 'AM';

                        if( start_hour >= 12 ) start_am_pm = "PM";
                        if( start_hour >  12 ) start_hour -= 12;
                        if( start_hour == 0  ) start_hour = 12;

                        let end_hour = Number(this.approval.contents.vacation_end_time.substring(0,2));
                        let end_min = Number(this.approval.contents.vacation_end_time.substring(2,4));
                        let end_am_pm = 'AM';

                        if( end_hour >= 12 ) end_am_pm = "PM";
                        if( end_hour >  12 ) end_hour -= 12;
                        if( end_hour == 0  ) end_hour = 12;

                        str += ` ${this.amPmStringToLocaleAmPmString(`${`0${start_hour}`.slice(-2)}:${`0${start_min}`.slice(-2)} ${start_am_pm}`)} ~ ${this.amPmStringToLocaleAmPmString(`${`0${end_hour}`.slice(-2)}:${`0${end_min}`.slice(-2)} ${end_am_pm}`)}`;
                    }
                }
            }

        }

        return str;

    }

    /**
     * 근무일자 텍스트 반환
     */
    getHolidayWorkText() : string {

        if( this.approval == null ) return "";

        let str = "";

        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.HOLIDAY_WORK ) {
            
            // 5.4.2 부터는 vacation_selected로 만들어야함
            if( this.approval.contents.vacation_selected != null ) {
                for( const selected_date of this.approval.contents.vacation_selected ) {
                    if( str.length > 0 ) str += ", ";
                    const date = new Date([selected_date.substring(0,4), selected_date.substring(4,6), selected_date.substring(6,8)].join('-'));
                    str += `${this.hodu_date_to_format_string(date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(date)}`;
                }

                if( this.approval.contents.vacation_selected.length == 1 && this.approval.contents.vacation_type_daycount != null &&
                    this.approval.contents.vacation_start_time != null && this.approval.contents.vacation_end_time != null ) {
                    // if( this.approval.contents.vacation_type_daycount > 0 && this.approval.contents.vacation_type_daycount < 1 ) {
                    if( this.approval.contents.vacation_type_daycount == 0.5 ) {
                        let start_hour = Number(this.approval.contents.vacation_start_time.substring(0,2));
                        let start_min = Number(this.approval.contents.vacation_start_time.substring(2,4));
                        let start_am_pm = 'AM';

                        if( start_hour >= 12 ) start_am_pm = "PM";
                        if( start_hour >  12 ) start_hour -= 12;
                        if( start_hour == 0  ) start_hour = 12;

                        let end_hour = Number(this.approval.contents.vacation_end_time.substring(0,2));
                        let end_min = Number(this.approval.contents.vacation_end_time.substring(2,4));
                        let end_am_pm = 'AM';

                        if( end_hour >= 12 ) end_am_pm = "PM";
                        if( end_hour >  12 ) end_hour -= 12;
                        if( end_hour == 0  ) end_hour = 12;

                        str += ` ${this.amPmStringToLocaleAmPmString(`${`0${start_hour}`.slice(-2)}:${`0${start_min}`.slice(-2)} ${start_am_pm}`)} ~ ${this.amPmStringToLocaleAmPmString(`${`0${end_hour}`.slice(-2)}:${`0${end_min}`.slice(-2)} ${end_am_pm}`)}`;
                    }
                }
            }

        }

        return str;

    }

    /**
     * 수신 참조 이름 반환
     */
    getReceiveReference() : string {

        if( this.approval == null ) return '';

        try {
            if( this.approval.receive_reference == null || this.approval.receive_reference.length < 1 ) {
                return "없음";
            } 

            let str = "";

            for( const ref of this.approval.receive_reference ) {
                if( str.length > 0 ) str += ", ";
                str += ref.user_name;
            }

            return str;

        } catch(e) {
            return "없음";
        }
    }
    
    /**
     * 파일 전체 다운로드
     */
    async downloadAll() : Promise<void> {

        if( this.approval == null ) return;

        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BASIC || this.approval.approval_type == approval_enum.APPROVAL_TYPE.FREE ) {

            // 파일 1개라면
            if( this.approval.contents.files.length == 1 ) {
                const file = this.approval.contents.files[0];
                this.hodu_download(`app_images/${file.url}`, file.name);
                return;
            }

            // 파일 여러개는 zip 파일로 받는다

            await this.hodu_show_indicator();

            const zip = new JSZip();

            const file_promise : Promise<void>[] = [];

            for( const file of this.approval.contents.files ) {

                // 파일 다운로드 Promise
                const promise = this.hodu_download(`/app_images/${file.url}`, "", true)
                    .then((response) => {
                        console.log(response);

                        // 다운로드한 파일은 zip 파일 구성에 추가
                        zip.file(file.name, new Blob([response.data]));
                    })
                    .catch((e) => {
                        this.hodu_error_process(e, true, false);
                    });

                // 병렬처리용 Promise 배열에 넣기
                file_promise.push(promise);
            }

            Promise.all(file_promise)
                .then(async() => {

                    // zip 파일 압축
                    await zip.generateAsync({type:"blob"}).then((content) => {
                        
                        if( this.approval == null ) return;
                        
                        // 압축 완료시 나오는 blob으로 다운로드
                        try {
                            this.hodu_download_blob(content, `기안서_${this.approval.contents.title}_${new Date().getTime()}.zip`);
                        } catch(e) {
                            this.hodu_error_process(e, true, false);
                        }

                    });
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                }).finally(async() => {
                    await this.hodu_hide_indicator();
                });

        }

        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER && this.approval.contents.transaction_files != null ) {

            // 파일 1개라면
            if( this.approval.contents.transaction_files.length == 1 ) {
                const file = this.approval.contents.transaction_files[0];
                this.hodu_download(`app_images/${file.url}`, file.name);
                return;
            }

            // 파일 여러개는 zip 파일로 받는다

            await this.hodu_show_indicator();

            const zip = new JSZip();

            const file_promise : Promise<void>[] = [];

            for( const file of this.approval.contents.transaction_files ) {

                // 파일 다운로드 Promise
                const promise = this.hodu_download(`/app_images/${file.url}`, "", true)
                    .then((response) => {
                        console.log(response);

                        // 다운로드한 파일은 zip 파일 구성에 추가
                        zip.file(file.name, new Blob([response.data]));
                    })
                    .catch((e) => {
                        this.hodu_error_process(e, true, false);
                    });

                // 병렬처리용 Promise 배열에 넣기
                file_promise.push(promise);
            }

            Promise.all(file_promise)
                .then(async() => {

                    // zip 파일 압축
                    await zip.generateAsync({type:"blob"}).then((content) => {
                        
                        if( this.approval == null ) return;
                        
                        // 압축 완료시 나오는 blob으로 다운로드
                        try {
                            this.hodu_download_blob(content, `${this.approval.contents.title}_${new Date().getTime()}.zip`);
                        } catch(e) {
                            this.hodu_error_process(e, true, false);
                        }

                    });
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                }).finally(async() => {
                    await this.hodu_hide_indicator();
                });
        }
    }

    /**
     * 결재자 상태값 텍스트
     */
    getApproverStateText(state : approval_enum.APPROVAL_APPROVER_STATE) : string {

        switch(state) {
            case approval_enum.APPROVAL_APPROVER_STATE.REQUEST:
                return "요청";

            case approval_enum.APPROVAL_APPROVER_STATE.CANCEL:
                return "취소";

            case approval_enum.APPROVAL_APPROVER_STATE.WAIT:
                return "대기";

            case approval_enum.APPROVAL_APPROVER_STATE.ING:
                return "진행";

            case approval_enum.APPROVAL_APPROVER_STATE.CONFIRM:
                return "승인";

            case approval_enum.APPROVAL_APPROVER_STATE.REJECT:
                return "반려";
        }

        return "";
    }

    /**
     * 전자결재 메시지 모달 띄우기
     */
    // showApprovalMessageModal() : void {
    //     this.doSetApprovalMessageModalInfo?.({ show_modal : true });
    // }

    /**
     *전자결재 처리 모달 띄우기
    */
    async showApprovalProcessModal() : Promise<void> {

        if( this.approval == null ) return;

        await this.getSelectedApprovalInfo();

        const my_infos = this.approval.approver.filter(item => item.user_id == this.user_id && item.state == approval_enum.APPROVAL_APPROVER_STATE.ING);

        if( my_infos == null || my_infos.length < 1 ) {
            this.hodu_show_dialog("cancel", "결재처리를 완료 할 수 없습니다\n이미 처리됐거나 대상이 아닙니다", ["확인"]);
            return;
        }

        this.doSetApprovalProcessModalInfo?.({ 
            show_modal : true,
            callback : async(state : approval_enum.APPROVAL_APPROVER_STATE, reject_message : string) => {
                try {

                    if( this.approval == null ) return;

                    await this.getSelectedApprovalInfo();

                    const my_infos = this.approval.approver.filter(item => item.user_id == this.user_id && item.state == approval_enum.APPROVAL_APPROVER_STATE.ING);
                    
                    if( my_infos == null || my_infos.length < 1 ) {
                        this.hodu_show_dialog("cancel", "결재처리를 완료 할 수 없습니다\n이미 처리됐거나 대상이 아닙니다", ["확인"]);
                        return;
                    }

                    const my_info = my_infos[0];
                    my_info.state = state;
                    my_info.date = new Date();

                    const target_index = this.approval.approver.indexOf(my_info);
                    
                    // 두번 눌리는듯한 버그가 있어서 버그 방지
                    if( (this.approval.approval_cur + 1) > this.approval.approval_total ) {
                        return;
                    }

                    this.approval.approval_cur = this.approval.approval_cur + 1;
                    
                    // 반려처리라면 문서 자체도 반려 상태로 바꿈
                    if( state == approval_enum.APPROVAL_APPROVER_STATE.REJECT ) {
                        this.approval.approval_state = approval_enum.APPROVAL_STATE.REJECT;
                        my_info.comment = reject_message;
                    }

                    // 마지막 승인이라면 기안서 자체를 완료 처리
                    else if( target_index == (this.approval.approver.length - 1) && state == approval_enum.APPROVAL_APPROVER_STATE.CONFIRM ) {
                        this.approval.approval_state = approval_enum.APPROVAL_STATE.COMPLETE;
                    }

                    else if( target_index != (this.approval.approver.length - 1) && state == approval_enum.APPROVAL_APPROVER_STATE.CONFIRM ) {
                        this.approval.approver[target_index + 1].state = approval_enum.APPROVAL_APPROVER_STATE.ING;
                    }

                    this.approval.approver.splice(target_index, 1, my_info);

                    // 수정 API
                    const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/approval/${this.approval_uid}`, API_METHOD.PUT, this.approval);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("기안서 수정 중 오류 발생");
                    }

                } catch(e) {
                    this.hodu_show_dialog('cancel', '기안서 결재 처리 중 오류 발생', ['확인']);
                    this.hodu_error_process(e, false, false, true);
                } finally {
                    await this.getSelectedApprovalInfo();
                } 

            } 
        });
    }

    isCancelable() : boolean {
        if( this.approval == null ) return false;
        return (this.user_id == this.approval.user_id || this.user_id == this.approval.contents.deputy_user_id) && (this.approval.approval_state == 'ING' || this.approval.approval_state == 'TEMP') && this.approval.approver[1].state == 'ING';
    }

    /**
     * 본인의 결재 차례라면
     */
    isMyTurn() : boolean {
        if( this.approval == null ) return false;
        const my_infos = this.approval.approver.filter(item => item.user_id == this.user_id && item.state == approval_enum.APPROVAL_APPROVER_STATE.ING);
        if( my_infos == null || my_infos.length < 1 ) return false;
        return true;
    }

    /**
     * 임시 저장한 기안서 삭제
     */
    approvalDelete() : void {
        this.hodu_show_dialog('cancel', "정말로 삭제 하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {
                try {
                    const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/approval/${this.approval_uid}`, API_METHOD.DELETE);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("기안서 삭제 중 오류 발생");
                    }

                    this.movePrevPage();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "기안서 삭제 중 오류 발생", ['확인'], [() => this.getSelectedApprovalInfo()]);
                }
            }
        ])
    }

    /**
     * 임시 저장한 기안서 / 반려된 기안서 수정
     */
    approvalModify() : void {
        if( this.approval == null ) return;
        this.hodu_router_push(`/${this.scope}/${this.group_id}/approval/create/${this.approval.approval_uid}`);
    }

    /**
     * 복사
     */
    async approvalCopy() {
        if( this.approval == null ) return;

        const copy_approval = JSON.parse(JSON.stringify(this.approval));

        console.log(copy_approval);

        copy_approval.is_copy = true;
        copy_approval.approver = [];
        copy_approval.receive_reference = [];

        this.doSetApproval?.(copy_approval);
        this.hodu_router_push(`/${this.scope}/${this.group_id}/approval/create`);
    }

    /**
     * 기안서 취소하기
     */
    async cancel() : Promise<void> {

        this.hodu_show_dialog("alert", "기안서를 취소 처리 하시겠습니까?", ["아니오", "예"], [() => {}, async() => {
            try {

                if( this.approval == null ) return;

                await this.getSelectedApprovalInfo();

                if( (this.approval.approval_state != approval_enum.APPROVAL_STATE.ING && this.approval.approval_state != approval_enum.APPROVAL_STATE.TEMP) || this.approval.approver[1].state != approval_enum.APPROVAL_APPROVER_STATE.ING ) {
                    this.hodu_show_dialog("cancel", "기안서 취소를 할 수 없습니다\n기안서가 이미 처리 됐습니다", ["확인"]);
                    return;
                }

                this.approval.approval_state = approval_enum.APPROVAL_STATE.CANCEL;
                this.approval.approver[0].state = approval_enum.APPROVAL_APPROVER_STATE.CANCEL;
                this.approval.approver[1].state = approval_enum.APPROVAL_APPROVER_STATE.WAIT;

                // 수정 API
                const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/approval/${this.approval_uid}`, API_METHOD.PUT, this.approval);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("기안서 취소 중 오류 발생");
                }

            } catch(e) {
                this.hodu_show_dialog('cancel', '기안서 취소 처리 중 오류 발생', ['확인']);
                this.hodu_error_process(e, false, false, true);
            } finally {
                await this.getSelectedApprovalInfo();
            }
        }]);
        
    }

    /**
     * 작성시 연차수 표시여부
     */
    isDenoteAnnualCount() : boolean {
        const feature = this.get_group_feature(this.group_id);
        return feature != null ? feature['approval']['is_denote_annual_count'] : false;
    }

    /**
     * 휴가원, 출장 신청서 프린트
     */
    printApproval() : void {

        let query = ``;
        query += `group_id=${this.group_id}`;
        query += `&approval_uid=${this.approval_uid}`;

        const timezone = -(new Date().getTimezoneOffset());
        query += `&timezone=${timezone ? timezone : 540}`;

        if( this.approval != null && this.approval.approval_type == approval_enum.APPROVAL_TYPE.BASIC ) {
            for( const file of this.approval.contents.files ) {
                query += `&pdf_path=${encodeURIComponent("app_images/")}${encodeURIComponent(file.url)}`;
            }
        }

        if( this.approval != null && this.approval.contents != null && this.approval.contents.transactions != null && this.approval.approval_type == approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER ) {
            
            let bottom_page = 0;

            // line_count 계산
            if( this.approval.line_count != null && this.approval.top_page_count != null ) {

                // 총 페이지가 1페이지인 경우
                if( this.approval.top_page_count == 1 ) {
                    bottom_page = Math.ceil((this.approval.contents.transactions.length - 5) / 12);
                }
                else {

                    // 마지막 페이지가 지출내역만 따로 있는 경우
                    if( (this.approval.line_count - 30) % 50 == 0 || (this.approval.line_count - 30) % 50 > 30 ) {
                        bottom_page = Math.ceil((this.approval.contents.transactions.length - 12) / 12);
                        query += `&is_long_transactions=true`;
                    }
                    // 마지막 페이지가 내용 + 지출내역인 경우
                    else {
                        bottom_page = Math.ceil((this.approval.contents.transactions.length - 5) / 12);
                    }
                    
                }

            }
            else {
                bottom_page = Math.ceil((this.approval.contents.transactions.length - 5) / 12);
            }
            
            query += `&bottom_page=${bottom_page}`;
        }

        if( this.approval != null && this.approval.is_long_comment != null ) {
            query += `&is_long_comment=${this.approval.is_long_comment}`;
        }

        if( this.approval != null && this.approval.top_page_count != null ) {
            query += `&top_page_count=${this.approval.top_page_count}`;
        }
        
        window.open(`/approval_pdf.html?${query}`, "work_pdf", "toolbar=0, scrollbars=0, resizable=0, width=" + 1024 + ", height=" + 768);

    }

    fadeInTransactionFile() {
        $('.filePopup').fadeIn(200);
    }

    fadeOutTransactionFile() {
        $('.filePopup').fadeOut();
    }

    isCopyable() {

        if( this.approval == null ) {
            return false;
        }

        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BASIC ) {

            if( this.templates == null || this.templates.length < 1 || this.approval.contents.files == null || this.approval.contents.files.length < 1 ) {
                return false;
            }

            for( const file of this.approval.contents.files ) {
                let is_find_template : boolean = false;

                for( const template of this.templates ) {
                    
                    if( template.files == null || template.files.length < 1 ) {
                        continue;
                    }

                    for( const template_file of template.files ) {

                        // 저장이 반대로 된지 오래되서 반대로 비교해야함..
                        if( template_file.template_class_id == file.template_id && template_file.template_id == file.template_class_id ) {
                            is_find_template = true;
                        }

                        if( is_find_template == true ) {
                            break;
                        }
                    }

                    if( is_find_template == true ) {
                        break;
                    }

                }

                // 한번이라도 양식을 찾지 못한게 있다면 그대로 복사 불가 
                if( is_find_template == false ) {
                    return false;
                }
            }

            return true;
        }
        
        return true;
    }

    /**
     * 전자결재 댓글
     */
    showApprovalReply() {
        this.doSetApprovalReplyModalInfo?.({
            show_modal : true,
            group_id : this.group_id,
            approval_uid : this.approval_uid,
            callback : this.getSelectedApprovalInfo
        })
    }

    getProfileImage(approver) {
        if( approver.user_pic != null ) {
            return `/app_images/${approver.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((approver.user_id ? approver.user_id : 0) / 10000)}/${approver.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        $(event.target).parent().find('span.prof').css('background-image', require('@/assets/images/contents/ic_approval_user.png'));
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#approval_detail_left_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#approver_status_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
