
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, GROUP_TYPE, OWNER_TYPE } from '@/mixin/VueHoduCommon';

/**
 * 컴포넌트
 */
import Top             from '@/components/Top.vue';
import Left            from '@/components/left/Left.vue';
import RightController from '@/components/right/RightController.vue';
import RightArea       from '@/components/right/RightArea.vue';

/**
 * 모달
 */
import EventShortCreate           from '@/components/event/modal/EventShortCreate.vue';
import EventRepeatModify          from '@/components/event/modal/EventRepeatModify.vue';
import EventDaumMap               from '@/components/event/modal/EventDaumMap.vue';
import EventImagePreview          from '@/components/event/modal/EventImagePreview.vue';
import EventShare                 from '@/components/event/modal/EventShare.vue';
import EventShareStatus           from '@/components/event/modal/EventShareStatus.vue';
import EventEmailShareModal       from '@/components/event/modal/EventEmailShareModal.vue';
import EventVoteCreate            from '@/components/event/modal/EventVoteCreate.vue';
import EventVoteResult            from '@/components/event/modal/EventVoteResult.vue';
import EventReply                 from '@/components/event/modal/EventReply.vue';
import EventWorkAssignment        from '@/components/event/modal/EventWorkAssignment.vue';
import EventWorkProcess           from '@/components/event/modal/EventWorkProcess.vue';
import WorkStatus                 from '@/components/event/modal/WorkStatus.vue'
import WorkDefaultStatus          from '@/components/event/modal/WorkDefaultStatus.vue'
import GroupListModal             from '@/components/GroupListConfirmModal.vue';
import GroupToBizModal            from '@/components/GroupToBizModal.vue';
import GroupTeamOtherOutModal     from '@/components/GroupTeamOtherOutModal.vue';
import GroupNoticeRead            from '@/components/GroupNoticeRead.vue';
import GroupBoardRead             from '@/components/GroupBoardRead.vue';
import MessageSendStatus          from '@/components/MessageSendStatus.vue';
import GroupAddFileBox            from '@/components/GroupAddFileBox.vue';
import EventImportModal           from '@/components/EventImportModal.vue';      
import GroupNoticeReplyModal      from '@/components/GroupNoticeReplyModal.vue';      
import GroupBoardReplyModal       from '@/components/GroupBoardReplyModal.vue';      
import EventHistoryModal          from '@/components/event/modal/EventHistoryModal.vue';      
import EventExportModal           from '@/components/event/modal/EventExportModal.vue';      
import EventReadModal             from '@/components/event/modal/EventReadModal.vue';      
import GroupMemberImportModal     from '@/components/GroupMemberImportModal.vue';      
import EquipmentImportModal       from '@/components/hodu_h/modal/EquipmentImportModal.vue';
import GroupSurveyTemplateModal   from '@/components/GroupSurveyTemplateModal.vue';            
import GroupSurveyChoiceListModal from '@/components/GroupSurveyChoiceListModal.vue';            
import GroupFileboxMoveModal      from '@/components/GroupFileboxMoveModal.vue';            

/**
 * HODU D 모달
 */
import AppointmentCreateModal   from '@/components/hodu_d/modal/AppointmentCreateModal.vue';
import DoctorTimeSettingModal   from '@/components/hodu_d/modal/DoctorTimeSettingModal.vue';
import DoctorOffSettingModal    from '@/components/hodu_d/modal/DoctorOffSettingModal.vue';
import PatientAddModal          from '@/components/hodu_d/modal/PatientAddModal.vue';
import PatientSendMessageModal  from '@/components/hodu_d/modal/PatientSendMessageModal.vue';
import DepartmentSettingModal   from '@/components/hodu_d/modal/DepartmentSettingModal.vue';
import HospitalOffSettingModal  from '@/components/hodu_d/modal/HospitalOffSettingModal.vue';
import DayOffSettingModal       from '@/components/hodu_d/modal/DayOffSettingModal.vue';
import AppointmentApplyModal    from '@/components/hodu_d/modal/AppointmentApplyModal.vue';
import AppointmentListModal     from '@/components/hodu_d/modal/AppointmentListModal.vue';
import HospitalTimeSettingModal from '@/components/hodu_d/modal/HospitalTimeSettingModal.vue';
import LoginHospitalSelectModal from '@/components/hodu_d/modal/LoginHospitalSelectModal.vue';
import GroupAddFileBoxNew       from '@/components/GroupAddFileBoxNew.vue';

/**
 * HODU H 모달
 */
import HomeBuildingCreateModal         from '@/components/hodu_h/modal/HomeBuildingCreateModal.vue';
import HomeContactCreateModal          from '@/components/hodu_h/modal/HomeContactCreateModal.vue';
import HomeResidentCarRequestModal     from '@/components/hodu_h/modal/HomeResidentCarRequestModal.vue';
import HomeResidentInfoModal           from '@/components/hodu_h/modal/HomeResidentInfoModal.vue';
import HomeVisitantCreateModal         from '@/components/hodu_h/modal/HomeVisitantCreateModal.vue';
import HomeVisitantInfoModal           from '@/components/hodu_h/modal/HomeVisitantInfoModal.vue';
import EquipmentManagementDetailModal  from '@/components/hodu_h/modal/EquipmentManagementDetailModal.vue';
import SupplyManagementDetailModal     from '@/components/hodu_h/modal/SupplyManagementDetailModal.vue';
import HomeVisitantAmanoInfoModal      from '@/components/hodu_h/modal/HomeVisitantAmanoInfoModal.vue';
import HomeResidentJoinModal           from '@/components/hodu_h/modal/HomeResidentJoinModal.vue';

/**
 * 채팅방 모달
 */
import ChattingRoomCreateModal from '@/components/chatting/modal/ChattingRoomCreateModal.vue';
import ChattingRoomInfoModal   from '@/components/chatting/modal/ChattingRoomInfoModal.vue';

/**
 * 전자 결재
 */
import ApprovalMessageModal            from '@/components/approval/modal/ApprovalMessageModal.vue';
import ApproverSelectModal             from '@/components/approval/modal/ApproverSelectModal.vue';
import ApprovalProcessModal            from '@/components/approval/modal/ApprovalProcessModal.vue';
import ApprovalTemplateExceptFileModal from '@/components/approval/modal/ApprovalTemplateExceptFileModal.vue';
import ApprovalFileModal               from '@/components/approval/modal/ApprovalFileModal.vue';
import ApprovalFormModifyModal         from '@/components/approval/modal/ApprovalFormModifyModal.vue';
import ApprovalApproverFavoriteModal   from '@/components/approval/modal/ApprovalApproverFavoriteModal.vue';
import ApprovalReplyModal              from '@/components/approval/modal/ApprovalReplyModal.vue';

/**
 * 조직도 (근태관리, 전자결재 사용)
 */
import OrganizationModal                        from '@/components/organization/modal/OrganizationModal.vue';
import OrganizationModal2                       from '@/components/organization/modal/OrganizationModal2.vue';
import OrganizationVacationTypeModal            from '@/components/organization/modal/OrganizationVacationTypeModal.vue';
import OrganizationWorkTypeDetailModal          from '@/components/organization/modal/OrganizationWorkTypeDetailModal.vue';
import OrganizationWorkTypeAddModal             from '@/components/organization/modal/OrganizationWorkTypeAddModal.vue';
import OrganizationOffModal                     from '@/components/organization/modal/OrganizationOffModal.vue';
import OrganizationOffAddModal                  from '@/components/organization/modal/OrganizationOffAddModal.vue';
import OrganizationVacationAddModal             from '@/components/organization/modal/OrganizationVacationAddModal.vue';
import OrganizationVacationDetailModal          from '@/components/organization/modal/OrganizationVacationDetailModal.vue';
import OrganizationMyVacationDetailModal        from '@/components/organization/modal/OrganizationMyVacationDetailModal.vue';
import OrganizationSignModal                    from '@/components/organization/modal/OrganizationSignModal.vue';
import OrganizationEmpPersonalRecordDetailModal from '@/components/organization/modal/OrganizationEmpPersonalRecordDetailModal.vue';
import OrganizationEmpPersonalRecordImportModal from '@/components/organization/modal/OrganizationEmpPersonalRecordImportModal.vue';
import OrganizationEmpPersonalRecordPrintModal  from '@/components/organization/modal/OrganizationEmpPersonalRecordPrintModal.vue';
import OrganizationDefaultEmpOffModal           from '@/components/organization/modal/OrganizationDefaultEmpOffModal.vue';

/**
 * 근태관리
 */
import AttendanceRequestModal from '@/components/attendance/modal/AttendanceRequestModal.vue';

/**
 * 공통 모달
 */
import ImageCropModal                      from '@/components/ImageCropModal.vue';
import MessageModal                        from '@/components/MessageModal.vue'
import CommonColorPickerModal              from '@/components/common/modal/CommonColorPickerModal.vue';
import CommonInputModal                    from '@/components/common/modal/CommonInputModal.vue';
import CommonDatepickerModal               from '@/components/common/modal/CommonDatepickerModal.vue';
import CommonSelectFriendAndGroupTeamModal from '@/components/common/modal/CommonSelectFriendAndGroupTeamModal.vue';
import CommonPremiumExpireModal            from '@/components/common/modal/CommonPremiumExpireModal.vue' 
import HoduPremiumPopup                    from '@/components/common/modal/HoduPremiumPopup.vue' 

/**
 * 팝업
 */
import Popup from '@/components/ui/Popup.vue';

/**
 * .section_ce 바로 아래 들어가는 모달
 */
import WorkPdfModal from '@/components/work/modal/WorkPdfModal.vue';

import { ResizeObserver } from 'vue-resize';

import { namespace } from 'vuex-class';
const MenuInfo     = namespace('MenuInfo');
const CalendarInfo = namespace('CalendarInfo');
const ModalInfo    = namespace('ModalInfo');
const PopupInfo    = namespace('PopupInfo');

import { 
    EventShareStatusInfo, EventEmailShareModalInfo, ImageCropModalInfo, 
    GroupModalInfo, GroupTeamOtherOutModalInfo, EventWorkAssignmentInfo, 
    GroupNoticeReadInfo, GroupBoardReadInfo, EventImportModalInfo, GroupNoticeReplyModalInfo, GroupBoardReplyModalInfo, GroupAddFileModalNewInfo, 
    CommonColorPickerModalInfo, LoginHospitalModalInfo, CommonInputModalInfo, CommonDatepickerModalInfo, 
    EventHistoryModalInfo, CommonSelectFriendAndGroupTeamModalInfo, CommonPremiumExpireModalInfo, HoduPremiumPopupInfo, GroupMemberImportModalInfo, 
    EventExportModalInfo, EquipmentImportModalInfo, EventReadModalInfo, GroupSurveyTemplateModalInfo, GroupSurveyChoiceListModalInfo, GroupFileboxMoveModalInfo,
} from '@/store/modules/ModalInfo';

import { hodu_doc_modal_info } from '@/model/hodudoc';
import { hodu_home_modal_info } from '@/model/hoduhome';
import { chat_modal_interface } from '@/model/chat';
import { approval_modal_interface } from '@/model/approval';
import { organization_modal_interface } from './model/organization';
import { attendance_modal_interface } from './model/attendance';

@Component({
    components: {
        Top, Left, RightController, RightArea,
        
        /**
         * 모달
         */
        EventShortCreate, EventRepeatModify, EventDaumMap, EventImagePreview, EventShare, 
        EventShareStatus, EventEmailShareModal, EventVoteCreate, EventVoteResult, 
        EventReply, EventWorkProcess, EventWorkAssignment, WorkStatus, WorkDefaultStatus,
        GroupListModal, GroupToBizModal, GroupTeamOtherOutModal, GroupNoticeRead, GroupBoardRead, GroupAddFileBox, 
        GroupAddFileBoxNew, MessageSendStatus, EventImportModal, GroupNoticeReplyModal, GroupBoardReplyModal, EventHistoryModal,
        EventExportModal, EventReadModal, GroupMemberImportModal, EquipmentImportModal, GroupSurveyTemplateModal,
        GroupSurveyChoiceListModal, GroupFileboxMoveModal,

        /**
         * HODU D 모달
         */
        AppointmentCreateModal, DoctorTimeSettingModal, PatientAddModal, 
        PatientSendMessageModal, DoctorOffSettingModal, DepartmentSettingModal, 
        HospitalOffSettingModal, AppointmentApplyModal, AppointmentListModal,
        HospitalTimeSettingModal, DayOffSettingModal, LoginHospitalSelectModal,

        /**
         * HODU H 모달
         */
        HomeBuildingCreateModal, HomeContactCreateModal, HomeResidentCarRequestModal, 
        HomeResidentInfoModal, HomeVisitantCreateModal, HomeVisitantInfoModal, 
        EquipmentManagementDetailModal, SupplyManagementDetailModal, HomeVisitantAmanoInfoModal,
        HomeResidentJoinModal,  

        /**
         * 채팅방 모달
         */
        ChattingRoomCreateModal, ChattingRoomInfoModal,

        /**
         * 전자결재 모달
         */
        ApprovalMessageModal, ApproverSelectModal, ApprovalProcessModal, 
        ApprovalTemplateExceptFileModal, ApprovalFileModal, ApprovalFormModifyModal,
        ApprovalApproverFavoriteModal, ApprovalReplyModal,

        /**
         * 조직도 (근태관리, 전자결재 사용)
         */
        OrganizationModal, OrganizationModal2, OrganizationVacationTypeModal, 
        OrganizationWorkTypeDetailModal, OrganizationWorkTypeAddModal, OrganizationOffModal,
        OrganizationOffAddModal, OrganizationVacationAddModal, OrganizationVacationDetailModal, 
        OrganizationMyVacationDetailModal, OrganizationSignModal, OrganizationEmpPersonalRecordDetailModal,
        OrganizationEmpPersonalRecordImportModal, OrganizationEmpPersonalRecordPrintModal, OrganizationDefaultEmpOffModal,

        /**
         * 근태관리
         */
        AttendanceRequestModal,

        /**
         * 공통 모달
         */
        ImageCropModal, MessageModal, CommonColorPickerModal, CommonInputModal, CommonDatepickerModal,
        CommonSelectFriendAndGroupTeamModal, CommonPremiumExpireModal, HoduPremiumPopup,

        /**
         * 팝업
         */
        Popup,

        /**
         * .section_ce 바로 아래 들어가는 모달
         */
        WorkPdfModal,

        /**
         * 리사이즈 옵저버
         */
        ResizeObserver,
    },
})
export default class App extends Mixins(VueHoduCommon) {
    
    /**
     * MenuInfo.State
     */
    @MenuInfo.State user_profile_open           !: boolean;
    @MenuInfo.State notification_list_menu_open !: boolean;
    @MenuInfo.State preferences_menu_open       !: boolean;
    @MenuInfo.State todo_menu_open              !: boolean;
    @MenuInfo.State todo_create_or_update       !: boolean;
    @MenuInfo.State etc_menu_open               !: boolean;
    @MenuInfo.State filter_menu_open            !: boolean;
      
    /**
     * MenuInfo.Action
     */
    @MenuInfo.Action doSetUserProfileOpen          ?: any;
    @MenuInfo.Action doSetNotificationListMenuOpen ?: any;
    @MenuInfo.Action doSetPreferencesMenuOpen      ?: any;
    @MenuInfo.Action doSetTodoMenuOpen             ?: any;
    @MenuInfo.Action doTodoCreateOrUpdate          ?: any;
    @MenuInfo.Action doSetEtcMenuOpen              ?: any;
    @MenuInfo.Action doSetFilterMenuOpen           ?: (params : boolean) => void;

    /**
     * ModalInfo.State
     */
    @ModalInfo.State show_event_short_create             !: boolean;
    @ModalInfo.State show_event_repeat_modify            !: boolean;
    @ModalInfo.State show_event_daum_map                 !: boolean;
    @ModalInfo.State show_event_image_preview            !: boolean;
    @ModalInfo.State show_event_share                    !: boolean;
    @ModalInfo.State event_share_status_info             !: EventShareStatusInfo;
    @ModalInfo.State event_email_share_modal_info        !: EventEmailShareModalInfo;
    @ModalInfo.State show_event_attend_result            !: boolean;
    @ModalInfo.State show_event_vote_create              !: boolean;
    @ModalInfo.State show_event_vote_result              !: boolean;
    @ModalInfo.State show_event_reply                    !: boolean;
    @ModalInfo.State event_work_assignment_info          !: EventWorkAssignmentInfo;
    @ModalInfo.State show_event_work_process             !: boolean;
    @ModalInfo.State show_work_status                    !: boolean;
    @ModalInfo.State show_work_default_status            !: boolean;
    @ModalInfo.State show_work_pdf_modal                 !: boolean;
    @ModalInfo.State group_modal_info                    !: GroupModalInfo;
    @ModalInfo.State show_group_to_biz_modal             !: boolean;
    @ModalInfo.State group_team_other_out_info           !: GroupTeamOtherOutModalInfo;
    @ModalInfo.State group_notice_read_info              !: GroupNoticeReadInfo;
    @ModalInfo.State group_board_read_info               !: GroupBoardReadInfo
    @ModalInfo.State show_group_notice_status            !: boolean;
    @ModalInfo.State show_message_send_status            !: boolean;
    @ModalInfo.State show_group_add_file_box             !: boolean;
    @ModalInfo.State group_add_file_box_new_info         !: GroupAddFileModalNewInfo;
    @ModalInfo.State event_import_modal_info             !: EventImportModalInfo;
    @ModalInfo.State group_notice_reply_modal_info       !: GroupNoticeReplyModalInfo;
    @ModalInfo.State group_board_reply_modal_info        !: GroupBoardReplyModalInfo;
    @ModalInfo.State event_history_modal_info            !: EventHistoryModalInfo; 
    @ModalInfo.State event_export_modal_info             !: EventExportModalInfo;
    @ModalInfo.State event_read_modal_info               !: EventReadModalInfo;
    @ModalInfo.State group_member_import_modal_info      !: GroupMemberImportModalInfo; 
    @ModalInfo.State equipment_import_modal_info         !: EquipmentImportModalInfo;
    @ModalInfo.State group_survey_template_modal_info    !: GroupSurveyTemplateModalInfo;
    @ModalInfo.State group_survey_choice_list_modal_info !: GroupSurveyChoiceListModalInfo;
    @ModalInfo.State group_filebox_move_modal_info       !: GroupFileboxMoveModalInfo;

    // HODU D       
    @ModalInfo.State appointment_create_modal_info    !: hodu_doc_modal_info.AppointmentCreateModalInfo;
    @ModalInfo.State doctor_time_setting_modal_info   !: hodu_doc_modal_info.DoctorTimeSettingModalInfo;
    @ModalInfo.State doctor_off_setting_modal_info    !: hodu_doc_modal_info.DoctorOffSettingModalInfo;
    @ModalInfo.State day_off_setting_modal_info       !: hodu_doc_modal_info.DayOffSettingModalInfo;
    @ModalInfo.State show_patient_add_modal           !: boolean;
    @ModalInfo.State patient_send_message_modal_info  !: hodu_doc_modal_info.PatientSendMessageModalInfo;
    @ModalInfo.State department_setting_modal_info    !: hodu_doc_modal_info.DepartmentSettingModalInfo;
    @ModalInfo.State show_hospital_off_setting_modal  !: boolean;
    @ModalInfo.State appointment_apply_modal_info     !: hodu_doc_modal_info.AppointmentApplyModalInfo;
    @ModalInfo.State appointment_list_modal_info      !: hodu_doc_modal_info.AppointmentListModalInfo;
    @ModalInfo.State hospital_time_setting_modal_info !: hodu_doc_modal_info.HospitalTimeSettingModalInfo;
    @ModalInfo.State login_hospital_select_modal_info !: LoginHospitalModalInfo;

    // HODU H
    @ModalInfo.State home_building_create_modal_info        !: hodu_home_modal_info.HomeBuildingCreateModalInfo;
    @ModalInfo.State home_contact_create_modal_info         !: hodu_home_modal_info.HomeContactCreateModalInfo;
    @ModalInfo.State home_resident_car_request_modal_info   !: hodu_home_modal_info.HomeResidentCarRequestModalInfo;
    @ModalInfo.State home_resident_info_modal_info          !: hodu_home_modal_info.HomeResidentInfoModalInfo;
    @ModalInfo.State home_visitant_create_modal_info        !: hodu_home_modal_info.HomeVisitantCreateModalInfo;
    @ModalInfo.State home_visitant_info_modal_info          !: hodu_home_modal_info.HomeVisitantInfoModalInfo;
    @ModalInfo.State equipment_management_detail_modal_info !: hodu_home_modal_info.EquipmentManagementDetailModalInfo;
    @ModalInfo.State supply_management_detail_modal_info    !: hodu_home_modal_info.SupplyManagementDetailModalInfo;
    @ModalInfo.State home_visitant_amano_info_modal_info    !: hodu_home_modal_info.HomeVisitantAmanoInfoModalInfo;
    @ModalInfo.State home_resident_join_modal_info          !: hodu_home_modal_info.HomeResidentJoinModalInfo;

    // 채팅방
    @ModalInfo.State chatting_room_create_modal_info !: chat_modal_interface.ChattingRoomCreateModalInfo;
    @ModalInfo.State chatting_room_info_modal_info   !: chat_modal_interface.ChattingRoomInfoModalInfo;

    // 전자결재
    @ModalInfo.State approval_message_modal_info              !: approval_modal_interface.ApprovalMessageModalInfo;
    @ModalInfo.State approver_select_modal_info               !: approval_modal_interface.ApproverSelectModalInfo;
    @ModalInfo.State approval_process_modal_info              !: approval_modal_interface.ApprovalProcessModalInfo;
    @ModalInfo.State approval_template_except_file_modal_info !: approval_modal_interface.ApprovalTemplateExceptFileModalInfo;
    @ModalInfo.State approval_file_modal_info                 !: approval_modal_interface.ApprovalFileModalInfo;
    @ModalInfo.State approval_form_modify_modal_info          !: approval_modal_interface.ApprovalFormModifyModalInfo;
    @ModalInfo.State approval_approver_favorite_modal_info    !: approval_modal_interface.ApprovalApproverFavoriteModalInfo;
    @ModalInfo.State approval_reply_modal_info                !: approval_modal_interface.ApprovalReplyModalInfo;

    // 조직도 (근태관리, 전자결재 사용)
    @ModalInfo.State organization_modal_info                            !: organization_modal_interface.OrganizationModalInfo;
    @ModalInfo.State organization_modal2_info                           !: organization_modal_interface.OrganizationModal2Info;
    @ModalInfo.State organization_vacation_type_modal_info              !: organization_modal_interface.OrganizationVacationTypeModalInfo;
    @ModalInfo.State organization_work_type_detail_modal_info           !: organization_modal_interface.OrganizationWorkTypeDetailModalInfo;
    @ModalInfo.State organization_work_type_add_modal_info              !: organization_modal_interface.OrganizationWorkTypeAddModalInfo;
    @ModalInfo.State organization_off_modal_info                        !: organization_modal_interface.OrganizationOffModalInfo;
    @ModalInfo.State organization_off_add_modal_info                    !: organization_modal_interface.OrganizationOffAddModalInfo;
    @ModalInfo.State organization_vacation_add_modal_info               !: organization_modal_interface.OrganizationVacationAddModlInfo;
    @ModalInfo.State organization_vacation_detail_modal_info            !: organization_modal_interface.OrganizationVacationDetailModalInfo;
    @ModalInfo.State organization_my_vacation_detail_modal_info         !: organization_modal_interface.OrganizationMyVacationDetailModalInfo;
    @ModalInfo.State organization_sign_modal_info                       !: organization_modal_interface.OrganizationSignModalInfo;
    @ModalInfo.State organization_emp_personal_record_detail_modal_info !: organization_modal_interface.OrganizationEmpPersonalRecordDetailModalInfo;
    @ModalInfo.State organization_emp_personal_record_import_modal_info !: organization_modal_interface.OrganizationEmpPersonalRecordImportModalInfo;
    @ModalInfo.State organization_emp_personal_record_print_modal_info  !: organization_modal_interface.OrganizationEmpPersonalRecordPrintModalInfo;
    @ModalInfo.State organization_default_emp_off_modal_info            !: organization_modal_interface.OrganizationDefaultEmpOffModalInfo;

    // 근태관리
    @ModalInfo.State attendance_request_modal_info !: attendance_modal_interface.AttendanceRequestModalInfo;

    // 공통
    @ModalInfo.State image_crop_modal_info                          !: ImageCropModalInfo;
    @ModalInfo.State common_color_picker_modal_info                 !: CommonColorPickerModalInfo;
    @ModalInfo.State common_input_modal_info                        !: CommonInputModalInfo;
    @ModalInfo.State common_datepicker_modal_info                   !: CommonDatepickerModalInfo;
    @ModalInfo.State common_select_friend_and_group_team_modal_info !: CommonSelectFriendAndGroupTeamModalInfo;
    @ModalInfo.State common_premium_expire_modal_info               !: CommonPremiumExpireModalInfo;
    @ModalInfo.State hodu_premium_popup_info                        !: HoduPremiumPopupInfo;

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventShortCreate           ?: any;
    @ModalInfo.Action doSetShowEventRepeatModify          ?: any;
    @ModalInfo.Action doSetShowEventDaumMap               ?: any;
    @ModalInfo.Action doSetShowEventShare                 ?: any;
    @ModalInfo.Action doSetEventShareStatusInfo           ?: any;
    @ModalInfo.Action doSetEventEmailShareModalInfo       ?: (params : EventEmailShareModalInfo) => void;
    @ModalInfo.Action doSetShowEventAttendResult          ?: any;
    @ModalInfo.Action doSetShowEventVoteCreate            ?: any;
    @ModalInfo.Action doSetShowEventVoteResult            ?: any;
    @ModalInfo.Action doSetShowEventReply                 ?: any;
    @ModalInfo.Action doSetEventWorkAssignmentInfo        ?: any;
    @ModalInfo.Action doSetShowEventWorkProcess           ?: any;
    @ModalInfo.Action doSetShowWorkStatus                 ?: any;
    @ModalInfo.Action doSetShowWorkDefaultStatus          ?: any;
    @ModalInfo.Action doSetShowWorkPdfModal               ?: (params : boolean) => void;  
    @ModalInfo.Action doSetGroupModalInfo                 ?: any;
    @ModalInfo.Action doSetShowGroupToBizModal            ?: any;
    @ModalInfo.Action doSetGroupTeamOtherOutModalInfo     ?: any;
    @ModalInfo.Action doSetGroupNoticeReadInfo            ?: any;
    @ModalInfo.Action doSetGroupBoardReadInfo             ?: (params : GroupBoardReadInfo) => void;
    @ModalInfo.Action doSetShowGroupNoticeStatus          ?: any;
    @ModalInfo.Action doSetMessageSendStatus              ?: any;
    @ModalInfo.Action doSetShowGroupAddFileBox            ?: any;
    @ModalInfo.Action doSetGroupAddFileModalNewInfo       ?: (params : GroupAddFileModalNewInfo) => void;
    @ModalInfo.Action doSetEventImportModalInfo           ?: (params : EventImportModalInfo) => void;
    @ModalInfo.Action doSetGroupNoticeReplyModalInfo      ?: (params : GroupNoticeReplyModalInfo) => void;
    @ModalInfo.Action doSetGroupBoardReplyModalInfo       ?: (params : GroupBoardReplyModalInfo) => void;
    @ModalInfo.Action doSetEventHistoryModalInfo          ?: (params : EventHistoryModalInfo) => void;
    @ModalInfo.Action doSetEventExportModalInfo           ?: (params : EventExportModalInfo) => void;
    @ModalInfo.Action doSetEventReadModalInfo             ?: (params : EventReadModalInfo) => void;
    @ModalInfo.Action doSetGroupMemberImportModalInfo     ?: (params : GroupMemberImportModalInfo) => void;
    @ModalInfo.Action doSetEquipmentImportModalInfo       ?: (params : EquipmentImportModalInfo) => void;
    @ModalInfo.Action doSetGroupSurveyTemplateModalInfo   ?: (params : GroupSurveyTemplateModalInfo) => void;
    @ModalInfo.Action doSetGroupSurveyChoiceListModalInfo ?: (params : GroupSurveyChoiceListModalInfo) => void;
    @ModalInfo.Action doSetGroupFileboxMoveModalInfo      ?: (params : GroupFileboxMoveModalInfo) => void;

    // HODU D
    @ModalInfo.Action doSetAppointmentCreateModalInfo   ?: (params : hodu_doc_modal_info.AppointmentCreateModalInfo) => void;
    @ModalInfo.Action doSetDoctorTimeSettingModalInfo   ?: (params : hodu_doc_modal_info.DoctorTimeSettingModalInfo) => void;   
    @ModalInfo.Action doSetDoctorOffSettingModalInfo    ?: (params : hodu_doc_modal_info.DoctorOffSettingModalInfo) => void;   
    @ModalInfo.Action doSetShowPatientAddModal          ?: (params : boolean) => void;   
    @ModalInfo.Action doSetPatientSendMessageModalInfo  ?: (params : hodu_doc_modal_info.PatientSendMessageModalInfo) => void;   
    @ModalInfo.Action doSetDepartmentSettingModalInfo   ?: (params : hodu_doc_modal_info.DepartmentSettingModalInfo) => void;   
    @ModalInfo.Action doSetShowHospitalOffSettingModal  ?: (params : boolean) => void;   
    @ModalInfo.Action doSetDayOffSettingModalInfo       ?: (params : hodu_doc_modal_info.DayOffSettingModalInfo) => void;   
    @ModalInfo.Action doSetAppointmentApplyModalInfo    ?: (params : hodu_doc_modal_info.AppointmentApplyModalInfo) => void;
    @ModalInfo.Action doSetAppointmentListModalInfo     ?: (params : hodu_doc_modal_info.AppointmentListModalInfo) => void;
    @ModalInfo.Action doSetHospitalTimeSettingModalInfo ?: (params : hodu_doc_modal_info.HospitalTimeSettingModalInfo) => void;
    @ModalInfo.Action doSetLoginHospitalInfo            ?: (params : LoginHospitalModalInfo) => void;
    
    // HODU H
    @ModalInfo.Action doSetHomeBuildingCreateModalInfo        ?: (params : hodu_home_modal_info.HomeBuildingCreateModalInfo) => void;
    @ModalInfo.Action doSetHomeContactCreateModalInfo         ?: (params : hodu_home_modal_info.HomeContactCreateModalInfo) => void;
    @ModalInfo.Action doSetHomeResidentCarRequestModalInfo    ?: (params : hodu_home_modal_info.HomeResidentCarRequestModalInfo) => void;
    @ModalInfo.Action doSetHomeResidentInfoModalInfo          ?: (params : hodu_home_modal_info.HomeResidentInfoModalInfo) => void;
    @ModalInfo.Action doSetHomeVisitantCreateModalInfo        ?: (params : hodu_home_modal_info.HomeVisitantCreateModalInfo) => void;
    @ModalInfo.Action doSetHomeVisitantInfoModalInfo          ?: (params : hodu_home_modal_info.HomeVisitantInfoModalInfo) => void;
    @ModalInfo.Action doSetEquipmentManagementDetailModalInfo ?: (params : hodu_home_modal_info.EquipmentManagementDetailModalInfo) => void;
    @ModalInfo.Action doSetSupplyManagementDetailModalInfo    ?: (params : hodu_home_modal_info.SupplyManagementDetailModalInfo) => void;
    @ModalInfo.Action doSetHomeVisitantAmanoInfoModalInfo     ?: (params : hodu_home_modal_info.HomeVisitantAmanoInfoModalInfo) => void;
    @ModalInfo.Action doSetHomeResidentJoinModalInfo          ?: (params : hodu_home_modal_info.HomeResidentJoinModalInfo) => void;

    // 채팅방
    @ModalInfo.Action doSetChattingRoomCreateModalInfo ?: (params : chat_modal_interface.ChattingRoomCreateModalInfo) => void;
    @ModalInfo.Action doSetChattingRoomInfoModalInfo   ?: (params : chat_modal_interface.ChattingRoomInfoModalInfo) => void;

    // 전자결재
    @ModalInfo.Action doSetApprovalMessageModalInfo            ?: (params : approval_modal_interface.ApprovalMessageModalInfo) => void;
    @ModalInfo.Action doSetApproverSelectModalInfo             ?: (params : approval_modal_interface.ApproverSelectModalInfo) => void;
    @ModalInfo.Action doSetApprovalProcessModalInfo            ?: (params : approval_modal_interface.ApprovalProcessModalInfo) => void;
    @ModalInfo.Action doSetApprovalTemplateExceptFileModalInfo ?: (params : approval_modal_interface.ApprovalTemplateExceptFileModalInfo) => void;
    @ModalInfo.Action doSetApprovalFileModalInfo               ?: (params : approval_modal_interface.ApprovalFileModalInfo) => void;
    @ModalInfo.Action doSetApprovalFormModifyModalInfo         ?: (params : approval_modal_interface.ApprovalFormModifyModalInfo) => void;
    @ModalInfo.Action doSetApprovalApproverFavoriteModalInfo   ?: (params : approval_modal_interface.ApprovalApproverFavoriteModalInfo) => void;
    @ModalInfo.Action doSetApprovalReplyModalInfo              ?: (params : approval_modal_interface.ApprovalReplyModalInfo) => void;

    // 조직도 (근태관리, 전자결재 사용)
    @ModalInfo.Action doSetOrganizationModalInfo                        ?: (params : organization_modal_interface.OrganizationModalInfo) => void; 
    @ModalInfo.Action doSetOrganizationModal2Info                       ?: (params : organization_modal_interface.OrganizationModal2Info) => void;
    @ModalInfo.Action doSetOrganizationVacationTypeModalInfo            ?: (params : organization_modal_interface.OrganizationVacationTypeModalInfo) => void; 
    @ModalInfo.Action doSetOrganizationWorkTypeDetailModalInfo          ?: (params : organization_modal_interface.OrganizationWorkTypeDetailModalInfo) => void; 
    @ModalInfo.Action doSetOrganizationWorkTypeAddModalInfo             ?: (params : organization_modal_interface.OrganizationWorkTypeAddModalInfo) => void; 
    @ModalInfo.Action doSetOrganizationOffModalInfo                     ?: (params : organization_modal_interface.OrganizationOffModalInfo) => void; 
    @ModalInfo.Action doSetOrganizationOffAddModalInfo                  ?: (params : organization_modal_interface.OrganizationOffAddModalInfo) => void; 
    @ModalInfo.Action doSetOrganizationVacationAddModlInfo              ?: (params : organization_modal_interface.OrganizationVacationAddModlInfo) => void;
    @ModalInfo.Action doSetOrganizationVacationDetailModalInfo          ?: (params : organization_modal_interface.OrganizationVacationDetailModalInfo) => void;
    @ModalInfo.Action doSetOrganizationMyVacationDetailModalInfo        ?: (params : organization_modal_interface.OrganizationMyVacationDetailModalInfo) => void;
    @ModalInfo.Action doSetOrganizationSignModalInfo                    ?: (params : organization_modal_interface.OrganizationSignModalInfo) => void;
    @ModalInfo.Action doSetOrganizationEmpPersonalRecordDetailModalInfo ?: (params : organization_modal_interface.OrganizationEmpPersonalRecordDetailModalInfo) => void;
    @ModalInfo.Action doSetOrganizationEmpPersonalRecordImportModalInfo ?: (params : organization_modal_interface.OrganizationEmpPersonalRecordImportModalInfo) => void;
    @ModalInfo.Action doSetOrganizationEmpPersonalRecordPrintModalInfo  ?: (params : organization_modal_interface.OrganizationEmpPersonalRecordPrintModalInfo) => void;
    @ModalInfo.Action doSetOrganizationDefaultEmpOffModalInfo           ?: (params : organization_modal_interface.OrganizationDefaultEmpOffModalInfo) => void;

    // 근태관리
    @ModalInfo.Action doSetAttendanceRequestModalInfo ?: (params : attendance_modal_interface.AttendanceRequestModalInfo) => void;

    // 공통
    @ModalInfo.Action doSetImageCropModalInfo           ?: (params : ImageCropModalInfo) => void;
    @ModalInfo.Action doSetCommonColorPickerModalInfo   ?: (params : CommonColorPickerModalInfo) => void;
    @ModalInfo.Action doSetCommonDatepickerModalInfo    ?: (params : CommonDatepickerModalInfo) => void;
    @ModalInfo.Action doSetCommonPremiumExpireModalInfo ?: (params : CommonPremiumExpireModalInfo) => void;
    @ModalInfo.Action doSetHoduPremiumPopupInfo         ?: (params : HoduPremiumPopupInfo) => void;
    
    /**
     * @CalendarInfo.State
     */
	@CalendarInfo.State calendar_view_type !: string; // 현재 달력 모드, 기본값 dayGridMonth (월 달력)
    
    /**
     * @PopupInfo.State
     */
    @PopupInfo.State show_popup !: boolean;

    /**
     * @PopupInfo.Action
     */
    @PopupInfo.Action doSetShowPopup ?: (params : boolean) => void;

    popup_id = "";
    popup_src = "";
    popup_open = () => {};

    is_update_notification_open : boolean = false;
    update_check_interval : number | undefined;

    is_app_display_none : boolean = false;

    beforeMount() : void {
        if( !(location.host.indexOf('localhost') == 0 || new RegExp(/[\s\S]{0,}\.dev\.hoduware\.com/ig).test(location.host) || new RegExp(/[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}/).test(location.host) ) ) {
            const domain_prefix = location.host.substring(0, location.host.indexOf("."));

            let domain = `${domain_prefix}.hodu.app`;
            if( domain_prefix == 'app' ) {
                domain = "web.hodu.app";
            }

            if( location.host.includes(domain) ) {
                return;
            }

            location.href = `https://${domain}`;
        }
    }

    async mounted() : Promise<void> {
        const vue = this;
        
        // 뒤로가기 등을 했을때 modal 끄기
        window.onhashchange = function() {
            vue.modalClose(null, true);
        }

        $('#home_div').css('height', `${window.innerHeight}px`);

        // 팝업 띄우기
        if( localStorage != null ) {
            const today = new Date();
            const today_key = `${today.getFullYear()}${`0${today.getMonth() + 1}`.slice(-2)}${`0${today.getDate()}`.slice(-2)}`;
            
            // 바우처 팝업 (2020-11-23 종료)
            // const save_key = localStorage.getItem('voucher_not_view_today');
            // if( this.doSetShowPopup && (save_key == null || Number(save_key) < Number(today_key)) ) {
            //     this.popup_id = "voucher";
            //     this.popup_src = require("@/assets/images/popup/voucher_popup.png");
            //     this.popup_open = () => {
            //         window.open("/voucher.html?is_voucher=true", "_blank");
            //     };
            //     this.doSetShowPopup(true);
            // }
        }

        // 30분마다 업데이트 체크
        this.updateCheck();
        this.update_check_interval = window.setInterval(this.updateCheck, 30*60*1000);

        // HTML 위치 꼬이는 문제 새로고침 했을때라도 고쳐지도록 display : none; -> display : block이 되도록 함
        // this.is_app_display_none = true;
        // this.$nextTick(() => window.setTimeout(() => { this.is_app_display_none = false }, 200));

        // 이미 로그인 된 상태에 App.vue가 리로드 됐다면 유저정보 갱신
        if( this.isLogin == true ) {
            try {
                const response = await this.hodu_api_call('api/v1/user/me', API_METHOD.GET);

                console.log(response);

                if( response == null || !this.isHttpStatusSuccess(response.status) || response.data == null || response.data.data == null ) {
                    throw new Error("유저 정보 갱신 실패");
                }

                await this.doLogin({
                    isLogin           : true,
                    user_id           : response.data.data.user.user_id,
                    user_type         : response.data.data.last_login_type,
                    user_email        : response.data.data.user.user_email,
                    user_name         : response.data.data.user.user_name,
                    user_phone_number : response.data.data.user.user_phone_number,
                    is_temp_password  : response.data.data.is_temp_password,
                    user_preference   : response.data.data.user_preference,
                    user_group_role   : [],
                    user_team_role    : [],
                    template_map      : response.data.data.template_map,
                    auth_info         : response.data.data.user.auth_info
                });

                await this.get_group_role_service();

                // 달력 환경설정 정보 갱신
                await this.calendar_preference_sync(false);

                // 친구 정보 갱신
                await this.getFriends();

                this.watchCalendarId();

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
            }
        }
    }

    beforeDestroy() : void {
        if( this.update_check_interval ) { clearInterval(this.update_check_interval); }
    }

    /**
     * 기타 메뉴 닫기
     */
    clickWhiteBg() : void {
        if( this.user_profile_open ) { this.doSetUserProfileOpen(false); }
        if( this.notification_list_menu_open ) { this.doSetNotificationListMenuOpen(false); }
        if( this.todo_menu_open ) { this.doSetTodoMenuOpen(false); }       
        if( this.todo_create_or_update ) { this.doTodoCreateOrUpdate(false); }
        if( this.preferences_menu_open ) { this.doSetPreferencesMenuOpen(false); }    
        if( this.etc_menu_open ) { this.doSetEtcMenuOpen(false); }
        this.doSetFilterMenuOpen?.(false);

        // 알림 메뉴가 닫힐때 안 읽은 알림 개수 재조회
        window['getNotificationCount']();

        // 할 일 메뉴가 닫힐때 남은 할 일 개수를 재조회
        window['getTodoCount']();
    }

    /**
     * modal 닫을때 사용되는 함수
     */
    modalClose(event, message_modal_close : boolean = false) : void {
        if( this.show_event_short_create ) { this.doSetShowEventShortCreate(false); }
        if( this.show_event_repeat_modify ) { this.doSetShowEventRepeatModify(false); }
        if( this.show_event_daum_map ) { this.doSetShowEventDaumMap(false); }
        if( this.show_event_share ) { this.doSetShowEventShare(false); }
        if( this.event_share_status_info.show_event_share_status ) { this.doSetEventShareStatusInfo({ show_event_share_status : false, event_id : "" }); }
        if( this.event_email_share_modal_info.show_modal ) { this.doSetEventEmailShareModalInfo?.({ show_modal : false, calendar_id : "", event_id : "", scope : "", scope_id : 0, start : new Date(), end : new Date()}); }
        if( this.show_event_attend_result ) { this.doSetShowEventAttendResult(false); }
        if( this.show_event_vote_create ) { this.doSetShowEventVoteCreate(false); }
        if( this.show_event_vote_result ) { this.doSetShowEventVoteResult(false); }
        if( this.show_event_reply ) { this.doSetShowEventReply(false); }
        if( this.event_work_assignment_info.show_event_work_assignment ) { this.doSetEventWorkAssignmentInfo({ show_event_work_assignment : false, selected_user_ids : [], confirm_function : () => {} }); }
        if( this.show_event_work_process ) { this.doSetShowEventWorkProcess(false); }
        if( this.show_work_status ) { this.doSetShowWorkStatus(false); }
        if( this.show_work_default_status ) { this.doSetShowWorkDefaultStatus(false); }
        if( this.group_modal_info.show_group_list_confirm ) { this.doSetGroupModalInfo({ "show_group_list_confirm" : false, "data" : null }); }
        if( this.show_group_to_biz_modal ) { this.doSetShowGroupToBizModal(false); }
        if( this.group_team_other_out_info.show_modal ) { this.doSetGroupTeamOtherOutModalInfo({ "show_modal" : false, "data" : null }); }
        if( this.group_notice_read_info.show_modal ) { this.doSetGroupNoticeReadInfo({ "show_modal" : false, "noti_uid" : "" }); }
        if( this.group_board_read_info.show_modal ) { this.doSetGroupBoardReadInfo?.({ "show_modal" : false, "group_id" : 0, "board_id" : "" }); }
        if( this.login_hospital_select_modal_info.show_modal && this.doSetLoginHospitalInfo ) { this.doSetLoginHospitalInfo({ show_modal : false, hospital_list : [], callback : (string) => {} }); }
        if( this.show_message_send_status ) { this.doSetMessageSendStatus(false); }
        if( this.show_group_add_file_box ) { this.doSetShowGroupAddFileBox(false); }
        if( this.group_add_file_box_new_info.show_modal && this.doSetGroupAddFileModalNewInfo ) { this.doSetGroupAddFileModalNewInfo({ "show_modal" : false, "is_create" : true, "dir_uuid" : "", "group_id" : 0 }); }
        if( this.event_import_modal_info.show_modal && this.doSetEventImportModalInfo ) { this.doSetEventImportModalInfo({ "show_modal" : false }); }
        if( this.group_notice_reply_modal_info.show_modal && this.doSetGroupNoticeReplyModalInfo ) { this.doSetGroupNoticeReplyModalInfo({ "show_modal" : false, "noti_uid" : "" }); }
        if( this.group_board_reply_modal_info.show_modal && this.doSetGroupBoardReplyModalInfo ) { this.doSetGroupBoardReplyModalInfo({ "show_modal" : false, "group_id" : 0, "board_id" : "" }); }
        if( this.event_history_modal_info.show_modal && this.doSetEventHistoryModalInfo ) { this.doSetEventHistoryModalInfo({ show_modal : false, event_id : "" }); }
        if( this.event_export_modal_info.show_modal ) { this.doSetEventExportModalInfo?.({ show_modal : false }); }
        if( this.event_read_modal_info.show_modal ) { this.doSetEventReadModalInfo?.({ show_modal : false, event_id : "", repeat_seq : 0, calendar_id : "", owner_type : "", owner_id : 0 }); }
        if( this.group_member_import_modal_info.show_modal ) { this.doSetGroupMemberImportModalInfo?.({ show_modal : false, group_id : 0, team_id : 0 }); }
        if( this.equipment_import_modal_info.show_modal ) { this.doSetEquipmentImportModalInfo?.({ show_modal : false, group_id : 0 }); }
        if( this.group_survey_template_modal_info.show_modal ) { this.doSetGroupSurveyTemplateModalInfo?.({ show_modal : false }); }
        if( this.group_survey_choice_list_modal_info.show_modal ) { this.doSetGroupSurveyChoiceListModalInfo?.({ show_modal : false, data : null }); }
        if( this.group_filebox_move_modal_info.show_modal ) { this.doSetGroupFileboxMoveModalInfo?.({ show_modal : false, type : "" }); }

        /**
         * HODU D
         */
        if( this.appointment_create_modal_info.show_modal && this.doSetAppointmentCreateModalInfo ) { this.doSetAppointmentCreateModalInfo({ "show_modal" : false, "is_create_one_patient" : false }); }
        if( this.doctor_time_setting_modal_info.show_modal && this.doSetDoctorTimeSettingModalInfo ) { this.doSetDoctorTimeSettingModalInfo({ "show_modal" : false, "is_create_work_time" : false, "start_date" : new Date(), "end_date" : new Date(), "timetable" : null }); }
        if( this.doctor_off_setting_modal_info.show_modal && this.doSetDoctorOffSettingModalInfo ) { this.doSetDoctorOffSettingModalInfo({ "show_modal" : false, "is_create" : true }); }
        if( this.show_patient_add_modal && this.doSetShowPatientAddModal ) { this.doSetShowPatientAddModal(false); }
        if( this.patient_send_message_modal_info.show_modal && this.doSetPatientSendMessageModalInfo ) { this.doSetPatientSendMessageModalInfo({ show_modal : false, selected_patients : [] }); }
        if( this.department_setting_modal_info.show_modal && this.doSetDepartmentSettingModalInfo ) { this.doSetDepartmentSettingModalInfo({"show_modal" : false, "is_create" : null, "department" : null}); }
        if( this.show_hospital_off_setting_modal && this.doSetShowHospitalOffSettingModal ) { this.doSetShowHospitalOffSettingModal(false); }
        if( this.day_off_setting_modal_info.show_modal && this.doSetDayOffSettingModalInfo ) { this.doSetDayOffSettingModalInfo({"show_modal":false, "is_create": false, "is_doctor": false, "day_off": null});}
        if( this.appointment_apply_modal_info.show_modal && this.doSetAppointmentApplyModalInfo ) { this.doSetAppointmentApplyModalInfo({ "show_modal" : false, "event_id" : "" }); }
        if( this.appointment_list_modal_info.show_modal && this.doSetAppointmentListModalInfo ) { this.doSetAppointmentListModalInfo({ "show_modal" : false, "events" : [] }); }
        if( this.hospital_time_setting_modal_info.show_modal && this.doSetHospitalTimeSettingModalInfo ) { this.doSetHospitalTimeSettingModalInfo({ "show_modal" : false, "start_date" : new Date(), "is_create" : true }); }

        /**
         * HODU H
         */
        if( this.home_building_create_modal_info.show_modal && this.doSetHomeBuildingCreateModalInfo ) { this.doSetHomeBuildingCreateModalInfo({ "show_modal" : false }); }
        if( this.home_contact_create_modal_info.show_modal && this.doSetHomeContactCreateModalInfo ) { this.doSetHomeContactCreateModalInfo({ "show_modal" : false }); }
        if( this.home_resident_car_request_modal_info.show_modal && this.doSetHomeResidentCarRequestModalInfo ) { this.doSetHomeResidentCarRequestModalInfo({ "show_modal" : false }); }
        if( this.home_resident_info_modal_info.show_modal && this.doSetHomeResidentInfoModalInfo ) { this.doSetHomeResidentInfoModalInfo({ "show_modal" : false }); }
        if( this.home_visitant_create_modal_info.show_modal && this.doSetHomeVisitantCreateModalInfo ) { this.doSetHomeVisitantCreateModalInfo({ "show_modal" : false }); }
        if( this.home_visitant_info_modal_info.show_modal && this.doSetHomeVisitantInfoModalInfo ) { this.doSetHomeVisitantInfoModalInfo({ "show_modal" : false }); }
        if( this.equipment_management_detail_modal_info.show_modal && this.doSetEquipmentManagementDetailModalInfo ) { this.doSetEquipmentManagementDetailModalInfo({ "show_modal" : false, "uid" : "", "seq" : 0 }); }
        if( this.supply_management_detail_modal_info.show_modal && this.doSetSupplyManagementDetailModalInfo ) { this.doSetSupplyManagementDetailModalInfo({ "show_modal" : false, "uid" : "", "seq" : 0 }); }
        if( this.home_visitant_amano_info_modal_info.show_modal && this.doSetHomeVisitantAmanoInfoModalInfo ) { this.doSetHomeVisitantAmanoInfoModalInfo({ "show_modal" : false, "amano_info" : null }); }
        if( this.home_resident_join_modal_info.show_modal ) { this.doSetHomeResidentJoinModalInfo?.({ "show_modal" : false, "group_id" : 0, "is_invite" : false }); }

        /**
         * 채팅방
         */
        if( this.chatting_room_create_modal_info.show_modal ) { this.doSetChattingRoomCreateModalInfo?.({ show_modal : false }); }
        if( this.chatting_room_info_modal_info.show_modal ) { this.doSetChattingRoomInfoModalInfo?.({ show_modal : false }); }
        
        /**
         * 전자결재
         */
        if( this.approval_message_modal_info.show_modal ) { this.doSetApprovalMessageModalInfo?.({ show_modal : false }); }
        if( this.approver_select_modal_info.show_modal ) { this.doSetApproverSelectModalInfo?.({ show_modal : false, is_approver : false, is_default_setting : false, is_self_approve : false }); }
        if( this.approval_process_modal_info.show_modal ) { this.doSetApprovalProcessModalInfo?.({ show_modal : false }); }
        if( this.approval_template_except_file_modal_info.show_modal ) { this.doSetApprovalTemplateExceptFileModalInfo?.({ show_modal : false }); }
        if( this.approval_file_modal_info.show_modal ) { this.doSetApprovalFileModalInfo?.({ show_modal : false }); }
        if( this.approval_form_modify_modal_info.show_modal ) { this.doSetApprovalFormModifyModalInfo?.({ show_modal : false, template_class_id : 0, template_id : 0 }); }
        if( this.approval_approver_favorite_modal_info.show_modal ) { this.doSetApprovalApproverFavoriteModalInfo?.({ show_modal : false }); }
        if( this.approval_reply_modal_info.show_modal ) { this.doSetApprovalReplyModalInfo?.({ show_modal : false, group_id : 0, approval_uid : '' }); }

        /**
         * 조직도 (근태관리, 전자결재 사용)
         */
        if( this.organization_modal_info.show_modal ) { this.doSetOrganizationModalInfo?.({ show_modal : false }); }
        if( this.organization_modal2_info.show_modal ) { this.doSetOrganizationModal2Info?.({ show_modal : false, dept_id : 0 }); }
        if( this.organization_vacation_type_modal_info.show_modal ) { this.doSetOrganizationVacationTypeModalInfo?.({ show_modal : false, is_create : true }); }
        if( this.organization_work_type_detail_modal_info.show_modal ) { this.doSetOrganizationWorkTypeDetailModalInfo?.({ show_modal : false, work_type_id : 0 }); }
        if( this.organization_work_type_add_modal_info.show_modal ) { this.doSetOrganizationWorkTypeAddModalInfo?.({ show_modal : false, is_create : true, work_type_id : 0 }); }
        if( this.organization_off_modal_info.show_modal ) { this.doSetOrganizationOffModalInfo?.({ show_modal : false, user_id : 0 }); }
        if( this.organization_off_add_modal_info.show_modal ) { this.doSetOrganizationOffAddModalInfo?.({ show_modal : false, is_create : true, off_id : 0 }); }
        if( this.organization_vacation_add_modal_info.show_modal ) { this.doSetOrganizationVacationAddModlInfo?.({ show_modal : false }); }
        if( this.organization_vacation_detail_modal_info.show_modal ) { this.doSetOrganizationVacationDetailModalInfo?.({ show_modal : false, approval_uid : "" }); }
        if( this.organization_my_vacation_detail_modal_info.show_modal ) { this.doSetOrganizationMyVacationDetailModalInfo?.({ show_modal : false, approval_uid : "" }); }
        if( this.organization_sign_modal_info.show_modal ) { this.doSetOrganizationSignModalInfo?.({ show_modal : false }); }
        if( this.organization_emp_personal_record_detail_modal_info.show_modal ) { this.doSetOrganizationEmpPersonalRecordDetailModalInfo?.({ show_modal : false, group_id : 0, user_id : 0 }); }
        if( this.organization_emp_personal_record_import_modal_info.show_modal ) { this.doSetOrganizationEmpPersonalRecordImportModalInfo?.({ show_modal : false }); }
        // if( this.organization_emp_personal_record_print_modal_info.show_modal ) { this.doSetOrganizationEmpPersonalRecordPrintModalInfo?.({ show_modal : false, group_id : 0, user_id : 0 }); }
        if( this.organization_default_emp_off_modal_info.show_modal ) { this.doSetOrganizationDefaultEmpOffModalInfo?.({ show_modal : false, group_id : 0 }); }

        /**
         * 근태관리
         */
        if( this.attendance_request_modal_info.show_modal ) { this.doSetAttendanceRequestModalInfo?.({ show_modal : false, user_id : 0, yyyymmdd : '' }); }

        /**
         * 공통
         */
        if( this.common_color_picker_modal_info.show_modal && this.doSetCommonColorPickerModalInfo ) { this.doSetCommonColorPickerModalInfo({ "show_modal" : false, "selected_color" : "", "is_dc_only" : false }); }
        if( this.common_input_modal_info.show_modal ) { this.doSetCommonInputModalInfo?.({ "show_modal" : false }); }
        if( this.common_datepicker_modal_info.show_modal ) { this.doSetCommonDatepickerModalInfo?.({ "show_modal" : false }); }
        if( this.common_select_friend_and_group_team_modal_info.show_modal ) { this.doSetCommonSelectFriendAndGroupTeamModalInfo?.({ "show_modal" : false }) }
        
        // 시간 지나기전에는 절대 꺼지지 않아야한다고함
        // if( this.common_premium_expire_modal_info.show_modal ) { this.doSetCommonPremiumExpireModalInfo?.({ show_modal : false, data : {} }); }
        
        // 직접 끄지않는 이상 안닫힘
        // if( this.hodu_premium_popup_info.show_modal ) { this.doSetHoduPremiumPopupInfo?.({ "show_modal" : false }) }

        // 크롭 모달 && 메세지 모달은 뒤로가기 했을때만 끈다
        if( message_modal_close && this.image_crop_modal_info.show_modal && this.doSetImageCropModalInfo ) { this.doSetImageCropModalInfo({ "show_modal" : false }); }
        if( message_modal_close && this.message_modal_info.show_message_modal && this.doSetMessageModalInfo ) { this.doSetMessageModalInfo({ "show_message_modal" : false, "message_type" : "", "message" : "", "button_text" : [] }); }
    }

    /**
     * 메인 페이지로 이동
     */
    moveMainPage() : void {
        this.hodu_router_push('/');
    }

    /**
     * 로그인 페이지로 이동
     */
    moveLoginPage() : void {
        this.hodu_router_push('/login');
    }

    /**
     * App Div에 들어갈 class 구하기
     */
    getAppClass() : string {

        let app_class : string = '';

        /**
         * 오른쪽 메뉴 열렸을때 showfff
         */
        if( this.user_profile_open     == true || this.notification_list_menu_open == true || 
            this.todo_menu_open        == true || this.todo_create_or_update       == true ||
            this.preferences_menu_open == true || this.etc_menu_open               == true || 
            this.filter_menu_open      == true ) {
            app_class += 'showfff ';
        }

        /**
         * HODU C 달력 보는중일때 hc
         */
        if( this.scope_group_team_option != null && this.scope_group_team_option.biz_type == GROUP_TYPE.BIZC ) {
            app_class += 'hc ';
        }

        /**
         * HODU D 달력 보는중일때 hoduD
         */
        if( this.scope_group_team_option != null && this.scope_group_team_option.biz_type == GROUP_TYPE.BIZD ) {
            // app_class += 'hoduD ';
            app_class += 'hc '; // 임시로 hc 그대로 사용
        }

        /**
         * HODU H 달력 보는중일때 hoduH
         */
        if( this.scope_group_team_option != null && this.scope_group_team_option.biz_type == GROUP_TYPE.BIZH ) {
            // app_class += 'hoduD ';
            app_class += 'hc hoduHome '; // 임시로 hc 그대로 사용
        }

        /**
         * 프리미엄 달력 보는중일때 pr_dc0 ~ 9
         */
        if( this.scope != null && this.scope != '' && this.scope != OWNER_TYPE.PERSONAL ) {
            app_class += `pr_${this.hodu_color_dc_lc(this.scope_group_team_option.group_team_color)} `;
        }

        app_class += this.calendar_view_type;

        return app_class.trim();
    }

    /**
     * 로고 텍스트 반환
     */
    getLogoText() : string {
        if( this.scope_group_team_option == null ) { return ""; }
        
        switch( this.scope_group_team_option.biz_type ) {
            case GROUP_TYPE.BIZC:
                return "HODU C by HODUWARE";

            case GROUP_TYPE.BIZD:
                return "HODU D by HODUWARE";

            case GROUP_TYPE.BIZH:
                return "HODU H by HODUWARE";
        }

        return "HODU by HODUWARE";
    }

    /**
     * section_ce 밑에 생기는 모달이 열려있는지 여부
     */
    isSectionCeModalOpen() : boolean {
        return this.show_work_pdf_modal == true;
    }

    /**
     * section_ce 밑에 생기는 모달 닫기
     */
    sectionCeModalClose() : void {
        this.doSetShowWorkPdfModal?.(false);
    }

    /**
     * 호두 웹 앱 업데이트 체크
     */
    async updateCheck() : Promise<void> {
        const build_version = this.getBuildVersion();

        if( build_version == null ) {
            console.log('invalid build version');
            return;
        }

        try {
            const response = await this.hodu_api_call('api/v1/version', API_METHOD.GET, null, false);
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("업데이트 체크 실패");
            }

            const response_build_version = response.data.data.WEB_BUILD_VERSION;

            if( response_build_version == null ) {
                throw new Error("API 서버 웹 빌드 버전 오류");
            }

            this.is_update_notification_open = Number(build_version) < Number(response_build_version);

            // 업데이트 체크 후 친구 데이터 갱신
            if( this.isLogin == true ) this.getFriends();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    @Watch("isLogin")
    watchIsLogin() {
        if( this.isLogin == true ) {
            console.log('login');
            this.getFriends();
        }
    }

    @Watch("calendar_id")
    async watchCalendarId() : Promise<void> {
        
        // if( this.calendar_id.includes("personal") ) {
        //     return;
        // }

        // console.log(this.calendar_id);

        // if( this.calendar_id == 'group-4636' ) {
        //     this.doSetCommonPremiumExpireModalInfo?.({
        //         show_modal : true,
        //         title : "알림",
        //         content : "메시지 예제 입니다",
        //     });
        // }

        // doSetCommonPremiumExpireModalInfo

        if( this.calendar_id == null || this.calendar_id.length < 1 ) {
            return;
        }

        const calendar_id_split = this.calendar_id.split('-');
        const owner_type = calendar_id_split[0].toUpperCase();
        const owner_id = calendar_id_split[1];

        try {

            const response = await this.hodu_api_call(`api/v1/hodu/message/${owner_type}/${owner_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.message ) {
                throw new Error("호두 공지사항 메시지 조회 중 오류 발생");
            }

            const message = response.data.data.message;

            const message_type = message.message_data.type;

            switch(message_type) {
                case "PREMIUM_EXPIRE":
                    this.doSetCommonPremiumExpireModalInfo?.({
                        show_modal : true,
                        data : message.message_data,
                    });
                    break;
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 리사이즈 감지 - 브라우저 최소 크기 1024 * 768 제한? 
     */
    handleResize() : void {
        $('.ware_wrapper').css('height', `${window.innerHeight}px`);
    }
}
