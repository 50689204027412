
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { CALENDAR_TYPE, OWNER_TYPE, EVENT_SUB_TYPE, CRUD_TYPE, GROUP_TYPE, API_METHOD, SHARE_OPTION } from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';

import { t_event } from '@/model/event';
const dateFormat = require('dateformat');

import { namespace } from 'vuex-class';
const CalendarInfo = namespace('CalendarInfo');
const EventInfo = namespace('EventInfo');
const HoduDocInfo = namespace('HoduDocInfo');
const ModalInfo = namespace('ModalInfo');
const GalleryInfo = namespace('GalleryInfo');

import { ScheduleSearchConfig, GroupAndTeamFilter, EventSearchOption } from '@/store/modules/CalendarInfo';
import { AppointmentDetailInfo } from '@/store/modules/HoduDocInfo';

import moment from 'moment';
import 'moment-lunar';
const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

import { RRule, RRuleSet, rrulestr } from 'rrule'
import { hodu_doc_enum, hodu_doc_modal_info } from '@/model/hodudoc';
import { hodu_color } from '@/common/color';
import { local_storage_info } from '@/lib/HoduLocalStorage';

@Component({
    components: {
        FullCalendar, ResizeObserver
    },
}) export default class Calendar extends Mixins(VueHoduCommon) {
    
    // this.$refs.fullCalendar.getApi() 사용
    $refs!: {
        fullCalendar : HTMLFormElement;
    }

    /**
     * @CalendarInfo.State
     */
    @CalendarInfo.State start_date             !: string;               // 마지막으로 보고있던 월의 시작일
    @CalendarInfo.State calendar_view_type     !: string;               // 현재 달력 모드, 기본값 dayGridMonth (월 달력)
    @CalendarInfo.State selected_date          !: Date;                 // 선택된 날짜
    @CalendarInfo.State calendar_height        !: number;               // 캘린더 높이
    @CalendarInfo.State event_limit            !: number;               // 이벤트 보이는 제한 개수
    @CalendarInfo.State go_to_date             !: Date;                 // LeftDatePicker Watch 콜백용
    @CalendarInfo.State event_search_option    !: EventSearchOption;    // 일정 검색 옵션
 
    /** 
     * @CalendarInfo.Action
     */ 
    @CalendarInfo.Action doSetStartDate           ?: any;                             // start_date 업데이트
    @CalendarInfo.Action doSetCalendarViewType    ?: any;                             // calendar_view_type 업데이트
    @CalendarInfo.Action doSetSelectedDate        ?: any;                             // selected_date 업데이트
    @CalendarInfo.Action doSelectScheduleList     ?: any;                             // schedule_search_config 업데이트  
    @CalendarInfo.Action doSetCalendarHeight      ?: any;                             // calendar_height 업데이트
    @CalendarInfo.Action doSetEventLimit          ?: any;                             // event_limit 업데이트
    @CalendarInfo.Action doSetIsEventFilterSearch ?: (a : boolean) => void;           // is_event_search_filter 업데이트 
    @CalendarInfo.Action doSetEventSearchQuery    ?: any;                             // event_search_query 업데이트
    @CalendarInfo.Action doSetEventSearchOption   ?: (a : EventSearchOption) => void; // event_search_option 업데이트 
 
    /**
     * @EventInfo.Action
     */
    @EventInfo.Action doSetIsFirstRepeatEvent   ?: any; // 조회하는 반복일정이 해당 반복일정의 첫 일정이였다면 true 아니라면 false
    @EventInfo.Action doSetEventOriginalDate    ?: any; // 반복일정 조회시 해당 일정의 원본 date들을 설정
    @EventInfo.Action doSetIsMovedByShortCreate ?: any; // 일정 간편 작성에서 들어온건지의 여부
    
    /**
     * HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetAppointmentDetailInfo ?: (parms : AppointmentDetailInfo) => void;

    /**
     * @GalleryInfo.Action
     */
    @GalleryInfo.Action doSetGalleryInfo ?: (gallery_info : any) => void;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventShortCreate     ?: any;                                                             // 일정 간편등록 Modal 조작
    @ModalInfo.Action doSetAppointmentListModalInfo ?: (params : hodu_doc_modal_info.AppointmentListModalInfo) => void; // 병원예약 클릭시 띄울 Modal

    get computedGroupAndTeam() : any[] {
        let group_and_team : any[] = [];

        const group_length : number = this.all_group_data.length;
        for( let i = 0; i < group_length; i++ ) {
            const group_data : any = this.all_group_data[i];
            group_and_team.push(group_data);

            if( group_data.teams == null || group_data.teams.length < 1 ) { continue; }

            const team_length : number = group_data.teams.length;
            for( let j = 0; j < team_length; j++ ) { group_and_team.push(group_data.teams[j]); }
        }

        return group_and_team;
    }

    is_calendar_mounted : boolean = false; // 달력 mount가 완료 됐는지 여부
    waited_render_arg : any = null;        // 최초 달력이 mount 되기까지 대기 중일때 들어온 handleDatesRender에서 사용되는 arg

    event_search_query  : string  = "";    // 일정 검색 쿼리
    searchVisible       : boolean = false; // 일정검색창 보일지 여부
    searchOptionVisible : boolean = false; // 일정검색 옵션창 보일지 여부

    start_date_text : string | null = null;
    end_date_text   : string | null = null;

    activeStart ?: Date | null = null; // (조회 기준) 달력 시작일
    activeEnd   ?: Date | null = null; // (조회 기준) 달력 종료일
    lunar_ymd   ?: string;             // 시작일 기준 음력

    is_create_menu_open : boolean = false;

    events     : any                 = [];        // 일정 배열
    lunar_date : Map<string, string> = new Map(); // 음력 Map
    holidays   : Map<string, Object> = new Map(); // 공휴일 Map
    calendarPlugins : any = [ dayGridPlugin, timeGridPlugin, rrulePlugin, interactionPlugin, momentPlugin ]; // 플러그인

    dc_color : string[] = ["#FF6363", "#FFA70E", "#FFC72F", "#FF198B", "#00B2C7", "#13D08B", "#4DBAFF", "#477FFF", "#6854FF", "#35405A"];
    lc_color : string[] = ["#B9A494", "#E67D9B", "#FF9898", "#AB8DD6", "#F3AC77", "#E3D37A", "#8DD6A0", "#7197ED", "#8DAED6", "#6F7A93"];

    is_first_init = false;

    views : any = {
        dayGrid: {
            titleFormat : 'YYYY.MM',
            columnHeaderText: (date : Date) => {
                switch( date.getDay() ){
                    case 0:
                        return "일";

                    case 1:
                        return "월";

                    case 2:
                        return "화";

                    case 3:
                        return "수";

                    case 4:
                        return "목";

                    case 5:
                        return "금";

                    case 6:
                        return "토";

                    default:
                        return "?";
                }    
            },
            // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
        },
        timeGrid: {
            // options apply to timeGridWeek and timeGridDay views
        },
        week: {
            titleFormat : (obj : any) => { 
                const startDate : Date = new Date(`${obj.start.year}-${obj.start.month + 1}-${obj.start.day}`);
                const endDate   : Date = new Date(`${obj.end.year}-${obj.end.month + 1}-${obj.end.day}`);

                const startYear : string = `${startDate.getFullYear()}`;
                const startMonth : string = `0${startDate.getMonth() + 1}`.slice(-2);
                const startDay : string = `0${startDate.getDate()}`.slice(-2);

                const endMonth : string = `0${endDate.getMonth() + 1}`.slice(-2);
                const endDay : string = `0${endDate.getDate()}`.slice(-2);

                let dateString : string = `${startYear}.${startMonth}.${startDay} - ${endMonth}.${endDay}<span class="fc-special-name" style="margin-right:0px;"></span>`;
                return dateString; 
            },
            columnHeaderHtml: (date : Date) => {

                switch( date.getDay() ){
                    case 0:
                        return `일<a class="fc-day-number">${date.getDate()}</a><span class="fc-special-name"></span>`;

                    case 1:
                        return `월 <a class="fc-day-number">${date.getDate()}</a><span class="fc-special-name"></span>`;

                    case 2:
                        return `화 <a class="fc-day-number">${date.getDate()}</a><span class="fc-special-name"></span>`;

                    case 3:
                        return `수 <a class="fc-day-number">${date.getDate()}</a><span class="fc-special-name"></span>`;

                    case 4:
                        return `목 <a class="fc-day-number">${date.getDate()}</a><span class="fc-special-name"></span>`;

                    case 5:
                        return `금 <a class="fc-day-number">${date.getDate()}</a><span class="fc-special-name"></span>`;

                    case 6:
                        return `토 <a class="fc-day-number">${date.getDate()}</a><span class="fc-special-name"></span>`;

                    default:
                        return "?";
                }    
            },
            // options apply to dayGridWeek and timeGridWeek views
        },
        day: {
            titleFormat : 'YYYY.MM.DD',
            columnHeaderHtml: (date : Date ) => {

                switch( date.getDay() ){
                    case 0:
                        return `일요일 ${date.getDate()}일</span><span class="day-num-other"></span><span class="fc-special-name"></span>`;

                    case 1:
                        return `월요일 ${date.getDate()}일</span><span class="day-num-other"></span><span class="fc-special-name"></span>`;

                    case 2:
                        return `화요일 ${date.getDate()}일</span><span class="day-num-other"></span><span class="fc-special-name"></span>`;

                    case 3:
                        return `수요일 ${date.getDate()}일</span><span class="day-num-other"></span><span class="fc-special-name"></span>`;

                    case 4:
                        return `목요일 ${date.getDate()}일</span><span class="day-num-other"></span><span class="fc-special-name"></span>`;

                    case 5:
                        return `금요일 ${date.getDate()}일</span><span class="day-num-other"></span><span class="fc-special-name"></span>`;

                    case 6:
                        return `토요일 ${date.getDate()}일</span><span class="day-num-other"></span><span class="fc-special-name"></span>`;

                    default:
                        return "?";
                }    
            },
            // options apply to dayGridDay and timeGridDay views
        }
    }
    
    order_function : (o1 : any, o2 : any) => number = (o1, o2) => {
        
        const o1_start_moment = moment(o1.eventAtomic.event_data.schedule_date.start);
        const o2_start_moment = moment(o2.eventAtomic.event_data.schedule_date.start);
        const o1_end_moment = moment(o1.eventAtomic.event_data.schedule_date.end);
        const o2_end_moment = moment(o2.eventAtomic.event_data.schedule_date.end);

        const o1_all_day = o1.eventAtomic.event_data.schedule_date.isAllDay;
        const o2_all_day = o2.eventAtomic.event_data.schedule_date.isAllDay;
        
        if( o1_all_day == true ) o1_start_moment.set('hour', 0).set('minute', 0);
        if( o2_all_day == true ) o2_start_moment.set('hour', 0).set('minute', 0);

        const o1_start_yyyymmdd = o1_start_moment.format("YYYYMMDD");
        const o2_start_yyyymmdd = o2_start_moment.format("YYYYMMDD");

        const o1_start_hhmm = o1_start_moment.format("HHmm");
        const o2_start_hhmm = o2_start_moment.format("HHmm");

        const o1_end_yyyymmdd = o1_end_moment.format("YYYYMMDD");
        const o2_end_yyyymmdd = o2_end_moment.format("YYYYMMDD");

        const o1_title = o1.title;
        const o2_title = o2.title;

        const o1_continious = o1_start_yyyymmdd != o1_end_yyyymmdd;
        const o2_continious = o2_start_yyyymmdd != o2_end_yyyymmdd;

        const o1_event_sub_type = o1.eventAtomic.event_sub_type;
        const o2_event_sub_type = o2.eventAtomic.event_sub_type;

        const o1_order = this.getSubTypeOrder(o1);
        const o2_order = this.getSubTypeOrder(o2);

        // console.log("====================================");
        // console.log(`${o1_title}:${o1_start_yyyymmdd}${o1_start_hhmm}:${o1_continious}`);
        // console.log(`${o2_title}:${o2_start_yyyymmdd}${o2_start_hhmm}:${o2_continious}`);
        // console.log("====================================");

        // 연속일정
        if( o1_continious && o2_continious ) {

            // 다른 날짜
            if( o1_start_yyyymmdd != o2_start_yyyymmdd ) {
                if( o1_start_yyyymmdd < o2_start_yyyymmdd ) return -1;
                if( o1_start_yyyymmdd > o2_start_yyyymmdd ) return 1;
            }

            // 같은 날짜
            if( o1_start_hhmm < o2_start_hhmm ) return -1;
            if( o1_start_hhmm > o2_start_hhmm ) return 1;
        }
        else if( o1_continious && !o2_continious ) return -1;
        else if( !o1_continious && o2_continious ) return 1;

        // 예약이 가장 먼저나오며 예약 시간순
        if( o1_event_sub_type == EVENT_SUB_TYPE.APPOINTMENT && o2_event_sub_type == EVENT_SUB_TYPE.APPOINTMENT ) {
            if( o1_start_hhmm < o2_start_hhmm ) return -1;
            if( o1_start_hhmm > o2_start_hhmm ) return 1;
        }
        else if( o1_event_sub_type == EVENT_SUB_TYPE.APPOINTMENT && o2_event_sub_type != EVENT_SUB_TYPE.APPOINTMENT ) return -1;
        else if( o1_event_sub_type != EVENT_SUB_TYPE.APPOINTMENT && o2_event_sub_type == EVENT_SUB_TYPE.APPOINTMENT ) return 1;

        if( o1_all_day && o2_all_day ) {
            // 이벤트 종류에 따른 sort 업무계열 > 일정계열 > 휴가, 출장 (휴가 출장은 종일이 없지만 나중에 어떻게될지 모르므로 추가)
            if( o1_order < o2_order ) return -1;
            if( o1_order > o2_order ) return 1;

            if( o1_title.toUpperCase() < o2_title.toUpperCase() ) return -1;
            else if( o1_title.toUpperCase() > o2_title.toUpperCase() ) return 1;
            else if( o1_title < o2_title ) return 1;
            else if( o1_title > o2_title ) return -1;
        }
        else if( o1_all_day && !o2_all_day ) return -1;
        else if( !o1_all_day && o2_all_day ) return 1;

        if( o1_order < o2_order ) return -1;
        if( o1_order > o2_order ) return 1;
        if( o1_start_hhmm < o2_start_hhmm ) return -1;
        if( o1_start_hhmm > o2_start_hhmm ) return 1;
        if( o1_title.toUpperCase() < o2_title.toUpperCase() ) return -1;
        if( o1_title.toUpperCase() > o2_title.toUpperCase() ) return 1;
        if( o1_title < o2_title ) return 1;
        if( o1_title > o2_title ) return -1;

        return 0;
    };

    order : any[] = [this.order_function];

    async mounted() : Promise<void> {
        const vue = this;

        // 검색 버튼 클릭시 searchDiv 띄우기
        $(".searchDiv .bt_option").click(function(){
            $(this).toggleClass("on");
            $(".schOption").toggleClass("on");
            $(".bg4sch").toggleClass("on");
        });

        // 검색옵션 열린 후 암전 부분 클릭시 검색 옵션 없애기
        // $(".bg4sch").click(function(){
        //     $(".searchDiv .bt_option").removeClass("on");
        //     $(".schOption").removeClass("on");
        //     $(".bg4sch").removeClass("on");
        // });


        // 검색 버튼 클릭시 searchDiv 띄우기
        // $("#section_ce .schBtn").click(function(){
        //     $("#section_ce .searchDiv").addClass("on");
        //     $('#event_search').focus();
        // });

        // 검색 닫기 버튼 클릭시 searchDiv 없애기
        // $("#section_ce .searchDiv .closeBtn").click(function(){
        //     $("#section_ce .searchDiv").removeClass("on");
        //     $(".schOption").removeClass("on");
        //     $(".bg4sch").removeClass("on");

        // });

        // @mousewheel 안먹히는 브라우저가 있어서 onWheel 사용
        $('.section_ce_fix.cal').on('wheel', (e : any) => {
            vue.handleMouseWheel(e.originalEvent);
        });

        this.doSetSelectedDate(new Date(moment(this.start_date).format()).getFullYear() == new Date().getFullYear() && new Date(moment(this.start_date).format()).getMonth() == new Date().getMonth() ? new Date() : new Date(moment(this.start_date).format()));
        this.calendarGotoDateCss(dateFormat(new Date(moment(this.start_date).format()).getMonth() == new Date().getMonth() ? new Date() : new Date(moment(this.start_date).format()), 'yyyy-mm-dd'));
        this.calendarResize();
        
        this.is_calendar_mounted = true;
        if( this.waited_render_arg != null ) { this.handleDatesRender(this.waited_render_arg); }

        this.searchOptionReset();

        window['getEvents'] = this.getEvents;
        window['calendar_reload'] = async() => {
            console.log("======================================= reload calendar =======================================");
            this.events.splice(0,this.events.length);
            await this.$forceUpdate();
            this.getEvents();
        };

        // 페이지를 처음 띄우고 로그인시 이상하게 제대로 height가 적용 안되는 문제가 발생함 변수 자체에는 제대로된 값이 들어있음 
        window.setTimeout(() => {
            this.doSetCalendarHeight(this.calendar_height - 1);
            this.$nextTick(() => { this.calendarResize(); });
        }, 1500);
    }

    beforeDestroy() {
        delete(window['getEvents']);
        delete(window['calendar_reload']);
    }

    /**
     * 이벤트 렌더링
     */
    handleEventRender(arg) : boolean | HTMLElement | void {

        const eventAtomic : t_event = arg.event.extendedProps.eventAtomic;

        // 주별 또는 일별 달력 일때 && 종일 일때 (병원 예약은 절대 종일이 될 수 없다)
        if( ( arg.view.type == CALENDAR_TYPE.TIME_GRID_DAY || arg.view.type == CALENDAR_TYPE.TIME_GRID_WEEK ) && arg.event.allDay == true ){
            let timeGridDayText : string = eventAtomic.event_data.event_owner_name == null ? "" : eventAtomic.event_data.event_owner_name;

            if ( eventAtomic.event_data.event_owner_group_name ) {
                timeGridDayText += " / " + eventAtomic.event_data.event_owner_group_name;
            }

            if ( eventAtomic.event_data.event_owner_team_name ) {
                timeGridDayText += " / " + eventAtomic.event_data.event_owner_team_name;
            }

            let sub_type_text = ""; 
            switch(eventAtomic.event_sub_type) {
                case EVENT_SUB_TYPE.CARD:
                    sub_type_text = '카드';
                    break;

                case EVENT_SUB_TYPE.REPORT:
                    sub_type_text = '업무일지';
                    break;

                case EVENT_SUB_TYPE.MEETINGLOG:
                    sub_type_text = '회의록';
                    break;

                case EVENT_SUB_TYPE.WORK:
                    sub_type_text = '프로젝트';
                    break;
            }

            const timeGridDayHtml : string = `
                <a href="#" class="fc-day-grid-event fc-h-event fc-event fc-start fc-end fc-draggable fc-resizable" style="background-color: ${eventAtomic.event_data.color} ;border-color: ${eventAtomic.event_data.color} ">
                    <div class="fc-content">
                        ${ eventAtomic.event_sub_type != EVENT_SUB_TYPE.SCHEDULE ? `<span class="fc-sub-title">${sub_type_text}</span>` : '' }
                        <span class="fc-title"> ${eventAtomic.event_data.title} </span>
                        <span class="writtenBy"> ${timeGridDayText} </span>
                    </div>
                    <div class="fc-resizer fc-end-resizer">
		            </div>
                </a>
            `;

            return $(timeGridDayHtml)[0];
            
        }

        // 주별 또는 일별 달력 일때 && 종일이 아닐때
        if( ( arg.view.type == CALENDAR_TYPE.TIME_GRID_DAY || arg.view.type == CALENDAR_TYPE.TIME_GRID_WEEK ) && arg.event.allDay == false ){
            const el : JQuery = $(arg.el);
            
            el.css('border-left', `solid 3px ${eventAtomic.event_data.color}`);
            el.find('.fc-time span').text(this.makeAmPmDateText(eventAtomic.event_data.schedule_date.start, eventAtomic.event_data.schedule_date.end)); // ex) AM 12:00 ~ PM 12:00
            
            // 그룹 이름 데이터 생성
            let grpHtml : string = "";
            if( eventAtomic.event_data.event_owner_group_name ){
                grpHtml = ` / <span class="grp">${eventAtomic.event_data.event_owner_group_name}</span>`;
            }

            if( eventAtomic.event_data.event_owner_team_name ){
                grpHtml = ` / <span class="grp">${eventAtomic.event_data.event_owner_team_name}</span>`;
            }
            
            // 작성자 / 그룹명 영역 추가
            el.find('.fc-content').append(`<div class="writtenBy"><span class="userName">${eventAtomic.event_data.event_owner_name}</span>${grpHtml}</div>`);
            
            if( arg.view.type == CALENDAR_TYPE.TIME_GRID_DAY ) {
                el.find('.fc-content').append(`
                    <div class="fc-icons">
                        <ul class="iconList">
                            ${ eventAtomic.event_data.schedule_date.rrule == null || eventAtomic.event_data.schedule_date.rrule.length < 1 ? '' : '<li class="repeat"><span class="icon"></span><span class="descript">반복</span></li>' }
                            ${ eventAtomic.event_data.alarm == null || eventAtomic.event_data.alarm.length < 1 ? '' : '<li class="alarm"><span class="icon"></span><span class="descript">알림</span></li>' }
                            ${ eventAtomic.event_data.location == null || eventAtomic.event_data.location.length < 1 ? '' : '<li class="place"><span class="icon"></span><span class="descript">장소</span></li>' }
                            ${ eventAtomic.event_data.contacts == null || eventAtomic.event_data.contacts.length < 1 || eventAtomic.event_data.contacts[0].tel == null || eventAtomic.event_data.contacts[0].tel.length < 1 ? '' : '<li class="tel"><span class="icon"></span><span class="descript">전화</span></li>' }
                            ${ eventAtomic.event_data.note == null || eventAtomic.event_data.note.length < 1 ? '' : '<li class="note"><span class="icon"></span><span class="descript">노트</span></li>' }
                            ${ eventAtomic.event_data.memo == null || eventAtomic.event_data.memo.length < 1 ? '' : '<li class="memo"><span class="icon"></span><span class="descript">메모</span></li>' }
                            ${ eventAtomic.event_data.attachment == null || eventAtomic.event_data.attachment.imgs == null || eventAtomic.event_data.attachment.imgs.length < 1 ? '' : '<li class="pic"><span class="icon"></span><span class="descript">사진</span></li>' }
                            ${ eventAtomic.event_data.attachment == null || eventAtomic.event_data.attachment.files == null || eventAtomic.event_data.attachment.files.length < 1 ? '' : '<li class="file"><span class="icon"></span><span class="descript">파일</span></li>' }
                            ${ !eventAtomic.event_data.attend ? '' : '<li class="attnd"><span class="icon"></span><span class="descript">참석여부</span></li>' }
                            ${ eventAtomic.event_data.vote == null || eventAtomic.event_data.vote.length < 1 ? '' : '<li class="vote"><span class="icon"></span><span class="descript">투표/설문</span></li>' }
                            ${ !eventAtomic.event_reply_id || eventAtomic.event_reply_id.length < 1 ? '' : '<li class="cmmnt"><span class="icon"></span><span class="descript">댓글</span></li>' }
                            ${ ( eventAtomic.subscribe_users  == null || eventAtomic.subscribe_users.length < 1 ) &&
                               ( eventAtomic.subscribe_groups == null || eventAtomic.subscribe_groups.length < 1 ) &&
                               ( eventAtomic.subscribe_teams  == null || eventAtomic.subscribe_teams.length < 1 ) ? '' : '<li class="share"><span class="icon"></span><span class="descript">공유일정</span></li>' } 
                        </ul>
                    </div>`
                );
            }
              
            // 백그라운드 추가
            el.find('.fc-resizer.fc-end-resizer').before('<div class="fc-bg"></div>');

            return arg.el;
        }

        // 월별 달력에서 배경색이 없는 경우 style에 컬러 강제 지정 및 border-left 3px 컬러 지정
        if( arg.view.type == CALENDAR_TYPE.DAY_GRID_MONTH && arg.event.extendedProps.hasBackground == false ) {
            eventAtomic.event_data.title = eventAtomic.event_data.title.replace(/^(.{1,1000}) ([0-2][0-9]:[0-5][0-9])/ig, '$2 $1');
            const is_target_appointment : boolean = eventAtomic.event_sub_type == EVENT_SUB_TYPE.APPOINTMENT && new RegExp(/[0-2][0-9]:[0-5][0-9]/g).test(eventAtomic.event_data.title.substring(0,5)); 

            let sch_cl_class : string = "";
            if( is_target_appointment == true && eventAtomic.event_data.appointment != null ) {
                switch(eventAtomic.event_data.appointment.appointment_status) {
                    case hodu_doc_enum.appointment_status_code.REQUEST:
                        sch_cl_class = ' ing'; 
                        break;

                    case hodu_doc_enum.appointment_status_code.DECLINE: 
                        sch_cl_class = ' reject';
                        break;

                    case hodu_doc_enum.appointment_status_code.CONFIRM: 
                        sch_cl_class = ' confirmed';
                        break;

                    case hodu_doc_enum.appointment_status_code.RECEIPT:
                        sch_cl_class = ' made';  
                        break;

                    case hodu_doc_enum.appointment_status_code.DIAGNOSIS:
                        sch_cl_class = ' seen';  
                        break;

                    case hodu_doc_enum.appointment_status_code.NOSHOW:
                        sch_cl_class = ' noshow';  
                        break;
                }
            }
            
            let meta_class = "";
            if( eventAtomic.meta != null && eventAtomic.meta['request_move'] != null ) {
                meta_class += `request_move`;
            }

            const title = is_target_appointment == true 
                                ? this.scope_group_team_option.biz_type == GROUP_TYPE.BIZD 
                                    ? (eventAtomic.event_data.appointment != null ? `${eventAtomic.event_data.appointment.patient_name} / ${eventAtomic.event_data.appointment.doctor_name}` : '')
                                    : eventAtomic.event_data.title.substring(5)
                                : eventAtomic.event_data.title;

            const use_time : boolean = this.schedule_time_type != 'NONE' && !is_target_appointment;
            const time = this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(eventAtomic.event_data.schedule_date.start, 'hh:mm a'));

            let el : JQuery<HTMLElement> = $(`<a></a>`);

            el =  $(`
                <a class="fc-day-grid-event fc-h-event fc-event fc-start fc-end fc-draggable ${is_target_appointment == true ? ' hd_list' : ''}" style="border-radius:0px; background-color:#ffffff; border-color:transparent; border-width:0px; color:#202A39">
                    <span class="schCl${sch_cl_class}" style="background :${eventAtomic.event_data.color}">
                        ${ is_target_appointment == true ? eventAtomic.event_data.title.substring(0,5)  : ''}
                    </span>
                    <div class="fc-content"> 
                        ${ !use_time ? '' : `<span class="cal_time ${ this.schedule_time_type == 'END' ? 'right_calTime' : '' }">${time}</span>` }
                        <span class="fc-title ${meta_class} ${ !use_time ? '' : 'titleTime' }" style="color:#202A39">${title}</span>
                    </div>
                </a>
            `);

            if( this.isWork(eventAtomic.event_sub_type ? (eventAtomic.event_sub_type as EVENT_SUB_TYPE) : EVENT_SUB_TYPE.SCHEDULE) ||
                this.isReport(eventAtomic.event_sub_type ? (eventAtomic.event_sub_type as EVENT_SUB_TYPE) : EVENT_SUB_TYPE.SCHEDULE) ||
                this.isMeetingLog(eventAtomic.event_sub_type ? (eventAtomic.event_sub_type as EVENT_SUB_TYPE) : EVENT_SUB_TYPE.SCHEDULE) ) {

                el = $(`
                    <a class="fc-day-grid-event fc-h-event fc-event fc-start fc-end fc-draggable ${is_target_appointment == true ? ' hd_list' : ''}" style="border-radius:0px; background-color:#ffffff; border-color:transparent; border-width:0px; color:#202A39">
                        
                        <div style="width : 3px; height : 13px; position: relative; margin-top: 2px; display: inline-block; float: left;">
                            <span style="display: inline-block; position: absolute; width : 3px !important; height : 3px; background-color : ${eventAtomic.event_data.color}; top : 1px; left : 0; border-radius : 50%;"></span>
                            <span style="display: inline-block; position: absolute; width : 3px !important; height : 3px; background-color : ${eventAtomic.event_data.color}; top : 6px; left : 0; border-radius : 50%;"></span>
                        </div>

                        <div class="fc-content"> 
                            <span class="fc-title ${meta_class}" style="color:#202A39">${title}</span>
                        </div>
                    </a>
                `);
            }

            if( eventAtomic.event_data.approval != null && (eventAtomic.event_data.approval.approval_type == 'VACATION' || eventAtomic.event_data.approval.approval_type == 'BUSINESSTRIP') ) {
                
                let color = "#E4F5FD";
                let bar_color = "#4dbaff";
                if( this.template_map != null ) {
                    const template = this.template_map[`${eventAtomic.group_id}-${eventAtomic.event_data.approval.approval_type == 'VACATION' ? '30' : '40' }`];
                    
                    if( template != null && template.contents != null && 
                        template.contents.color != null && template.contents.color.length > 0 ) {
                        color = template.contents.color;
                        bar_color = template.contents.bar_color;
                    }
                }
                
                el = $(`
                    <a class="fc-day-grid-event fc-h-event fc-event fc-start fc-end fc-draggable" style="border-radius:0px; background-color:#ffffff; border-color:transparent; border-width:0px; color:#202A39">
                        <span class="schCl" style="background : ${bar_color}; height : 15px; margin-top : 0px;"/>
                        <div class="fc-content" style="background : ${color};"> 
                            <span class="fc-title ${meta_class}" style="color:#202A39">${title}</span>
                        </div>
                    </a>
                `);
            }

            return el[0];
        }
        
        // 종일 일정일때
        if( arg.view.type == CALENDAR_TYPE.DAY_GRID_MONTH && arg.event.extendedProps.hasBackground == true ) {
            let meta_class = "";
            if( eventAtomic.meta != null && eventAtomic.meta['request_move'] != null ) {
                meta_class += `request_move`;
            }

            $(arg.el).find('span.fc-title').addClass(meta_class);

            if( eventAtomic.event_data.approval != null && (eventAtomic.event_data.approval.approval_type == 'VACATION' || eventAtomic.event_data.approval.approval_type == 'BUSINESSTRIP') ) {
                // arg.el = $(`
                //     <a class="fc-day-grid-event fc-h-event fc-event fc-start fc-end fc-draggable fc-resizable" style="background-color:#dbf1ff;border-color:#dbf1ff;color:#202A39;">
                //         <span class="schCl" style="background : #4dbaff; height : 17px; margin-top : 0px;"></span>
                //         <div class="fc-content">
                //             <span class="fc-title" style="color:#202A39;">${eventAtomic.event_data.title}</span>
                //         </div>
                //         <div class="fc-resizer fc-end-resizer"></div>
                //     </a>
                // `);

                const element = document.createElement('a');

                element.classList.add('fc-day-grid-event');
                element.classList.add('fc-h-event');
                element.classList.add('fc-event');
                element.classList.add('fc-start');
                element.classList.add('fc-end');
                element.classList.add('fc-draggable');
                element.classList.add('fc-resizable');

                let color = "#E4F5FD";
                let bar_color = "#4dbaff";
                if( this.template_map != null ) {
                    const template = this.template_map[`${eventAtomic.group_id}-${eventAtomic.event_data.approval.approval_type == 'VACATION' ? '30' : '40' }`];
                    
                    if( template != null && template.contents != null && 
                        template.contents.color != null && template.contents.color.length > 0 ) {
                        color = template.contents.color;
                        bar_color = template.contents.bar_color;
                    }
                }

                element.style.backgroundColor = color;
                element.style.borderColor = color;
                element.style.color = "#202A39";

                element.innerHTML = `
                    <span class="schCl" style="background : ${bar_color}; height : 17px; margin-top : 0px;"></span>
                    <div class="fc-content">
                        <span class="fc-title" style="color:#202A39;">${eventAtomic.event_data.title}</span>
                    </div>
                    <div class="fc-resizer fc-end-resizer"></div>
                `;

                arg.el = element;
            }

            return arg.el;
        }
    }

    /**
     * 캘린더에서 일 클릭 - 일정 간편등록 (EventShortCreate)
     */
    async handleDateClick(arg) : Promise<void> {
        
        // 호두 D에서 누른경우 예약 등록 페이지로 이동
        if( this.scope_group_team_option.biz_type == GROUP_TYPE.BIZD ) {
            this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/create`);
            return;
        }

        // 일정 생성 권한이 없다면 return;
        if( this.getScheduleCreatePermission() == false ) {
            return;
        }

        this.calendarGotoDateCss(dateFormat(moment(arg.date).format(), 'yyyy-mm-dd'));
        this.doSetSelectedDate(new Date(moment(arg.date).format()));
        
        const target_date : Date = new Date(moment(arg.date).format());
        target_date.setHours(new Date().getHours());
        target_date.setMinutes(new Date().getMinutes());

        const start_date  : Date = new Date(target_date.getTime());
        const end_date    : Date = new Date(target_date.getTime());

        // 시작 시간이 '정시' 거나 '23시' 일 경우는 그대로 사용한다 
        if( target_date.getMinutes() != 0 && target_date.getHours() != 23 ){
           start_date.setHours(target_date.getHours() + 1);
        }
        
        start_date.setMinutes(0);
        start_date.setSeconds(0);
        start_date.setMilliseconds(0);

        // 시작시간이 23시라면 23시 50분 고정, 아니라면 시작시간 + 1시간에 0분
        if( start_date.getHours() == 23 ){
            end_date.setHours(23);
            end_date.setMinutes(50);
        } else {
            end_date.setHours(start_date.getHours() + 1);
            end_date.setMinutes(0);
        }

        end_date.setSeconds(0);
        end_date.setMilliseconds(0);

        // event 기본 값 설정
        const event : t_event = {
            "audit_created": new Date(),
            "audit_delete_flag": false,
            "audit_deleted": null,
            "audit_modified": new Date(),
            "audit_user_id": this.user_id,
            "calendar_id": this.calendar_id,
            "event_data": {
                "alarm": [],
                "attachment": {
                    "files": [],
                    "imgs": []
                },
                "attend": false,
                "color": "#477FFF",
                "event_owner_group_id": this.scope_team_id > 0 ? 0 : this.scope_group_id,
                "event_owner_group_name": this.scope_team_id > 0 ? "" : this.scope_group_team_option.group_team_name,
                "event_owner_id": this.user_id,
                "event_owner_name": this.user_name,
                "event_owner_team_id": this.scope_team_id,
                "event_owner_team_name": this.scope_team_id > 0 ? this.scope_group_team_option.group_team_name : "",
                "event_push_yn": true,
                "event_sub_type": EVENT_SUB_TYPE.SCHEDULE,
                "location": [],
                "memo": "",
                "note": "",
                "percent_complete": 0,
                "priority": 0,
                "schedule_date": {
                    "end": end_date,
                    "isAllDay": false,
                    "isContinuos": false,
                    "isIgnore": false,
                    "lunar_yn": false,
                    "recurrence_end": end_date,
                    "start":  start_date
                },
                "contacts" : [{
                    "name" : "",
                    "tel" : ""
                }],
                "status": "",
                "title": "",
                "uid": "",
                "version": "1",
                "vote": [],
                "is_private": false,
            },
            "event_id": "",
            "event_type": this.scope,
            "event_sub_type": EVENT_SUB_TYPE.SCHEDULE,
            "user_id": this.scope == OWNER_TYPE.PERSONAL ? this.user_id : 0,
            "team_id": this.scope == OWNER_TYPE.TEAM ? this.scope_team_id : 0,
            "group_id": this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : 0,
            "subscribe_users": [],
            "subscribe_groups": [],
            "subscribe_teams": [],
            "duration" : "",
        }

        // alert(JSON.stringify(event));
        
        // EventInfo에 이벤트 등록
        this.doSetEvent(event);
        this.doSetEventCrudType(CRUD_TYPE.CREATE);
        this.doSetEventShareInfo({
            share_option : SHARE_OPTION.SHARE,
            user_ids : [],
            group_ids : [],
            team_ids : [],
            group_user_ids : [],
            team_user_ids : []
        });

        // 일정 간편 등록 Modal 띄우기
        this.doSetShowEventShortCreate(true);

    }

    /**
     * 일정 클릭 - 일정 조회 (Event.vue)
     */
    async handleEventClick(arg) : Promise<void> {
        // alert(arg.event.id + "\n" + arg.el.innerText);

        const eventAtomic : t_event = arg.event.extendedProps.eventAtomic

        if( eventAtomic.event_data.approval && eventAtomic.event_data.approval.approval_uid && eventAtomic.event_data.approval.approval_uid.length > 0 ) {
            
            const approver : number[] = Array.from(eventAtomic.event_data.approval.approver ? eventAtomic.event_data.approval.approver : [], x => x.user_id);
            const receiver : number[] = Array.from(eventAtomic.event_data.approval.receive_reference ? eventAtomic.event_data.approval.receive_reference : [], x => x.user_id);
            const approval_user_id = eventAtomic.event_data.approval.user_id;
            
            // 작성자, 결재자, 수신참조만 기안서 화면으로 이동
            if( eventAtomic.event_data.event_owner_id == this.user_id || approval_user_id == this.user_id ||
                approver.indexOf(this.user_id) > -1 || receiver.indexOf(this.user_id) > -1 ) {
                this.hodu_router_push(`/GROUP/${eventAtomic.group_id}/approval/${eventAtomic.event_data.approval.approval_uid}`);
            }

            return;
        }

        // 병원 예약인 경우
        if( eventAtomic.event_sub_type == "APPOINTMENT" ) {
            
            // 개인 달력에서 본인이 예약한 것을 클릭 한 경우 예약 상세 페이지로 이동
            if( this.scope == OWNER_TYPE.PERSONAL && eventAtomic.event_data.event_owner_id == this.user_id ) {
                if( !this.doSetAppointmentDetailInfo ) { return; }
                this.doSetAppointmentDetailInfo({
                    event : eventAtomic,
                    is_patient : true,
                })
                this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/${eventAtomic.event_id}`);
                return;
            }

            // 병원 예약인 이벤트만 && 누른 날짜랑 동일한 이벤트만 필터해서 가져온다
            const year = new Date(eventAtomic.event_data.schedule_date.start).getFullYear();
            const month = new Date(eventAtomic.event_data.schedule_date.start).getMonth();
            const date = new Date(eventAtomic.event_data.schedule_date.start).getDate();

            const lo_events : any[] = this.events.filter(item => item.eventAtomic != null &&
                                                                 item.eventAtomic.event_data != null &&
                                                                 item.eventAtomic.event_data.schedule_date != null &&
                                                                 item.eventAtomic.event_sub_type == EVENT_SUB_TYPE.APPOINTMENT && 
                                                                 new Date(item.eventAtomic.event_data.schedule_date.start).getFullYear() == year  &&
                                                                 new Date(item.eventAtomic.event_data.schedule_date.start).getMonth() == month &&
                                                                 new Date(item.eventAtomic.event_data.schedule_date.start).getDate() == date);


            // 해당 일자에 예약이 하나 뿐인데 그것을 클릭한것이라면 바로 이동?
            if( lo_events.length == 1 ) {
                
                const lo_eventAtomic = lo_events[0].eventAtomic;
                const status : hodu_doc_enum.appointment_status_code = lo_eventAtomic.event_data.appointment.appointment_status;

                // 요청, 거절은 AppointmentList.vue
                if( status == hodu_doc_enum.appointment_status_code.REQUEST || status == hodu_doc_enum.appointment_status_code.DECLINE ) {
                    this.hodu_router_push(`/hospital/${new Date(lo_eventAtomic.event_data.schedule_date.start).getTime()}/appointment`);
                    return;
                }

                // 노쇼, 확정, 접수, 진료는 Appointment.vue
                if( this.doSetAppointmentDetailInfo ) {
                    this.doSetAppointmentDetailInfo({ "is_patient" : false, "event" : lo_eventAtomic });
                    this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/${lo_eventAtomic.event_id}`);
                }
                return;
            }

            const filterd_events : t_event[] = [];
            for ( const lo_event of lo_events ) { filterd_events.push(lo_event.eventAtomic); }

            // 의사 (HODU_D 관리자, 부관리자)가 클릭한 경우
            if( this.doSetAppointmentListModalInfo ) { this.doSetAppointmentListModalInfo({ "show_modal" : true, "events" : filterd_events }); }
            return;
        }

        // 반복 일정의 날짜 데이터 설정
        if( eventAtomic.event_data.schedule_date.rrule != null && eventAtomic.event_data.schedule_date.lunar_yn == false ) {
            // 해당 반복일정의 첫 일정인지 구하기
            const dtStart : Date = new Date(moment(eventAtomic.event_data.schedule_date.start).format());
            
            // DTSTART랑 UNTIL이 이미 들어있다면 제거후 재등록
            let rrule_string : string = eventAtomic.event_data.schedule_date.rrule;
            if( rrule_string.indexOf(';UNTIL') > -1 ) {
                rrule_string = rrule_string.substring(0, rrule_string.indexOf(';UNTIL'));
            }
            if( rrule_string.indexOf('FREQ') > - 1 ) {
                rrule_string = rrule_string.substring(rrule_string.indexOf('FREQ'));
            }
            eventAtomic.event_data.schedule_date.rrule = rrule_string;

            const rrule : RRule | RRuleSet = rrulestr(`DTSTART:${this.formatDateForRruleDTSTARTByUTC(dtStart)}\nRRULE:${eventAtomic.event_data.schedule_date.rrule};UNTIL=${this.formatDateForRruleUNTILByUTC(new Date(moment(eventAtomic.event_data.schedule_date.recurrence_end).format()))}`);
            // console.log(rrule.all()[0]);
            // console.log(new Date(moment(arg.event.start).format()));

            const repeat_event_dates = rrule.all();
            const event_start = moment(arg.event.start);

            // 해당 반복일정 조각이 반복일정의 첫 조각인지 판단
            this.doSetIsFirstRepeatEvent( arg.event.extendedProps.isFirstEvent && (repeat_event_dates[0].getTime() == new Date(event_start.format()).getTime() ) );

            // 일정의 원래 날짜 store EventInfo에 등록
            this.doSetEventOriginalDate({
                original_start : new Date(moment(eventAtomic.event_data.schedule_date.start).format()),
                original_end : new Date(moment(eventAtomic.event_data.schedule_date.end).format())
            });

            // const start_date : Date = new Date(moment(arg.event.start).format());
            // start_date.setHours(new Date(moment(eventAtomic.event_data.schedule_date.start).format()).getHours());
            // start_date.setMinutes(new Date(moment(eventAtomic.event_data.schedule_date.start).format()).getMinutes());
            // eventAtomic.event_data.schedule_date.start = start_date; // ATOMIC START

            // const end_date : Date = new Date(moment(arg.event.end).format());
            // end_date.setHours(new Date(moment(eventAtomic.event_data.schedule_date.end).format()).getHours());
            // end_date.setMinutes(new Date(moment(eventAtomic.event_data.schedule_date.end).format()).getMinutes());
            // eventAtomic.event_data.schedule_date.end = end_date;   // ATOMIC END

        }
        
        // 음력 반복 일정
        else if ( eventAtomic.event_data.schedule_date.rrule != null && eventAtomic.event_data.schedule_date.lunar_yn == true ) {
            
            // if( !eventAtomic.event_data.schedule_date.lunar_start || !this.activeStart ) { 
            //     alert("데이터 오류");
            //     return; 
            // }

            // // 해당 달력의 1일 기준의 날짜의 연도를 세팅 해준다
            // const target_year_date : Date = new Date(this.activeStart);
            // while( target_year_date.getDate() != 1 ) {
            //     target_year_date.setDate(target_year_date.getDate() + 1 );
            // }
            
            // const lunar_arr : string[] = eventAtomic.event_data.schedule_date.lunar_start.split('-');

            // // 윤달 여부 체크
            // const intercalation_obj : any = await this.hodu_is_intercalation(eventAtomic.event_data.schedule_date.start);

            // let solar_date : string = "";

            // // 윤달인 경우
            // if( intercalation_obj.is_intercalation == true ) {

            //     let is_search_target_year : boolean = false;
            //     for( const next_date of intercalation_obj.next_date_obj ) {
            //         if( next_date.solar_ymd.substr(0, 4) == `${target_year_date.getFullYear()}` ) {
            //             solar_date = next_date.solar_ymd.substring(0, 4) + '-' + next_date.solar_ymd.substring(4, 2) + '-' + next_date.solar_ymd.substring(6, 2);
            //             is_search_target_year = true;
            //             break;
            //         }
            //     }

            //     // 현재 보고 있는 달력기준의 연도에 윤달 음력을 찾지 못한경우 잘못된 데이터다 
            //     if( is_search_target_year == false ) { 
            //         alert("데이터 오류");
            //         return; 
            //     } 
            // }

            // // 윤달이 아닌 경우
            // else {
            //     const target_lunar_date : string  = `${target_year_date.getFullYear()}${lunar_arr[1]}${lunar_arr[2]}`;
            //     solar_date = moment(await this.hodu_lunar_to_solar(target_lunar_date, false)).format('YYYY-MM-DD');
            // }

            // const solar_end_date : Date = new Date(solar_date);
            // solar_end_date.setHours(23);
            // solar_end_date.setMinutes(59);
            // solar_end_date.setSeconds(59);
            // solar_end_date.setMilliseconds(999);

            // 일정의 원래 날짜 store EventInfo에 등록
            this.doSetEventOriginalDate({
                original_start : new Date(moment(eventAtomic.event_data.schedule_date.start).format()),
                original_end : new Date(moment(eventAtomic.event_data.schedule_date.end).format())
            });
            
        }

        const start_date : Date = new Date(moment(arg.event.start).format());
        start_date.setHours(new Date(moment(eventAtomic.event_data.schedule_date.start).format()).getHours());
        start_date.setMinutes(new Date(moment(eventAtomic.event_data.schedule_date.start).format()).getMinutes());
        eventAtomic.event_data.schedule_date.start = start_date; // ATOMIC START
        
        const end_date : Date = new Date(moment(arg.event.end ? arg.event.end : arg.event.start).format());
        if( arg.event.allDay == true && (eventAtomic.event_data.schedule_date.isAllDay == true || eventAtomic.event_data.schedule_date.isContinuos == true) ) {
            end_date.setSeconds(-1);

            // 반복일정 왠지모르지만 종일이면 종료일 1일이 더 추가되는 문제 있음
            // if( eventAtomic.event_data.schedule_date.rrule != null && eventAtomic.event_data.schedule_date.rrule.length > 0 ) {
            //     end_date.setDate(end_date.getDate() - 1);
            // }
            
        }
        end_date.setHours(new Date(moment(eventAtomic.event_data.schedule_date.end).format()).getHours());
        end_date.setMinutes(new Date(moment(eventAtomic.event_data.schedule_date.end).format()).getMinutes());
        eventAtomic.event_data.schedule_date.end = end_date;   // ATOMIC END

        // EventInfo에 이벤트 등록
        this.doSetEvent(eventAtomic);
        this.doSetEventCrudType(CRUD_TYPE.READ);
        this.doSetEventShareInfo({
            share_option : SHARE_OPTION.SHARE,
            user_ids : [],
            group_ids : [],
            team_ids : [],
            group_user_ids : [],
            team_user_ids : []
        });

        // 일정 등록 페이지로 이동 
        this.hodu_router_push(`/event/${ eventAtomic.event_id }`);
    }
    
    /**
     * 날짜 렌더링
     */
    async handleDatesRender(arg) : Promise<void> {
        this.waited_render_arg = arg;
        
        // mount가 되기 전까지는 arg를 받고 대기한다
        if( this.is_calendar_mounted == false ) {
            return; 
        }
        
        const vue = this;

        // handleDatesRender에 현재 보고 있는 날짜와 같은 날짜가 들어올때 리턴
        const arg_active_start     : Date        = arg.view.activeStart instanceof Date ? arg.view.activeStart : new Date(arg.view.activeStart);
        const current_active_start : Date | null = this.activeStart     instanceof Date ? this.activeStart     : null;
        if( current_active_start != null && current_active_start.getTime() == arg_active_start.getTime() ) { return; }

        this.activeStart = arg.view.activeStart;
        this.doSetStartDate(moment(arg.view.currentStart).format('YYYY-MM-DD').toString());
        this.activeEnd   = arg.view.activeEnd;

        this.calendarResize();

        // 해당 달의 1일로 세팅
        const current_month : Date = new Date(arg.view.activeStart);
        if( this.calendar_view_type == CALENDAR_TYPE.DAY_GRID_MONTH && current_month.getDate() != 1 ) {
            current_month.setDate(1);
            current_month.setMonth(current_month.getMonth() + 1);
        }
        $('#hMonthPrt').html(`<input type="text" class="forDatePick" value="${moment(current_month).format("YYYY-MM-DD")}"/>${arg.view.title}`);
        $('#fc-print-title').text(moment(current_month).format("YYYY.MM"));

        // week에 해당 월이 5주인지 6주인지 써준다
        this.$nextTick(() => {
            
            $('.fc-body .fc-day-grid-container .fc-week').removeClass('row-5');
            $('.fc-body .fc-day-grid-container .fc-week').removeClass('row-6');
            $('.fc-body .fc-day-grid-container .fc-week').addClass(`row-${$('.fc-body .fc-day-grid-container .fc-week').length}`); 
            
            for( let i = 1; i <= 3; i++ ) {
                setTimeout(() => { 
                    $('.fc-body .fc-day-grid-container .fc-week').addClass(`row-${$('.fc-body .fc-day-grid-container .fc-week').length}`); 
                }, i * 1000);
            }

        });

        setTimeout(() => {

            // datepicker option
            let option = {
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: function (dateText, inst) {

                    vue.doSetSelectedDate(new Date(dateText));
                    vue.$refs.fullCalendar.getApi().gotoDate(new Date(dateText));

                    // @ts-ignore
                    $('#datepicker').datepicker('setDate', new Date(moment(vue.selected_date).format()));
                    vue.calendarGotoDateCss(dateFormat(vue.selected_date, 'yyyy-mm-dd'));
                },
            };

            // @ts-ignore
            $('.forDatePick').datepicker(option);
        }, 100);

        // 일정 읽기 권한이 없다면 달력 조회가 불가능하다
        if( this.getScheduleReadPermission() == false ) { return; }

        // 일정 비우기
        this.events.splice(0,this.events.length);

        this.$nextTick(async() => {

            if( this.is_first_init == false ) {                
                
                Promise.all([this.get_group_role_service(), this.getFilterInfo()]).then(() => {

                    this.calendarGotoDateCss(dateFormat(new Date(moment(this.start_date).format()).getMonth() == new Date().getMonth() ? new Date() : new Date(moment(this.start_date).format()), 'yyyy-mm-dd'));

                    this.is_first_init = true;

                    // 첫 조회는 필터정보 제대로 적용 되기를 바라면서 지연시간 주기
                    setTimeout(async() => {

                        Promise.all([this.getEvents(), this.getLunar(arg.view.activeStart, arg.view.activeEnd), this.getHolidays(arg.view.activeStart, arg.view.activeEnd)]).then(async() => {
                            await this.renderLunarHoliday();
                        });

                    }, 1000);

                    
                });
                return;
            }
            
            Promise.all([this.getEvents(), this.getLunar(arg.view.activeStart, arg.view.activeEnd), this.getHolidays(arg.view.activeStart, arg.view.activeEnd)]).then(async() => {
                this.renderLunarHoliday();
            });
            
        });            

    }

    /**
     * 음력 구하기
     */
    async getLunar(start : Date, end : Date) : Promise<void> {
        start = start instanceof Date ? start : new Date(moment(start).format());
        end   = end   instanceof Date ? end   : new Date(moment(end).format());

        const lunar_objs : any[] = await this.hodu_solars_to_lunars(start, end);
        
        for( let lunar_obj of lunar_objs ) {
            this.lunar_date.set(lunar_obj.solar_ymd, lunar_obj.lunar_ymd);
        }
    }

    /**
     * 공휴일 구하기
     */
    async getHolidays(start : Date, end : Date) : Promise<void> {
        const solar_start : Date = start instanceof Date ? start : new Date(moment(start).format());
        const solar_end   : Date = end   instanceof Date ? end   : new Date(moment(end).format());

        const holi_days : any[] = await this.hodu_get_holidays(solar_start, solar_end);

        // 공휴일 Map 생성
        for( let holi_day of holi_days ) {
            this.holidays.set(holi_day.solar_ymd, holi_day);

            // add_before 처리
            for( let before_day = 1; before_day <= holi_day.add_before; before_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_before : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // before 만큼 날짜 이전으로
                target_date.setDate(target_date.getDate() - before_day);

                // object 및 map에 세팅
                holi_day_before.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_before.solar_ymd, holi_day_before);
            }

            // add_after 처리
            for( let after_day = 1; after_day <= holi_day.add_after; after_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_after : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // after 만큼 날짜 이후로
                target_date.setDate(target_date.getDate() + after_day);

                // object 및 map에 세팅
                holi_day_after.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_after.solar_ymd, holi_day_after);
            }
        }

    }

    /**
     * daygrid 음력, 공휴일 렌더링
     */
    async dayGridLunarAndHolidays() : Promise<void> {
        const vue = this;

        const day_count : number = $('.fc-day-grid').find('.fc-day-number').length;
        
        // console.log(day_count);

        for( let i = 0; i < day_count; i++ ) {
            const html_element : HTMLElement = $('.fc-day-grid').find('.fc-day-number')[i];
            
            // fc-special-name가 이미 있다면 제거함
            const special_name_count = $(html_element).parent().find('span.fc-special-name').length;
            if( special_name_count > 0 ) {
                $(html_element).parent().find('span.fc-special-name').remove();
                $(html_element).parent().find('span.day-num-other').remove();
            }

            const this_date : Date = new Date(moment($(html_element).parent().data('date')).format());
            const this_date_string : string = moment(this_date).format('YYYY-MM-DD');
            
            const lunar : string | undefined = this.lunar_date.get(moment(this_date).format('YYYY-MM-DD'));
            // const lunar_string : string = lunar ? lunar : moment(this_date).lunar().format();

            const holidays : any | undefined = this.holidays.get(moment(this_date).format('YYYY-MM-DD'));
            
            // 해당하는 날짜가 아닌경우 continue
            const date = $(html_element).parent().data('date');
            if( this_date_string != date ) {
                continue;
            }
            
            // 공휴일인 경우 parent에 fc-special 클래스 추가
            if( holidays ) { $(html_element).parent().addClass('fc-special'); }

            // moment.format으로 하면 2월 30일이 Invalid Date로 나오기 때문에 텍스트 그대로 잘라서 사용한다
            const lunar_ymd : string[] = lunar ? lunar.split('-') : [];
            const lunar_text : string = lunar_ymd.length > 1 ? `${lunar_ymd[1]}.${lunar_ymd[2]}` : ''; 
            $(html_element).after(`<span class="fc-special-name">${ holidays ? holidays.holi_name : '' }</span><span class="day-num-other">${ this_date.getDay() == 0 ? lunar_text : '' } </span> `);
        }

    }

    /**
     * timeGrid 음력, 공휴일 렌더링
     */
    async timeGridLunarAndHolidays(view_type : string) : Promise<void> {
        
        // 주달력
        if( view_type == CALENDAR_TYPE.TIME_GRID_WEEK ) {
            await this.timeGridWeekLunarAndHolidays();
        } 
        
        // 일달력
        else if ( view_type == CALENDAR_TYPE.TIME_GRID_DAY ) {
            await this.timeGridDayLunarAndHolidays();
        }
    }

    /**
     * timeGridWeek(주달력) 음력, 공휴일 렌더링
     */
    async timeGridWeekLunarAndHolidays() : Promise<void> {
    
        // 일자별로 처리
        const day_count : number = $('.fc-timeGridWeek-view .fc-head .fc-row').find('.fc-day-header').length;
        for( let i = 0; i < day_count; i++ ) {
            const html_element : HTMLElement = $('.fc-timeGridWeek-view .fc-head .fc-row').find('.fc-day-header')[i];

            const this_date : Date = new Date(moment($(html_element).data('date')).format());
            
            // 일요일의 음력만 구한다
            if( i == 0 ) {
                const lunar : string | undefined = this.lunar_date.get(moment(this_date).format('YYYY-MM-DD'));
                const lunar_string : string = lunar ? lunar : moment(this_date).lunar().format();

                // moment.format으로 하면 2월 30일이 Invalid Date로 나오기 때문에 텍스트 그대로 잘라서 사용한다
                const lunar_ymd : string[] = lunar_string.split('-');
                const lunar_text : string = lunar_ymd.length > 1 ? `${lunar_ymd[1]}.${lunar_ymd[2]}` : ''; 
                $('.fc-left #hMonthPrt .fc-special-name').text(`(${lunar_text})`);
            }

            const holidays : any | undefined = this.holidays.get(moment(this_date).format('YYYY-MM-DD'));
            if( holidays ) {
                $(html_element).addClass('fc-special');
                $(html_element).find('.fc-special-name').text(holidays.holi_name);
            }
        }
    }

    /**
     * timeGridDay(일달력) 음력, 공휴일 렌더링
     */
    async timeGridDayLunarAndHolidays() : Promise<void> {
        if( $('.fc-timeGridDay-view .fc-head .fc-row .fc-day-header').length < 1 ) {
            return;
        }

        const html_element : HTMLElement = $('.fc-timeGridDay-view .fc-head .fc-row .fc-day-header')[0];
        const this_date : Date = new Date(moment($(html_element).data('date')).format());

        // 일요일일때만 음력 추가
        if( this_date.getDay() == 0 ) {
            const lunar : string | undefined = this.lunar_date.get(moment(this_date).format('YYYY-MM-DD'));
            const lunar_string : string = lunar ? lunar : moment(this_date).lunar().format();

            // moment.format으로 하면 2월 30일이 Invalid Date로 나오기 때문에 텍스트 그대로 잘라서 사용한다
            const lunar_ymd : string[] = lunar_string.split('-');
            const lunar_text : string = lunar_ymd.length > 1 ? `${lunar_ymd[1]}.${lunar_ymd[2]}` : ''; 
            $(html_element).find('.day-num-other').text(lunar_text);
        }

        // 해당일이 공휴일일때 추가
        const holidays : any | undefined = this.holidays.get(moment(this_date).format('YYYY-MM-DD'));
        if( holidays ) {
            $(html_element).find('.fc-special-name').text(holidays.holi_name);
        }

    }

    /**
     * 일정 가져오기 (해당 달력에서 보이는 첫날 ~ 마지막날 )
     */
    async getEvents() : Promise<void> {

        if( this.is_first_init == false ) return;

        const temp_event : any[] = [];
        const temp_promise : Promise<any>[] = [];

        let owner_type  : string = this.scope;
        let owner_id    : number = this.scope == OWNER_TYPE.PERSONAL ? this.user_id :
                                   this.scope == OWNER_TYPE.GROUP    ? this.scope_group_id : this.scope_team_id;

        let calendar_id : string = this.calendar_id;

        // 과거일정 안보이도록했다면 && 현재 날짜가 달력 시작일보다 큰 경우에만 당일 0시 0분 0초 사용
        const target_date : Date = this.activeStart == null || this.schedule_search_config.past_schedule == false && (this.getNewDateForCompare()).getTime() > this.activeStart.getTime() 
                                    ? this.getNewDateForCompare()
                                    : this.activeStart;

        // 중복 호출 시 기존 함수 실행 취소용
        const search_tag : number = this.activeStart ? this.activeStart.getTime() : 0;

        // 지난 일정 안보기 중일때 target_date가 activeEnd보다 나중의 날짜일때 events를 비우고 그대로 나간다
        if( this.schedule_search_config.past_schedule == false && this.activeEnd != null && target_date.getTime() > this.activeEnd.getTime() ){
            this.events = [];
            return;
        }
        
        const start_query : string = moment(target_date).utc().format();
        
        const end_query_date : Date = new Date(this.activeEnd ? new Date(moment(this.activeEnd).format()) : new Date());
        end_query_date.setDate(end_query_date.getDate() - 1);
        end_query_date.setHours(23);
        end_query_date.setMinutes(59);
        end_query_date.setSeconds(59);
        end_query_date.setMilliseconds(999);

        const end_query : string = moment(end_query_date).utc().format();

        let query : string = `?start=${start_query}&end=${end_query}&mycal=${ this.scope == OWNER_TYPE.PERSONAL ? this.schedule_search_config.my_schedule : false }&shared=${ this.scope == OWNER_TYPE.PERSONAL ? this.schedule_search_config.shared_schedule : false }&syscal=${ this.scope == OWNER_TYPE.PERSONAL ? this.schedule_search_config.system_calendar : false }`;

        let groups : number[] = [];
        let teams : number[] = [];

        // groups 쿼리 생성
        if( this.user_group_role != null && this.user_group_role.length > 0 ){
            const role_size : number = this.user_group_role.length;
            for( let i = 0; i < role_size; i++ ) {
                
                if ( this.user_group_role[i].group_id == null || this.user_group_role[i].group_id < 1 ) {
                    continue
                }

                groups.push(this.user_group_role[i].group_id);
                    
                // 개인 달력일때 그룹 필터에 해당 그룹 아이디가 존재한다면 다음순서로
                if( this.scope == OWNER_TYPE.PERSONAL && this.schedule_search_config.group_filter.indexOf(Number(this.user_group_role[i].group_id)) != -1 ){
                    continue;
                }

                // PERSONAL이 아니라면 해당 그룹의 정보만 추가한다
                if( this.scope != OWNER_TYPE.PERSONAL ) {
                    if( this.scope_group_id > 0 && this.scope_group_id != Number(this.user_group_role[i].group_id) ) {
                        continue;
                    }

                    let biz_id : string | null = null;
                    let hodu_c_group_length : number = this.hodu_c_group_data.length;
                    for( let j = 0; j < hodu_c_group_length; j++ ) {
                        if( this.hodu_c_group_data[j].group_id == Number(this.user_group_role[i].group_id) ) {
                            biz_id = this.hodu_c_group_data[j].biz_id;
                            break;
                        }
                    }

                    // 판독한 그룹이 biz그룹이 아니라면 || 현재 보고 있는 달력의 비즈 그룹이 아니라면 continue;
                    if( biz_id == null || biz_id != this.scope_group_team_option.biz_id ) {
                        continue;
                    }

                    // BIZ_ID로 필터를 뽑아낸다
                    let filter : GroupAndTeamFilter | null = null;
                    const filter_length : number = this.schedule_search_config.group_and_team_filter.length;
                    for( let j = 0; j < filter_length; j++ ) {
                        if( this.schedule_search_config.group_and_team_filter[j].biz_id == biz_id &&
                            this.schedule_search_config.group_and_team_filter[j].scope == this.scope &&
                            this.schedule_search_config.group_and_team_filter[j].scope_id == (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id) ) {
                            filter = this.schedule_search_config.group_and_team_filter[j];
                        }
                    }

                    // 필터에 들어있다면 건너뛴다
                    if( filter != null && filter.group_id == Number(this.user_group_role[i].group_id) ) {
                        continue;
                    }
                }

                query += `&groups=${Number(this.user_group_role[i].group_id)}`;
            }
        }

        // teams 쿼리 생성
        if( this.user_team_role != null && this.user_team_role.length > 0 ){
            const role_size : number = this.user_team_role.length;
            for( let i = 0; i < role_size; i++ ){
                
                if( this.user_team_role[i].team_id == null || this.user_team_role[i].team_id < 1 ){
                    continue
                }

                teams.push(this.user_team_role[i].team_id);
                    
                // 팀 필터에 해당 팀 아이디가 존재한다면 다음순서로
                if( this.scope == OWNER_TYPE.PERSONAL && this.schedule_search_config.team_filter.indexOf(Number(this.user_team_role[i].team_id)) != -1 ){
                    continue;
                }

                // PERSONAL이 아니라면 해당 팀의 정보만 추가한다
                if( this.scope != OWNER_TYPE.PERSONAL ) {
                    if( this.scope_team_id > 0 && this.scope_team_id != Number(this.user_team_role[i].team_id) ) {
                        continue;
                    }

                    let biz_id : string | null = null;
                    let hodu_c_group_length : number = this.hodu_c_group_data.length;
                    for( let j = 0; j < hodu_c_group_length; j++ ) {
                        const team_length : number = this.hodu_c_group_data[j].teams.length;
                        for( let k = 0; k < team_length; k++ ) {
                            if( this.hodu_c_group_data[j].teams[k].team_id == Number(this.user_team_role[i].team_id) ) {
                                biz_id = this.hodu_c_group_data[j].biz_id;
                                break;
                            }
                        }

                        // 비즈 아이디를 찾았다면 break;
                        if( biz_id != null ) {
                            break;
                        }
                    }

                    // 판독한 팀이 biz팀이 아니라면 || 현재 보고 있는 달력의 비즈 팀이 아니라면 continue;
                    if( biz_id == null || biz_id != this.scope_group_team_option.biz_id ) {
                        continue;
                    }

                    // BIZ_ID로 필터를 뽑아낸다
                    let filter : GroupAndTeamFilter | null = null;
                    const filter_length : number = this.schedule_search_config.group_and_team_filter.length;
                    for( let j = 0; j < filter_length; j++ ) {
                        if( this.schedule_search_config.group_and_team_filter[j].biz_id == biz_id &&
                            this.schedule_search_config.group_and_team_filter[j].scope == this.scope &&
                            this.schedule_search_config.group_and_team_filter[j].scope_id == (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id) ) {
                            filter = this.schedule_search_config.group_and_team_filter[j];
                        }
                    }

                    // 필터에 들어있다면 건너뛴다
                    if( filter != null && filter.team_ids.indexOf(Number(this.user_team_role[i].team_id)) > -1 ) {
                        continue;
                    }
                }

                query += `&teams=${Number(this.user_team_role[i].team_id)}`;
            }
        }
        
        // 병원 예약 - 일반 달력인 경우
        if( this.hodu_d_group_data.length > 0 && this.scope == OWNER_TYPE.PERSONAL ) {
            const hopital_count : number = this.hodu_d_group_data.length;
            for( let i = 0; i < hopital_count; i++ ) {
                const doctor_count : number = this.hodu_d_group_data[i].teams.length;
                for( let j = 0; j < doctor_count; j++ ) {
                    const doctor_key : string = `${this.hodu_d_group_data[i].teams[j].biz_id}___${this.hodu_d_group_data[i].teams[j].department_code}___${this.hodu_d_group_data[i].teams[j].doctor_code}`; 

                    // 필터에 들어 있는 경우 건너 뜀
                    if( this.schedule_search_config.hodu_d_filter.indexOf(doctor_key) > -1 ) {
                        continue;
                    }

                    query += `&doctors=${doctor_key}`;
                }
            }
        }

        // 병원 예약 - 호두 D 달력인 경우
        if( this.scope_group_team_option.biz_type == GROUP_TYPE.BIZD && this.scope_group_team_option.biz_id != null && this.scope_group_team_option.biz_id.length > 0 ) {
            query += `&biz_type=${this.scope_group_team_option.biz_type}&biz_id=${this.scope_group_team_option.biz_id}`;

            const group_appointment_filter_count : number = this.schedule_search_config.group_appointment_filter.length;
            for( const doctor_key of this.schedule_search_config.group_appointment_filter ) {
                query += `&doctors=${doctor_key}`;
            }
        }
        
        await this.hodu_api_call(`/api/v1/calendars/${calendar_id}/events/${owner_type}/${owner_id}${query}`, API_METHOD.GET, undefined, false)
            .then(async(response) => {
                
                // 검색하던 기준이 현재 기준과 다르다면 return 
                if( search_tag != (this.activeStart ? this.activeStart.getTime() : 0)) {
                    return;
                }
                
                let event_array : t_event[] = response.data.data.events;
                // this.events.splice(0, this.events.length);

                // 이벤트JSON -> 달력 이벤트 객체
                const event_count : number = event_array.length;
                for( let i : number = 0; i < event_count; i++ ) {
                    let event : t_event = event_array[i];

                    // event 데이터가 없거나 event_id가 이상한 경우 건너 뛴다
                    if( event == null || event.event_id == null || event.event_id.length < 1 ) { continue; }

                    // 일정 비공개 여부 체크
                    if( event.event_data.is_private == true ) {

                        // 권한 구하기
                        let is_permitted : boolean = false;
                        if( event.team_id > 0 ) {
                            if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                                is_permitted = this.is_team_permmision(event.team_id, "event", "private_read");
                            }
                            else if( this.isMeetingLog(event.event_sub_type) ) {
                                is_permitted = this.is_team_permmision(event.team_id, "meetinglog", "private_read");
                            }
                            else if( this.isReport(event.event_sub_type) ) {
                                is_permitted = this.is_team_permmision(event.team_id, "report", "private_read");
                            }
                            else if( this.isWork(event.event_sub_type) ) {
                                is_permitted = this.is_team_permmision(event.team_id, "work", "private_read");
                            }
                        }
                        else if( event.group_id > 0 ) {
                            if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                                is_permitted = this.is_group_permmision(event.group_id, "event", "private_read");
                            }
                            else if( this.isMeetingLog(event.event_sub_type) ) {
                                is_permitted = this.is_group_permmision(event.group_id, "meetinglog", "private_read");
                            }
                            else if( this.isReport(event.event_sub_type) ) {
                                is_permitted = this.is_group_permmision(event.group_id, "report", "private_read");
                            }
                            else if( this.isWork(event.event_sub_type) ) {
                                is_permitted = this.is_group_permmision(event.group_id, "work", "private_read");
                            }
                        }

                        // 숨김일때 본인 작성이 아닌 경우 && 특정인 지정 열람 권한도 없는경우
                        if( event.event_data.event_owner_id != this.user_id && is_permitted == false ) {
                            
                            // 프로젝트인 경우 assignment_type == 'ASSIGN' 인데 담당자가 아니라면 건너 뛴다
                            if( event.event_sub_type == 'WORK' && event.event_data.work != null && event.event_data.work.assignment_type == 'ASSIGN' && 
                                (event.event_data.work.assign_user_ids == null || event.event_data.work.assign_user_ids.filter(user_id => user_id == this.user_id).length < 1) ) {
                                continue;
                            }

                            if( event.event_sub_type != 'WORK' ) {

                                // 프로젝트가 아니라면 공유 받은거만
                                let is_shared = false;

                                if( event.subscribe_users != null ) {
                                    is_shared = event.subscribe_users.indexOf(this.user_id) > -1; 
                                }

                                if( event.subscribe_groups != null ) {
                                    for( const group_id of event.subscribe_groups ) {
                                        if( groups.indexOf(group_id) > -1 ) {
                                            is_shared = true;
                                            break;
                                        }
                                    }
                                }

                                if( event.subscribe_teams != null ) {
                                    for( const team_id of event.subscribe_teams ) {
                                        if( teams.indexOf(team_id) > -1 ) {
                                            is_shared = true;
                                            break;
                                        }
                                    }
                                }

                                if( is_shared == false ) continue;
                            }
                        }

                    }

                    // 일정 - 일정 필터가 꺼진경우
                    // 프로젝트(업무) - 프로젝트(업무) 필터가 꺼진경우
                    // 업무일지 - 업무일지 필터가 꺼진경우
                    // 회의록 - 회의록 필터가 꺼진경우
                    // 휴가원 - 휴가원 필터가 꺼진경우
                    // 출장신청서 - 출장신청서 필터가 꺼진경우
                    // 일정 가공하지 않고 넘어가도록
                    if( !(event.event_data.approval != null && (event.event_data.approval.approval_type == 'VACATION' || event.event_data.approval.approval_type == 'BUSINESSTRIP')) && 
                        (event.event_sub_type == EVENT_SUB_TYPE.SCHEDULE || event.event_sub_type == EVENT_SUB_TYPE.CARD) && (local_storage_info.filter_info ?? { basic : true }).basic == false || 
                        event.event_sub_type == EVENT_SUB_TYPE.WORK && (local_storage_info.filter_info ?? { work : true }).work == false ||
                        event.event_sub_type == EVENT_SUB_TYPE.REPORT && (local_storage_info.filter_info ?? { report : true }).report == false ||
                        event.event_sub_type == EVENT_SUB_TYPE.MEETINGLOG && (local_storage_info.filter_info ?? { meetinglog : true }).meetinglog == false || 
                        event.event_data.approval != null && event.event_data.approval.approval_type == 'VACATION' && (local_storage_info.filter_info ?? { vacation : true }).vacation == false ||
                        event.event_data.approval != null && event.event_data.approval.approval_type == 'BUSINESSTRIP' && (local_storage_info.filter_info ?? { businesstrip : true }).businesstrip == false ) {
                        continue;
                    }

                    // event_id와 event_data.uid가 다른경우 (반복 일정에서 이 일정만 수정으로 생겨난 데이터들) isIgnore가 true면 무시한다
                    if( event.event_id != event.event_data.uid && event.event_data.schedule_date.isIgnore == true ) {
                        continue;
                    }

                    // RGB값만 있다면 그대로 RGB를 사용, ARGB라면 ARGB를 RGB로 변환
                    event.event_data.color = this.hodu_hex_color_process(event.event_data.color);
                                            
                    let hasBackground : boolean = this.getHasBackground(event);

                    let start_date : Date = event.event_data.schedule_date.start instanceof Date 
                                        ? event.event_data.schedule_date.start 
                                        : new Date(moment(event.event_data.schedule_date.start).format());

                    let end_date : Date = event.event_data.schedule_date.end instanceof Date 
                                        ? event.event_data.schedule_date.end 
                                        : new Date(moment(event.event_data.schedule_date.end).format());

                    // 배경색 가진것들은 end 날짜를 + 1 해준다 (end의 -1일까지 일정을 표시하기 때문)
                    if( hasBackground == true ){
                        end_date.setDate(end_date.getDate() + 1); // 월의 마지막 일 이후로 넘어가면 자동으로 다음달로 계산해줌
                    }

                    const dtStart : Date = new Date(moment(event.event_data.schedule_date.start).format());

                    const today_date : Date = this.getNewDateForCompare();
                    today_date.setHours(dtStart.getHours());
                    today_date.setMinutes(dtStart.getMinutes());
                    today_date.setSeconds(dtStart.getSeconds());
                    today_date.setMilliseconds(dtStart.getMilliseconds());

                    // 반복일정 rrule_dtstart
                    let rrule_dtstart : string = this.formatDateForRruleDTSTART(
                        this.schedule_search_config.past_schedule == false && this.getNewDateForCompare().getTime() >= dtStart.getTime()
                        ? today_date : dtStart
                    );

                    // 매년 반복 일정 일때 지난 일정 보기를 해제하면 오늘로 옮겨지는 문제 수정
                    if( event.event_data.schedule_date.rrule != null && 
                        event.event_data.schedule_date.rrule.search("FREQ=YEARLY") != -1 ) {
                        rrule_dtstart = this.formatDateForRruleDTSTART(dtStart);
                    }

                    // 반복일정 rrule_until
                    let rrule_until : string  = this.formatDateForRruleUNTIL(new Date(moment(event.event_data.schedule_date.recurrence_end).format()), hasBackground);
                    
                    // 반복일정일때 사용될 Rrule
                    let rrule : string | null  = event.event_data.schedule_date.rrule == null 
                                                    ? null 
                                                    : `DTSTART:${rrule_dtstart}\nRRULE:${event.event_data.schedule_date.rrule};UNTIL=${rrule_until}`;

                    event.event_data.schedule_date.rrule = rrule == null ? undefined : rrule;

                    // 지난 일정 안보기 중에 매년 반복의 경우 보고있는 달력 기간에 해당되지 않는 경우 제외시킨다
                    if( this.schedule_search_config.past_schedule == false && rrule != null && 
                        rrule.search(/FREQ=YEARLY/) != -1 && event.event_data.schedule_date.lunar_yn == false ) {
                        
                        const rruleObj : RRule | RRuleSet = rrulestr(rrule);
                        const compare_dates : Date[] = rruleObj.all();
                        // console.log(compare_dates);
                        
                        let continue_flag : boolean = true;

                        if( this.activeEnd == null ) { break; }

                        const compare_end_date : Date = new Date(this.activeEnd);
                        compare_end_date.setHours(23);
                        compare_end_date.setMinutes(59);
                        compare_end_date.setSeconds(59);
                        compare_end_date.setMilliseconds(999);
                            
                        // 하나라도 달력 날짜 기간 안에 들어온다면 통과, 아니라면 continue
                        for( const compare_date of compare_dates ) {

                            if( today_date.getTime() <= compare_date.getTime() && compare_date.getTime() <= compare_end_date.getTime() ) { 
                                continue_flag = false;
                                break;
                            }
                        }

                        if( continue_flag == true ) { continue; }
                    }

                    // 반복일정일때 사용될 일정 자체의 기간
                    let duration : any  = event.event_data.schedule_date.rrule == null
                                        ? null 
                                        : this.makeDuration(event.event_data.schedule_date.start, event.event_data.schedule_date.end, hasBackground);
                    
                    // 검색하던 기준이 현재 기준과 다르다면 return 
                    if( search_tag != (this.activeStart ? this.activeStart.getTime() : 0)) {
                        return;
                    }

                    // 예약이라면 상태값에 따라 다른 색상을 가진다
                    if( event.event_sub_type == EVENT_SUB_TYPE.APPOINTMENT && event.event_data.appointment != null ) {
                        switch(event.event_data.appointment.appointment_status) {
                            case hodu_doc_enum.appointment_status_code.REQUEST:
                                event.event_data.color = hodu_color.appointment_request;
                                break;

                            case hodu_doc_enum.appointment_status_code.DECLINE:
                                event.event_data.color = hodu_color.appointment_decline;
                                break;

                            case hodu_doc_enum.appointment_status_code.CONFIRM:
                                event.event_data.color = hodu_color.appointment_confirm;
                                break;

                            case hodu_doc_enum.appointment_status_code.RECEIPT:
                                event.event_data.color = hodu_color.appointment_receipt;
                                break;

                            case hodu_doc_enum.appointment_status_code.DIAGNOSIS:
                                event.event_data.color = hodu_color.appointment_diagnosis;
                                break;

                            case hodu_doc_enum.appointment_status_code.NOSHOW:
                                event.event_data.color = hodu_color.appointment_noshow;
                                break;

                        }
                    }

                    // 일반 일정
                    if( rrule == null ) {
                        
                        temp_event.push({
                            id              : event.event_id,
                            title           : event.event_data.title,
                            allDay          :  hasBackground || event.event_sub_type == EVENT_SUB_TYPE.CARD || event.event_sub_type == EVENT_SUB_TYPE.WORK || 
                                               event.event_sub_type == EVENT_SUB_TYPE.REPORT || event.event_sub_type == EVENT_SUB_TYPE.MEETINGLOG,
                            textColor       : !hasBackground ? "#202A39" : "#FFFFFFFF",
                            backgroundColor :  hasBackground ? event.event_data.color : "#FFFFFFFF",
                            borderColor     :  hasBackground ? event.event_data.color : "transparent",
                            start           : start_date,
                            end             : end_date,
                            rrule           : rrule,
                            duration        : duration,
                            eventAtomic     : event,
                            hasBackground   : hasBackground,
                        });

                    }
                    
                    // 반복 일정
                    else {
                        let event_list : t_event[] = [];
                        let repeat_event : t_event = JSON.parse(JSON.stringify(event));

                        // 음력 매년 반복
                        if( repeat_event.event_data.schedule_date.lunar_yn == true && repeat_event.event_data.schedule_date.lunar_start != null && 
                            repeat_event.event_data.schedule_date.lunar_end != null && this.activeStart != null ) {
                            
                            // 해당 달력의 1일 기준의 날짜의 연도를 세팅 해준다
                            const target_year_date : Date = new Date(this.activeStart);
                            while( target_year_date.getDate() != 1 ) {
                                target_year_date.setDate(target_year_date.getDate() + 1 );
                            }
                            
                            // 음력 문자열
                            let lunar_start : string = repeat_event.event_data.schedule_date.lunar_start.replace(/-/ig, '').substring(0, 8);

                            // 평달 반복인지 윤달 반복인지 체크
                            const intercalation_obj : any = await this.hodu_is_intercalation(moment(repeat_event.event_data.schedule_date.start).format('YYYYMMDD'));

                            // 윤달인 경우, 현재 양력 연도에 해당 반복 일정이 없다면 넘어간다 
                            if( intercalation_obj.is_intercalation ) {
                                let is_search_target_year : boolean = false;
                                for( const next_date of intercalation_obj.next_date_obj ) {
                                    if( next_date.solar_ymd.substr(0, 4) == `${target_year_date.getFullYear()}` ) {
                                        is_search_target_year = true;
                                        break;
                                    }
                                }

                                if( is_search_target_year == false ) { continue; }
                            }

                            lunar_start = lunar_start.substr(0, 8);
                            
                            const solar_start_date_promise : Promise<any[]> = this.hodu_lunar_to_solars(lunar_start, intercalation_obj.is_intercalation);
                            let solar_start_date : Date | null = null;

                            solar_start_date_promise.then((dates : any[]) => {
                                
                                if( this.activeStart == null || this.activeEnd == null ) {
                                    return;
                                }

                                // 현재 달력 첫 날짜 ~ 마지막 날짜 사이의 일정인지 판단 해야함
                                const active_start = moment(this.activeStart).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
                                const active_end = moment(this.activeEnd).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();

                                for( const date of dates ) {
                                    const solar : string = date.solar_ymd
                                    const lunar : string = date.lunar_ymd

                                    // 같은 년도에 두 번 있는 경우가 있어서 아래식으로만 찾을 수 없음
                                    // 12월 달력에서 다음 년도에있는게 안나옴 
                                    // if( solar.substring(0, 4) == String(target_year_date.getFullYear()) && 
                                    //     solar.substring(4,6) == `0${target_year_date.getMonth() + 1}`.slice(-2) ) {
                                    //     solar_start_date = new Date(solar);
                                    //     break;
                                    // }

                                    const solar_date = new Date(solar);
                                    if( active_start.getTime() <= solar_date.getTime() && solar_date.getTime() <= active_end.getTime() ) {
                                        solar_start_date = new Date(solar);
                                        break;
                                    }
                                }
                            });

                            temp_promise.push(Promise.all([solar_start_date_promise])
                                .then(() => {

                                    if( solar_start_date == null ) { return; }

                                    // event 데이터가 없거나 event_id가 이상한 경우 건너 뛴다
                                    if( repeat_event == null || repeat_event.event_id == null || repeat_event.event_id.length < 1 ) { return; }

                                    // 검색하던 기준이 현재 기준과 다르다면 return 
                                    if( search_tag != (this.activeStart ? this.activeStart.getTime() : 0)) {
                                        return;
                                    }

                                    if( this.schedule_search_config.past_schedule == false ) {
                                        const today : Date = new Date();
                                        today.setHours(0);
                                        today.setMinutes(0);
                                        today.setSeconds(0);
                                        today.setMilliseconds(0);

                                        if( this.activeEnd == null ) { return; }

                                        const compare_end_date : Date = new Date(this.activeEnd);
                                        compare_end_date.setHours(23);
                                        compare_end_date.setMinutes(59);
                                        compare_end_date.setSeconds(59);
                                        compare_end_date.setMilliseconds(999);

                                        if( solar_start_date.getTime() < today.getTime() || solar_start_date.getTime() > compare_end_date.getTime() ) {
                                            return;
                                        }
                                    }

                                    const solar_end_date : Date = new Date(solar_start_date);
                                    solar_end_date.setHours(23);
                                    solar_end_date.setMinutes(59);
                                    solar_end_date.setSeconds(59);
                                    solar_end_date.setMilliseconds(999);
                                    
                                    temp_event.push({
                                        id              : repeat_event.event_id,
                                        title           : repeat_event.event_data.title,
                                        allDay          :  hasBackground,
                                        textColor       : !hasBackground ? "#202A39" : "#FFFFFFFF",
                                        backgroundColor :  hasBackground ? repeat_event.event_data.color : "#FFFFFFFF",
                                        borderColor     :  hasBackground ? repeat_event.event_data.color : "transparent",
                                        start           : solar_start_date,
                                        end             : solar_end_date,
                                        rrule           : null,
                                        duration        : duration,
                                        eventAtomic     : repeat_event,
                                        hasBackground   : hasBackground,
                                    });
                                }));
                            continue;
                        }

                        // exdate null 체크
                        if( repeat_event.event_data.schedule_date.exdate == null ) {
                            repeat_event.event_data.schedule_date.exdate = [];
                        }

                        const exdate_list : Date[] = repeat_event.event_data.schedule_date.exdate;
                        const original_event : t_event = JSON.parse(JSON.stringify(repeat_event));
                        
                        exdate_list.sort();

                        // console.log(`===================================================`);
                        // console.log(`${original_event.event_data.title}`);
                        // console.log(`===================================================`);
                        // console.log(exdate_list);

                        const exdate_count : number = repeat_event.event_data.schedule_date.exdate.length;
                        for( let i = 0; i < exdate_count; i++ ) {
                            
                            // 왼쪽 오른쪽 자르고 왼쪽은 t_event[]에 push, 오른쪽은 repeat_event에 다시 저장
                            const left_event  : t_event = JSON.parse(JSON.stringify(repeat_event));
                            const right_event : t_event = JSON.parse(JSON.stringify(repeat_event));

                            if( left_event.event_data.schedule_date.rrule == null || right_event.event_data.schedule_date.rrule == null ) {
                                continue;
                            }

                            const start  : Date = new Date(moment(repeat_event.event_data.schedule_date.start).format());
                            const end    : Date = new Date(moment(repeat_event.event_data.schedule_date.end).format());
                            const r_end  : Date = new Date(moment(repeat_event.event_data.schedule_date.recurrence_end).format());
                            const exdate : Date = new Date(moment(exdate_list[i]).format());

                            // console.log(`start : ${start}`);
                            // console.log(`end : ${end}`);
                            // console.log(`r_end : ${r_end}`);
                            // console.log(`exdate : ${exdate}`);

                            // 왼쪽, 오른쪽 생성 (exdate가 start ~ recurrence_end 사이라면 실행)
                            if( start.getTime() <= exdate.getTime() &&
                                exdate.getTime() <= r_end.getTime() ) {


                                const left_r_end_moment = moment(r_end).set('year', exdate.getFullYear())
                                                                       .set('month', exdate.getMonth())
                                                                       .set('date', exdate.getDate() - 1);

                                // if( repeat_event.event_data.schedule_date.isAllDay == true ) {
                                //     left_r_end_moment.set('date', exdate.getDate() - 1)
                                // }

                                // 왼쪽 데이터 가공 준비
                                const left_r_end : Date = left_r_end_moment.toDate();

                                // console.log(`left_r_end : ${left_r_end}`);

                                // 왼쪽 rrule 제조 다시 하기
                                const left_rruleObj : RRule | RRuleSet = rrulestr(i == 0 ? rrule : left_event.event_data.schedule_date.rrule);
                                let   left_rrule_string : string = left_rruleObj.toString();
                                left_rrule_string = left_rruleObj.toString();
                                left_rrule_string = left_rrule_string.substring(0, left_rrule_string.lastIndexOf('UNTIL'));
                                left_rrule_string = `${left_rrule_string}UNTIL=${this.hodu_date_to_format_string(left_r_end, 'YYYYMMDD')}T235959`;

                                // console.log(`left_rrule_string : ${left_rrule_string}`);
                                
                                // 왼쪽 event 필요 데이터 등록 후 event_list에 push
                                left_event.event_data.schedule_date.recurrence_end = left_r_end;
                                left_event.event_data.schedule_date.rrule = left_rrule_string;

                                // dtstart와 until이 같은 날이 아니라면 push
                                // const rrule_for_start_end_equal : RRule | RRuleSet = rrulestr(left_rrule_string);
                                // const dtstart_for_start_end_equal : Date = rrule_for_start_end_equal.options.dtstart;
                                // const until_for_start_end_equal : Date | null = rrule_for_start_end_equal.options.until;
                                // if( until_for_start_end_equal != null && !(
                                //     dtstart_for_start_end_equal.getFullYear() == until_for_start_end_equal.getFullYear() &&
                                //     dtstart_for_start_end_equal.getMonth()    == until_for_start_end_equal.getMonth() &&
                                //     dtstart_for_start_end_equal.getDate()     == until_for_start_end_equal.getDate()
                                // ) ) {
                                    event_list.push(JSON.parse(JSON.stringify(left_event)));
                                // }

                                // 오른쪽 데이터 가공 준비
                                const right_start : Date = new Date(start);
                                const right_end   : Date = new Date(end);

                                right_start.setFullYear(exdate.getFullYear());
                                right_start.setMonth(exdate.getMonth());
                                right_start.setDate(exdate.getDate() + 1);

                                right_end.setFullYear(exdate.getFullYear());
                                right_end.setMonth(exdate.getMonth());
                                right_end.setDate(exdate.getDate() + 1);

                                // rrule 제조 다시 하기
                                const right_rruleObj : RRule | RRuleSet = rrulestr(i == 0 ? rrule : right_event.event_data.schedule_date.rrule);
                                let   right_rrule_string : string = right_rruleObj.toString();

                                let right_r_end_moment = moment(r_end);                                
                                let right_r_end = right_r_end_moment.toDate();

                                right_rrule_string = right_rruleObj.toString();
                                right_rrule_string = right_rrule_string.substring(right_rrule_string.lastIndexOf('RRULE:'), right_rrule_string.lastIndexOf('UNTIL'));
                                right_rrule_string = `DTSTART:${this.hodu_date_to_format_string(exdate, 'YYYYMMDD')}\n${right_rrule_string}UNTIL=${this.hodu_date_to_format_string(right_r_end, 'YYYYMMDDTHHmmss')}`;

                                // console.log(`right_rrule_string : ${right_rrule_string}`);

                                // exdate 부터 시작하는 새로운 RRule
                                const new_rrule : RRule | RRuleSet = rrulestr(right_rrule_string);
                                const right_date : Date[] = new_rrule.all();

                                // console.log(right_date);
                                // 해당하는 반복일정이 하나 뿐이라면 exdate만 남은것이므로 제외시킨다
                                if( right_date.length < 2 ) {
                                    repeat_event.event_id = undefined;
                                    break;
                                }

                                // exdate 다음인 두번째 일정부터 RRule에 포함 시킨다
                                // right_rrule_string = right_rruleObj.toString();
                                right_rrule_string = right_rrule_string.substring(right_rrule_string.lastIndexOf('RRULE:'));
                                right_rrule_string = `DTSTART:${this.formatDateForRruleDTSTARTByUTC(right_date[1])}\n${right_rrule_string}`;

                                // console.log(`right_rrule_string 2 : ${right_rrule_string}`);

                                // 오른쪽 event 필요 데이터 등록 후 repeat_event에 저장
                                right_event.event_data.schedule_date.start = right_start;
                                right_event.event_data.schedule_date.end   = right_end;
                                right_event.event_data.schedule_date.rrule = right_rrule_string;
                                repeat_event = JSON.parse(JSON.stringify(right_event));
                            }
      
                        }

                        // event_list를 가공해서 events에 push
                        const event_list_count : number = event_list.length;
                        for( let i = 0; i < event_list_count; i++ ) {
                            const event_obj : t_event = event_list[i];

                            // event 데이터가 없거나 event_id가 이상한 경우 건너 뛴다
                            if( event_obj == null || event_obj.event_id == null || event_obj.event_id.length < 1 ) { continue; }

                            // 검색하던 기준이 현재 기준과 다르다면 return 
                            if( search_tag != (this.activeStart ? this.activeStart.getTime() : 0)) {
                                return;
                            }
                            
                            temp_event.push({
                                id              : event_obj.event_id,
                                title           : event_obj.event_data.title,
                                allDay          :  hasBackground,
                                textColor       : !hasBackground ? "#202A39" : "#FFFFFFFF",
                                backgroundColor :  hasBackground ? event_obj.event_data.color : "#FFFFFFFF",
                                borderColor     :  hasBackground ? event_obj.event_data.color : "transparent",
                                start           : new Date(moment(event_obj.event_data.schedule_date.start).format()),
                                end             : new Date(moment(event_obj.event_data.schedule_date.end).format()),
                                rrule           : event_obj.event_data.schedule_date.rrule,
                                duration        : duration,
                                eventAtomic     : original_event,
                                isFirstEvent    : i == 0,
                                hasBackground   : hasBackground,
                            });
                        }

                        // 검색하던 기준이 현재 기준과 다르다면 return 
                        if( search_tag != (this.activeStart ? this.activeStart.getTime() : 0)) {
                            return;
                        }

                        // event 데이터가 없거나 event_id가 이상한 경우 건너 뛴다
                        if( repeat_event == null || repeat_event.event_id == null || repeat_event.event_id.length < 1 ) { continue; }
                        
                        // 남아있는 repeat_event를 정보 가공해서 events에 push
                        temp_event.push({
                            id              : repeat_event.event_id,
                            title           : repeat_event.event_data.title,
                            allDay          :  hasBackground,
                            textColor       : !hasBackground ? "#202A39" : "#FFFFFFFF",
                            backgroundColor :  hasBackground ? repeat_event.event_data.color : "#FFFFFFFF",
                            borderColor     :  hasBackground ? repeat_event.event_data.color : "transparent",
                            start           : new Date(moment(repeat_event.event_data.schedule_date.start).format()),
                            end             : new Date(moment(repeat_event.event_data.schedule_date.end).format()),
                            rrule           : repeat_event.event_data.schedule_date.rrule,
                            duration        : duration,
                            eventAtomic     : original_event,
                            isFirstEvent    : event_list_count == 0,
                            hasBackground   : hasBackground,
                        });

                    }
                    

                    // 테스트용 로그
                    // if( rrule != null ){
                    //     console.log(`${event.event_data.title}\n${rrule}\n${JSON.stringify(duration)}\n${event.event_data.schedule_date.start}\n${event.event_data.schedule_date.end}`);
                    // }
                }

                // 음력반복 양력 가져오기 API 전부 완료 될 때 까지 대기 후 events로 담음 
                Promise.all(temp_promise).then(() => {
                    
                    // 검색하던 기준이 현재 기준과 다르다면 return 
                    if( search_tag != (this.activeStart ? this.activeStart.getTime() : 0)) {
                        return;
                    }

                    this.events = temp_event;
                    
                    this.renderLunarHoliday();

                    // 주간 달력일때 프린트 될 내용 업데이트
                    if( this.calendar_view_type == CALENDAR_TYPE.TIME_GRID_WEEK ) window['updateEvents'](new Date(this.start_date), this.events);
                });
            })
            .catch(async(e)=>{
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 달력에서 일 숫자 클릭
     */
    handleNavLinkDayClick(date : Date, jsEvent : Event) : void {
        this.$refs.fullCalendar.getApi().gotoDate(date);
        this.doSetSelectedDate(date);
        this.calendarGotoDateCss(dateFormat(date, 'yyyy-mm-dd'));

        // @ts-ignore
        $('#datepicker').datepicker('setDate', this.selected_date);

        this.renderLunarHoliday();
    }

    /**
     * 이벤트 리사이즈 - 아무것도 안하도록 원래대로 돌림
     */
    async handleEventResize(info) : Promise<void> {
        info.revert();
    }

    /**
     * 이벤트 드랍 - 아무것도 안하도록 원래대로 돌림
     */
    async handleEventDrop(info) : Promise<void> {
        this.doSetCalendarHeight(this.calendar_height - 1);
        this.$nextTick(() => {
            window.setTimeout(() => {
                this.calendarResize();
            }, 100);
        });
        info.revert();
    }
    
    /**
     * 해당 일정이 백그라운드 색상을 가질지 여부 조사
     * 1. 종일일때 또는 음력일때 
     * 2. 연속 일정일때 (ex: 2일 ~ 4일 일정)
     */
    getHasBackground(event : t_event) : boolean {

        // 종일 또는 음력
        if( event.event_data.schedule_date.isAllDay == true || event.event_data.schedule_date.lunar_yn == true ){
            return true;
        }

        // 연속 일정 플래그가 true이거나 시작일과 종료일이 1일 이상 차이날때 (연속 일정)
        if( event.event_data.schedule_date.isContinuos == true || this.getDateDiff(event.event_data.schedule_date.start, event.event_data.schedule_date.end) > 0 ){
            return true;
        }

        return false;
    }

    /**
     * DTSTART 제작 : 일정 시작일
     */
    formatDateForRruleDTSTART(date : Date) : string {
        return `${dateFormat(date, "yyyymmdd")}T${dateFormat(date, "HHMMss")}Z`;
    }

    /**
     * DTSTART 제작 : 일정 시작일
     */
    formatDateForRruleDTSTARTByUTC(date : Date) : string {
        return `${dateFormat(date, "UTC:yyyymmdd")}T${dateFormat(date, "UTC:HHMMss")}Z`;
    }

    /**
     * UNTIL 제작 : 해당 날짜 -1일까지 뿌려줌
     */
    formatDateForRruleUNTIL(date : Date, hasBackground : boolean = false) : string {
        if( hasBackground == false ) { date.setDate(date.getDate() + 1); } // 월의 마지막 일 이후로 넘어가면 자동으로 다음달로 계산해줌
        return `${dateFormat(date, "yyyymmdd")}`;
    }

    /**
     * UNTIL 제작 : 해당 날짜 -1일까지 뿌려줌
     */
    formatDateForRruleUNTILByUTC(date : Date) : string {
        date.setDate(date.getDate() + 1); // 월의 마지막 일 이후로 넘어가면 자동으로 다음달로 계산해줌
        return `${dateFormat(date, "UTC:yyyymmdd")}`;
    }

    /**
     * duration 제작
     */
    makeDuration(startDate : Date | string, endDate : Date | string, hasBackground : boolean ) : string {
        let _startDate : Date = startDate instanceof Date ? startDate : new Date(moment(startDate).format());
        let _endDate   : Date = endDate   instanceof Date ? endDate   : new Date(moment(endDate).format());

        // 두 날짜 사이의 간격을 밀리초로 구함
        const timeMills : number = _endDate.getTime() - _startDate.getTime();
        
        // timeMillis를 hour(시간), minute(분)으로 만듬
        const hour   : number = Math.floor(timeMills / 3600 / 1000);
        const minute : number = Math.floor( (timeMills - (hour * 3600 * 1000)) / 60 / 1000 );

        // 강제로 2자리로 만듬 ( 10이상이면 10, 11, 12 그대로, 10미만이면 01, 02, 03 처럼 앞에 0을 붙임 ) 
        let duration_hour   : string = `0${ hasBackground ? hour + 24 : hour}`.slice(-2);
        let duration_minute : string = `0${minute}`.slice(-2);

        // ex) 01:00
        return `${duration_hour}:${duration_minute}`;
    }

    /**
     * AM PM 텍스트 생성
     */
    makeAmPmDateText(startDate : Date | string, endDate : Date | string ) : string {
        let _startDate : Date = startDate instanceof Date ? startDate : new Date(moment(startDate).format());
        let _endDate   : Date = endDate   instanceof Date ? endDate   : new Date(moment(endDate).format());

        let startHour   : number = _startDate.getHours();
        let startMinute : number = _startDate.getMinutes();
        let startAmPm   : string = "오전";

        let endHour   : number = _endDate.getHours();
        let endMinute : number = _endDate.getMinutes();
        let endAmPm   : string = "오전";

        // 12시가 넘었다면 PM
        if( startHour > 11 ){
            startAmPm = "오후";
            startHour = startHour - 12;
        }
        if( endHour > 11 ){
            endAmPm = "오후";
            endHour = endHour - 12;
        }

        // 0시는 12시로 치환
        if( startHour == 0 ){
            startHour = 12;
        }
        if( endHour == 0 ){
            endHour = 12;
        }

        const startHourString   : string = `${startHour}`;
        const startMinuteString : string = `0${startMinute}`.slice(-2);

        const endHourString     : string = `${endHour}`;
        const endMinuteString   : string = `0${endMinute}`.slice(-2);

        // ex) AM 12:00 - PM 1:00
        return `${startAmPm} ${startHourString}:${startMinuteString} - ${endAmPm} ${endHourString}:${endMinuteString}`;
    }

    /**
     * 마우스 휠 이벤트
     */
    async handleMouseWheel(e) : Promise<void> {
        if( this.calendar_view_type != CALENDAR_TYPE.DAY_GRID_MONTH || this.searchVisible == true ) {
            return;
        }

        // 일정 더보기 클릭시 스크롤 생기는 영역 위에 마우스가 있는 경우는 무시
        if( $('.fc-more-popover .fc-event-container').length > 0 && 
            $('.fc-more-popover .fc-event-container').is(':hover') == true ){
            return;
        }

        if(e.deltaY >= 0){
            $('#btnNext').trigger('click');
        } else if(e.deltaY < 0){
            $('#btnPrev').trigger('click');
        }
    }

    /**
     * 순수 날짜 비교용 Date객체 반환
     */
    getNewDateForCompare() : Date {
        const date : Date = new Date();

        // 0시 0분 0초 0밀리초로 만듬
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        return date; 
    }

    /**
     * 이전 달로 이동
     */
    async handlePrev() : Promise<void> {
        this.$refs.fullCalendar.getApi().prev();
        
        // @ts-ignore
        $('#datepicker').datepicker('setDate', new Date(moment(this.$refs.fullCalendar.getApi().view.currentStart).format()));
        this.calendarGotoDateCss(dateFormat(this.selected_date, 'yyyy-mm-dd'));

        this.renderLunarHoliday();
    }

    /**
     * 다음 달로 이동
     */
    async handleNext() : Promise<void> {
        this.$refs.fullCalendar.getApi().next();
        
        // @ts-ignore
        $('#datepicker').datepicker('setDate', new Date(moment(this.$refs.fullCalendar.getApi().view.currentStart).format()));
        this.calendarGotoDateCss(dateFormat(this.selected_date, 'yyyy-mm-dd'));

        this.renderLunarHoliday();
    }

    /**
     * 오늘 날짜로 이동
     */
    async handleToday() : Promise<void> {
        this.$refs.fullCalendar.getApi().gotoDate(new Date());
        
        this.doSetSelectedDate(new Date());

        // @ts-ignore
        $('#datepicker').datepicker('setDate', this.selected_date);
        this.calendarGotoDateCss(dateFormat(this.selected_date, 'yyyy-mm-dd'));

        this.renderLunarHoliday();
    }

    /**
     * 일정 검색 옵션이 바뀐 것을 감지
     */
    @Watch('schedule_search_config', { immediate : false, deep : true })
    @Debounce(100)
    onScheduleSearchConfigChanged(): void {
        if( this.is_first_init == false ) return;
        this.doSelectScheduleList({"schedule_search_config" : this.schedule_search_config});
        this.getEvents();
    }

    /**
     * 달력 이동 해야 되는 날짜가 바뀐 것을 감지
     */
    @Watch('go_to_date')
    onDateChange() : void {
        this.$refs.fullCalendar.getApi().gotoDate(this.go_to_date);
    }
    
    /**
     * 캘린더 리사이즈
     */
    calendarResize() : void {
        // resize 시 height 변경 처리
        let scrollProc: any = 0;
        let minWidths : any = 0;
        try {
            minWidths = $('.section_ce_fix').css('min-width');

            if(minWidths) {
                minWidths = minWidths.replace('px', '');
            } else {
                minWidths = 0;
            }
        } catch(e) {
            minWidths = 0;
        }

        let windowWidth      : number | undefined = $(window).outerWidth();
        let windwoWidthFinal : number             = windowWidth == null ? 0 : windowWidth;

        let leftWidth      : number | undefined = $('#left_area').outerWidth();
        let leftWidthFinal : number             = leftWidth == null ? 0 : leftWidth;

        if(Number(minWidths)>=(windwoWidthFinal - leftWidthFinal)) {
            scrollProc = 15;
        }

        let containerHeight      : number | undefined = $('#container').outerHeight();
        let containerHeightFinal : number             = containerHeight == null ? 0 : containerHeight;

        let userToolboxHeight      : number | undefined = $('#fc-userToolbox').outerHeight();
        let userToolboxHeightFinal : number             = userToolboxHeight == null ? 0 : userToolboxHeight;

        const calendar_height : number = containerHeightFinal - userToolboxHeightFinal - scrollProc;
        this.doSetCalendarHeight(calendar_height);

        // event_limit 설정
        const week = $('.fc-widget-content').find('.fc-week');
        const week_count : number = week.length;
        const event_height : number = 22;

        const one_day_height = Math.floor(calendar_height / week_count);
        const one_day_head_height = 23;

        let event_limit : number = (calendar_height == 0 || week_count == 0 || event_height == 0) ? 0 
                                 : Math.floor((one_day_height - one_day_head_height) / event_height);

        if( event_limit < 1 ){
            event_limit = 1;
        }

        // console.log(`${containerHeightFinal}:${userToolboxHeightFinal}:${scrollProc}`);
        // console.log(`${calendar_height}:${week_count}:${event_height}`);
    
        this.doSetEventLimit(event_limit);
        this.renderLunarHoliday();
    }

    /**
     * 달력 음력, 공휴일 렌더링
     */
    async renderLunarHoliday() {
        this.$nextTick(() => {
            
            // 월 달력 음력, 공휴일 렌더링
            if( this.calendar_view_type == CALENDAR_TYPE.DAY_GRID_MONTH ){
                this.dayGridLunarAndHolidays();
            } 

            // 주, 일 달력 음력, 공휴일 렌더링
            else {
                this.timeGridLunarAndHolidays(this.calendar_view_type);
            }
            
            this.calendarGotoDateCss(dateFormat(this.selected_date, 'yyyy-mm-dd'));
        });
    }

    /**
     * 캘린더 변경
     */
    async onChangeCalendar(event) : Promise<void> {
        const vue = this;

        switch(event.target.value){
            case CALENDAR_TYPE.TIME_GRID_DAY:
                this.doSetCalendarViewType(CALENDAR_TYPE.TIME_GRID_DAY);
                break;

            case CALENDAR_TYPE.TIME_GRID_WEEK:
                this.doSetCalendarViewType(CALENDAR_TYPE.TIME_GRID_WEEK);
                break;

            case CALENDAR_TYPE.DAY_GRID_MONTH:
                this.doSetCalendarViewType(CALENDAR_TYPE.DAY_GRID_MONTH);
                break;
        }

        this.$refs.fullCalendar.getApi().changeView(this.calendar_view_type);
        setTimeout(()=>{
            this.calendarGotoDateCss(dateFormat(this.selected_date, 'yyyy-mm-dd'));
            this.$nextTick(() => { 
                
                // 월 달력이라면 해당 달의 1일로 세팅
                if( this.activeStart == null ) { return; }
                const current_month : Date = new Date(this.activeStart);
                if( this.calendar_view_type == CALENDAR_TYPE.DAY_GRID_MONTH && current_month.getDate() != 1 ) {
                    current_month.setDate(1);
                    current_month.setMonth(current_month.getMonth() + 1);
                }
                // handleDatesRender가 실행 안되는 때가 있어서 이쪽에서도 실행
                $('#hMonthPrt').html(`<input type="text" class="forDatePick" value="${moment(current_month).format("YYYY-MM-DD")}" readonly />${this.$refs.fullCalendar.getApi().view.title}`);
                
                // 주달력, 일달력은 음력, 공휴일을 다시 렌더링 해준다
                if( this.calendar_view_type == CALENDAR_TYPE.TIME_GRID_WEEK ) { this.timeGridWeekLunarAndHolidays(); } 
                else if( this.calendar_view_type == CALENDAR_TYPE.TIME_GRID_DAY ) { this.timeGridDayLunarAndHolidays(); }

                setTimeout(() => {

                    // datepicker option
                    let option = {
                        inline: false,
                        showOtherMonths: true,
                        selectOtherMonths: true,
                        dateFormat: 'yy-mm-dd',
                        monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                        dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                        yearSuffix : '.',
                        blankSpace : '',
                        changeYear : true,
                        yearRange  : '1900:2050',
                        onSelect: function (dateText, inst) {

                            vue.doSetSelectedDate(new Date(dateText));
                            vue.$refs.fullCalendar.getApi().gotoDate(new Date(dateText));

                            // @ts-ignore
                            $('#datepicker').datepicker('setDate', new Date(moment(vue.$refs.fullCalendar.getApi().view.currentStart).format()));
                            vue.calendarGotoDateCss(dateFormat(vue.selected_date, 'yyyy-mm-dd'));
                        },
                    };

                    // @ts-ignore
                    $('.forDatePick').datepicker(option);
                }, 100);

                if( this.calendar_view_type == CALENDAR_TYPE.TIME_GRID_WEEK ) window['updateEvents'](new Date(this.start_date), this.events);
            });
        }, 100)
    }

    /**
     * 캘린더 변경 CSS 
     */
    calendarGotoDateCss(date : string) : void {

        this.$nextTick(() => {
            $('#calendar').find('.fc-day-number').removeClass('on_orange');
            $('#calendar').find('.fc-day-number').removeClass('on_select');
            
            if(dateFormat(moment().toDate(), 'yyyy-mm-dd') == date) {
                $('#calendar').find(`td.fc-day-top[data-date="${date}"]`).find('.fc-day-number').addClass('on_orange');
            }else {
                $('#calendar').find(`td.fc-day-top[data-date="${date}"]`).find('.fc-day-number').addClass('on_select');
            }
        });

    }

    /**
     * 일정 검색창 토글
     */
    searchToggle() : void {
        const vue = this; 

        if( this.getScheduleReadPermission() == false ) {
            this.hodu_show_dialog('cancel', '일정 읽기 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }

        this.searchVisible = !this.searchVisible;

        // 검색 ON 이라면 자동으로 포커스 가도록
        if( this.searchVisible == true ) { this.$nextTick(() => $('#event_search').focus()); }
        else { this.searchOptionVisible = false; }
    }

    /**
     * 일정 추가 && 예약 등록
     */
    addScheduleOrAppointment() : void {

        if( this.getScheduleCreatePermission() == false ) {
            this.is_create_menu_open = !this.is_create_menu_open;
            return;
        }

        // 호두 D 에서 + 버튼 누르면 예약 등록
        if( this.scope_group_team_option.biz_type == GROUP_TYPE.BIZD ) {
            this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/create`);
            return;
        }

        this.addEvent(EVENT_SUB_TYPE.SCHEDULE);
    }

    /**
     * 업무일지 생성
     */
    addReport() : void {
        
        if( this.getReportCreatePermission() == false ) {
            this.hodu_show_dialog('cancel', '업무 일지 작성 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }

        this.addEvent(EVENT_SUB_TYPE.REPORT);
    }

    /**
     * 회의록 생성
     */
    addMeetingLog() : void {
        
        if( this.getMeetingLogCreatePermission() == false ) {
            this.hodu_show_dialog('cancel', '회의록 작성 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }

        this.addEvent(EVENT_SUB_TYPE.MEETINGLOG);
    }

    /**
     * 업무 생성
     */
    addWork() : void {
        if( this.getWorkCreatePermission() == false ) {
            this.hodu_show_dialog('cancel', '업무 작성 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }

        this.addEvent(EVENT_SUB_TYPE.WORK);
    }

    /**
     * 이벤트 생성 공통
     */
    addEvent(event_sub_type : EVENT_SUB_TYPE) : void {
        
        const target_date : Date = new Date();
        const start_date : Date = new Date(target_date.getTime());
        const end_date   : Date = new Date(target_date.getTime());

        // 시작 시간이 '정시' 거나 '23시' 일 경우는 그대로 사용한다 
        if( target_date.getMinutes() != 0 && target_date.getHours() != 23 ){
           start_date.setHours(target_date.getHours() + 1);
        }
        
        start_date.setMinutes(0);
        start_date.setSeconds(0);
        start_date.setMilliseconds(0);

        // 시작시간이 23시라면 23시 50분 고정, 아니라면 시작시간 + 1시간에 0분
        if( start_date.getHours() == 23 ){
            end_date.setHours(23);
            end_date.setMinutes(50);
        } else {
            end_date.setHours(start_date.getHours() + 1);
            end_date.setMinutes(0);
        }

        end_date.setSeconds(0);
        end_date.setMilliseconds(0);

        // event 기본 값 설정
        const event : t_event = {
            "audit_created": new Date(),
            "audit_delete_flag": false,
            "audit_deleted": null,
            "audit_modified": new Date(),
            "audit_user_id": this.user_id,
            "calendar_id": this.calendar_id,
            "event_data": {
                "alarm": [],
                "attachment": {
                    "files": [],
                    "imgs": []
                },
                "attend": false,
                "color": "#477FFF",
                "event_owner_group_id": this.scope_team_id > 0 ? 0 : this.scope_group_id,
                "event_owner_group_name": this.scope_team_id > 0 ? "" : this.scope_group_team_option.group_team_name,
                "event_owner_id": this.user_id,
                "event_owner_name": this.user_name,
                "event_owner_team_id": this.scope_team_id,
                "event_owner_team_name": this.scope_team_id > 0 ? this.scope_group_team_option.group_team_name : "",
                "event_push_yn": true,
                "event_sub_type": event_sub_type,
                "location": [],
                "memo": "",
                "note": "",
                "percent_complete": 0,
                "priority": 0,
                "schedule_date": {
                    "end": end_date,
                    "isAllDay": false,
                    "isContinuos": false,
                    "isIgnore": false,
                    "lunar_yn": false,
                    "recurrence_end": end_date,
                    "start":  start_date
                },
                "contacts" : [{
                    "name" : "",
                    "tel" : ""
                }],
                "status": "",
                "title": "",
                "uid": "",
                "version": "1",
                "vote": [],
                "is_private": false,
            },
            "event_id": "",
            "event_type": this.scope,
            "event_sub_type": event_sub_type,
            "user_id": this.scope == OWNER_TYPE.PERSONAL ? this.user_id : 0,
            "team_id": this.scope == OWNER_TYPE.TEAM ? this.scope_team_id : 0,
            "group_id": this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : 0,
            "subscribe_users": [],
            "subscribe_groups": [],
            "subscribe_teams": [],
            "duration" : "",
        }
        
        if( event_sub_type == EVENT_SUB_TYPE.WORK ) {
            event.event_data.work = {
                "template_id"         : "", 
                "template_type"       : "CHECK", 
                "work_status"         : "대기",
                "work_status_code"    : "WAIT",
                "assignment_type"     : "ALL", 
                "work_status_visible" : true,
                "audit_modified"      : new Date()
            };
        }
        if( event_sub_type == EVENT_SUB_TYPE.REPORT ) event.event_data.remark = "";
        if( event_sub_type == EVENT_SUB_TYPE.MEETINGLOG ) {
            event.event_data.meeting_location = "";
            event.event_data.attendees = "";
        }

        // EventInfo에 이벤트 등록
        this.doSetEvent(event);
        this.doSetEventCrudType(CRUD_TYPE.CREATE);
        this.doSetEventShareInfo({
            share_option : SHARE_OPTION.SHARE,
            user_ids : [],
            group_ids : [],
            team_ids : [],
            group_user_ids : [],
            team_user_ids : []
        });

        this.hodu_router_push('/event');
    }

    /**
     * 일정 검색 keydown
     */
    eventKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }

        this.goEventSearch();
    }

    /**
     * 일정 검색으로 이동
     */
    goEventSearch() : void {

        if( this.getScheduleReadPermission() == false ) {
            this.hodu_show_dialog('cancel', '일정 읽기 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }

        if( this.event_search_query == null || this.event_search_query.length < 2 ) {
            this.hodu_show_dialog('alert', '검색어를 2자 이상 입력해주세요', ["확인"]);
            return;
        }

        if( this.doSetIsEventFilterSearch != null ) { this.doSetIsEventFilterSearch(false); }
        this.doSetEventSearchQuery(this.event_search_query);
        this.hodu_router_push('/event_search');
    }

    /**
     * default date 반환
     */
    getDefaultDate() : Date {
        let default_date : Date | null = null;
        
        // 조건에 맞지 않으면 항상 start_date로 설정된 날짜 반환
        default_date = new Date(this.start_date);
        
        // 월달력은 같은 년월이라면 오늘날짜 반환
        if( this.isDayGridMonth() ) {
            default_date = new Date(moment(this.start_date).format()).getFullYear() == new Date().getFullYear() && 
                                new Date(moment(this.start_date).format()).getMonth() == new Date().getMonth() ? new Date() : new Date(moment(this.start_date).format());
        }

        return default_date;
    }

    /**
     * 일정 읽기 권한 체크
     */
    getScheduleReadPermission() : boolean {
        
        // 그룹 달력
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, "event", "read");
        }

        // 팀 달력
        else if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_team_permmision(this.scope_team_id, "event", "read");
        }

        // 개인 달력
        else {
            return true;
        } 
    }

    /**
     * 일정 생성 권한 체크
     */
    getScheduleCreatePermission() : boolean {
        
        // 그룹 달력
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, "event", "create");
        }

        // 팀 달력
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, "event", "create");
        }

        // 개인 달력
        else {
            return true;
        } 
    }

    /**
     * 업무 일지 생성 권한 체크
     */
    getReportCreatePermission() : boolean {
        
        // 그룹 달력
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.isEnableGroupFeature(this.scope_group_id, 'work') && this.is_group_permmision(this.scope_group_id, "report", "create");
        }

        // 팀 달력
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.isEnableGroupFeature(this.scope_group_id, 'work') && this.is_team_permmision(this.scope_team_id, "report", "create");
        }

        // 개인 달력
        else {
            return false;
        } 
    }

    /**
     * 회의록 생성 권한 체크
     */
    getMeetingLogCreatePermission() : boolean {
        
        // 그룹 달력
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.isEnableGroupFeature(this.scope_group_id, 'work') && this.is_group_permmision(this.scope_group_id, "meetinglog", "create");
        }

        // 팀 달력
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.isEnableGroupFeature(this.scope_group_id, 'work') && this.is_team_permmision(this.scope_team_id, "meetinglog", "create");
        }

        // 개인 달력
        else {
            return false;
        } 
    }

    /**
     * 업무 생성 권한 체크
     */
    getWorkCreatePermission() : boolean {
        
        // 그룹 달력
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.isEnableGroupFeature(this.scope_group_id, 'work') && this.is_group_permmision(this.scope_group_id, "work", "create");
        }

        // 팀 달력
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.isEnableGroupFeature(this.scope_group_id, 'work') && this.is_team_permmision(this.scope_team_id, "work", "create");
        }

        // 개인 달력
        else {
            return false;
        } 
    }

    /**
     * 일정 검색 옵션 ON / OFF
     */
    searchOptionOnOff() : void {
        const vue = this;

        this.searchOptionVisible = !this.searchOptionVisible;

        if( this.searchOptionVisible ) { 

            if( this.event_search_option.start_date != null ) {
                const date_format_text : string = moment(this.event_search_option.start_date).format("YYYY.MM.DD");
                const week_of_day : string = this.getDayOfWeekByDate(this.event_search_option.start_date, "요일");
                const final_date_text : string = `${date_format_text} ${week_of_day}`;
                this.start_date_text = final_date_text;
            }

            if( this.event_search_option.end_date != null ) {
                const date_format_text : string = moment(this.event_search_option.end_date).format("YYYY.MM.DD");
                const week_of_day : string = this.getDayOfWeekByDate(this.event_search_option.end_date, "요일");
                const final_date_text : string = `${date_format_text} ${week_of_day}`;
                this.end_date_text = final_date_text;
            }

            setTimeout(() => { 
                const option : any = {
                    inline: false,
                    showOtherMonths: true,
                    selectOtherMonths: true,
                    dateFormat: 'yy-mm-dd',
                    monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                    dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                    yearSuffix : '.',
                    blankSpace : '',
                    changeYear : true,
                    yearRange  : '1970:2050',
                    onSelect: function (dateText, inst) {
                        const id : string | undefined = $(this).attr('id');

                        const selected_date : Date = new Date(dateText);
                        selected_date.setHours(0);
                        selected_date.setMinutes(0);
                        selected_date.setSeconds(0);
                        selected_date.setMilliseconds(0);

                        const date_format_text : string = moment(selected_date).format("YYYY.MM.DD");
                        const week_of_day : string = vue.getDayOfWeekByDate(selected_date, "요일");
                        
                        const final_date_text : string = `${date_format_text} ${week_of_day}`;

                        if( id == "search_sch_from" ) {
                            vue.start_date_text                = final_date_text;
                            vue.event_search_option.start_date = selected_date;

                            // end가 비어있지 않고 start보다 작다면 end를 start로 덮어씌운다
                            if( vue.event_search_option.end_date != null && new Date(vue.event_search_option.end_date).getTime() < selected_date.getTime() ) {
                                vue.end_date_text                = final_date_text;
                                vue.event_search_option.end_date = selected_date;
                            }

                        }

                        if( id == "search_sch_to" ) {
                            vue.end_date_text                = final_date_text;
                            vue.event_search_option.end_date = selected_date;

                            // start가 비어있지 않고 end보다 크다면 start를 end로 덮어씌운다
                            if( vue.event_search_option.start_date != null && new Date(vue.event_search_option.start_date).getTime() > selected_date.getTime() ) {
                                vue.start_date_text                = final_date_text;
                                vue.event_search_option.start_date = selected_date;
                            }
                        }
                    }
                };

                // @ts-ignore
                $('#search_sch_from').datepicker(option);

                // @ts-ignore
                $('#search_sch_to').datepicker(option);
            }, 10); 
        }
    }

    /**
     * 일정 검색 타입 변경
     */
    searchTypeChange(event : any) : void {
        this.event_search_option.search_event_type = event.target.value;

        const value : string = event.target.value;

        if( value != "WORK" ) {
            this.event_search_option.is_work_wait = false;
            this.event_search_option.is_work_start = false;
            this.event_search_option.is_work_end = false;
        }

        if( value == "WORK" ) {
            this.event_search_option.has_attend = false;
            this.event_search_option.has_vote   = false;
        }

    }

    /**
     * 파일 검색 허용 할 것인지 여부
     */
    checkFileEnabled(value : string) : boolean {
        
        // 특정 그룹, 팀이 선택 된 경우
        const reg : RegExp = new RegExp(/\d-\d/);
        if( reg.test(value) == true ) {
            let group_id : number = 0;
            let team_id : number = 0;

            try {
                const scope_ids : string[] = value.split("-");
                if( scope_ids[0] != null ) { group_id = Number(scope_ids[0]); }
                if( scope_ids[1] != null ) { team_id  = Number(scope_ids[1]); }

                const group_team_count : number = this.computedGroupAndTeam.length;
                for( let i = 0; i < group_team_count; i++ ) {
                    const group_team : any = this.computedGroupAndTeam[i];
                    if( group_team.group_id == group_id && group_team.team_id == team_id ) {
                        return group_team.limit_event_attachment > 0;
                    }
                }

            } catch(e) {
                this.hodu_error_process(e, false, false, false);
            }

            return false;
        }

        // 특정 그룹, 팀이 선택된게 아니라면 무조건 검색 허용
        return true;
    }

    /**
     * 해당 컬러가 검색 할 컬러에 포함되어있는지 여부 반환
     */
    isContainColor(color : string) : boolean {
        const upper_color : string = this.hodu_hex_color_process(color).toUpperCase();
        return (this.event_search_option.event_color.indexOf(upper_color) > -1);
    }
    
    /**
     * 검색 컬러 변경
     */
    changeSearchColor(event : any, color : string) : void {
        const checked : boolean = event.target.checked;
        const upper_color : string = this.hodu_hex_color_process(color).toUpperCase();

        const color_index : number = this.event_search_option.event_color.indexOf(upper_color);

        // 체크 하는 경우
        if( checked == true ) {
            if( color_index < 0 ) { this.event_search_option.event_color.push(upper_color); }
        }

        // 체크를 해제 하는 경우
        else {
            if( color_index > -1 ) { this.event_search_option.event_color.splice(color_index, 1); }
        }
    }

    /**
     * 모든 컬러가 선택 되었는지 여부
     */
    isCheckedAllColor() : boolean {
        return this.event_search_option.event_color.length >= 20;
    }

    /**
     * 모든 색상 선택 체크 변경
     */
    changeCheckAllColor(event : any) : void {
        const checked : boolean = event.target.checked;
        this.event_search_option.event_color.splice(0, this.event_search_option.event_color.length);
        if( checked == true ) {
            for( let color of this.dc_color ) { this.event_search_option.event_color.push(color); }
            for( let color of this.lc_color ) { this.event_search_option.event_color.push(color); }
        }
    }

    /**
     * 검색 옵션 리셋
     */
    searchOptionReset() : void {
        this.start_date_text = null;
        this.end_date_text = null;

        const option : EventSearchOption = {
            search_event_type : "ALL",
            event_color : [],
            has_photo : false,
            has_file : false,
            has_chat : false,
            has_attend : false,
            has_vote : false,
            is_work_wait : false,
            is_work_start : false,
            is_work_end : false
        };

        if( this.doSetEventSearchOption != null ) { this.doSetEventSearchOption(option); }
    }

    /**
     * 필터 검색 시작
     */
    goEventFilterSearch() : void {
        if( this.doSetIsEventFilterSearch != null ) { this.doSetIsEventFilterSearch(true); }
        if( this.doSetEventSearchOption != null ) { this.doSetEventSearchOption(JSON.parse(JSON.stringify(this.event_search_option))); }
        this.doSetEventSearchQuery("");
        this.hodu_router_push('/event_search');
    }

    /**
     * 달력 타입에 따라 텍스트 반환
     */
    getSelectedCalendarViewTypeText() : string {
        switch( this.calendar_view_type ) {
            case CALENDAR_TYPE.DAY_GRID_MONTH:
                return '월별';

            case CALENDAR_TYPE.TIME_GRID_WEEK:
                return '주별';
                
            case CALENDAR_TYPE.TIME_GRID_DAY:
                return '일별';

        }

        return "";
    }
    
    isDayGridMonth() : boolean {
        return this.calendar_view_type == CALENDAR_TYPE.DAY_GRID_MONTH;
    }

    isTimeGridWeek() : boolean {
        return this.calendar_view_type == CALENDAR_TYPE.TIME_GRID_WEEK;
    }

    isTimeGridDay() : boolean {
        return this.calendar_view_type == CALENDAR_TYPE.TIME_GRID_DAY;
    }

    /**
     * 일정타입에 따른 정렬 순서 반환
     */
    getSubTypeOrder(event) : number {
        
        const atomic = event.eventAtomic;
        
        switch(atomic.event_sub_type) {
            case EVENT_SUB_TYPE.SCHEDULE:
            case EVENT_SUB_TYPE.CARD:
                if( atomic.event_data.approval != null && atomic.event_data.approval.approval_type != null && atomic.event_data.approval.approval_type.length > 0 ) {
                    return 2;
                }
                return 1;

            case EVENT_SUB_TYPE.WORK:
            case EVENT_SUB_TYPE.REPORT:
            case EVENT_SUB_TYPE.MEETINGLOG:
                return 0;
        }

        return Number.MAX_SAFE_INTEGER;
    }

    /**
     * 일정 갤러리
     */
    goGallery() {
        this.doSetGalleryInfo?.({
            tab : 'imgs',
            events : [],
            files : [],
            search_query : "",
            date : new Date(),
        });
        this.hodu_router_push("/gallery");
    }

}

